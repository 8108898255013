import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Globals} from "../global/globals";
import {Observable} from "rxjs";
import {PerformanceListDto} from "../dtos/performance";
import {SectorCreateDto, SectorListDtoToSend} from "../dtos/sector";

@Injectable({
  providedIn: 'root'
})
export class PerformanceService {

  private userBaseUri: string = this.globals.backendUri + '/performance';
  constructor(private httpClient: HttpClient, private globals: Globals) { }

  getPerformancesWithSectorLimit(limit: number, performanceName: string): Observable<PerformanceListDto[]> {
    let params = new HttpParams();
    params = params.append('sectorLimit', limit)
    if(performanceName){
      params = params.append('performanceName', performanceName)
    }
    return this.httpClient.get<PerformanceListDto[]>(`${this.userBaseUri}`, {params})
  };
  saveEventHall(sectorCreateDto: SectorCreateDto[]): Observable<SectorListDtoToSend[]> {
    return this.httpClient.post<SectorListDtoToSend[]>(`${this.userBaseUri}/hall`, sectorCreateDto)
  };
}
