import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import { MessageListDto } from 'src/app/dtos/message';
import { MessageService } from 'src/app/services/message.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ErrorFormatterService } from 'src/app/services/error-formatter.service';
import {HttpStatusCode} from "@angular/common/http";
import { TopTen } from 'src/app/dtos/event';
import { Subject, debounceTime } from 'rxjs';
import { EventType } from 'src/app/dtos/eventType';
import { TopTenService } from 'src/app/services/top-ten.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  messages: MessageListDto[] = [];
  topten: TopTen[] = [];
  category: EventType = EventType.DISABLED;
  searchChangedObservable = new Subject<void>();
  defaultValue = '';
  ticketAmount: number;

  constructor(
    public authService: AuthService,
    private userService: UserService,
    private notification: ToastrService,
    private router: Router,
    private errorFormatter: ErrorFormatterService,
    private topTenService: TopTenService
  ) { }

  ngOnInit() {
    if(this.authService.isLoggedIn()) {
      this.loadMessages();
      this.getTopTen();
      this.searchChangedObservable
      .pipe(debounceTime(300))
      .subscribe({next: () => this.getTopTen()});
    }
  }

  loadMessages() {
    this.userService.getUnreadMessages().subscribe({
      next: data => {
        this.messages = data;
      },
      error: error => {
        if (error.status === HttpStatusCode.NotFound) {
          this.notification.error(this.errorFormatter.format(error), "Could not fetch unread news items.");
        } else {
          this.notification.error("Please try again.", "Could not fetch unread news items.");
        }
      }
    });
  }

  getTopTen() {
    this.topTenService.getTopTen(this.category).subscribe({
      next: data => {
        this.topten = data;
        this.ticketAmount = this.topten[0].tickets;
      },
      error: error => {
        this.notification.error("Please try again.", "Could not fetch top ten items.");
      }
    });
  }

  searchChanged() {
    this.searchChangedObservable.next();
  }

  logIn() {
    this.router.navigate(['login']);
  }
}
