import {EventType} from "./eventType";

export interface EventListDto {
  id: number;
  name: string;
  description: string;
  startDateTime: string;
  endDateTime: string;
  content: string;
  type: EventType;
}
export class EventCreateDto {

  name : string;
  description: string;
  startDateTime: Date;
  endDateTime: Date;
  category: EventType;
  content: string;
  image: string;
}

export interface TopTen {
  id: number;
  name: string;
  tickets: number;
}

export interface EventDetailDto {
  id: number;
  name: string;
  description: string;
  startDateTime: Date;
  endDateTime: Date;
  content: string;
  image: string;
  type: EventType;
}
