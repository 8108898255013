import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {CartSessionDataDto, PaymentIntentRequestDto, PaymentIntentResponseDto} from "../dtos/payment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private orderBaseUri: string = this.globals.backendUri + '/payments';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  createPaymentIntent(paymentIntentRequest: PaymentIntentRequestDto): Observable<PaymentIntentResponseDto> {
    return this.httpClient.post<PaymentIntentResponseDto>(`${this.orderBaseUri}/payment_intents`, paymentIntentRequest);
  }

  startPaymentSession(cartSessionDataDto: CartSessionDataDto): Observable<void> {
    return this.httpClient.patch<void>(`${this.orderBaseUri}/payment_sessions/start`, cartSessionDataDto);
  }

  expirePaymentSession(cartSessionDataDto: CartSessionDataDto): Observable<void> {
    return this.httpClient.patch<void>(`${this.orderBaseUri}/payment_sessions/expire`, cartSessionDataDto);
  }

  completePaymentSession(cartSessionDataDto: CartSessionDataDto): Observable<void> {
    return this.httpClient.post<void>(this.orderBaseUri, cartSessionDataDto);
  }
}
