import {Component, OnInit} from "@angular/core";
import {MessageService} from "../../services/message.service";
import {Message} from "../../dtos/message";
import {ToastrService} from "ngx-toastr";
import {ErrorFormatterService} from "../../services/error-formatter.service";

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"],
})
export class MessageComponent implements OnInit {
  // After first submission attempt, form validation will start
  submitted = false;
  totalItems = 0;
  currentPage = 1;
  itemsPerPage = 6;
  private message: Message[];

  constructor(
    private messageService: MessageService,
    private notification: ToastrService,
    private errorFormatter: ErrorFormatterService,
  ) {}

  ngOnInit() {
    this.messageService.getAmountOfMessages().subscribe({
      next: (amount: number) => {
        this.totalItems = amount;
      },
    });
    this.loadMessage(this.currentPage);
  }

  getMessage(): Message[] {
    return this.message;
  }

  /**
   * Loads the specified page of message from the backend
   */
  loadMessage(page: number, fetchAgain = false) {
    this.messageService
      .getPageOfMessages(page, this.itemsPerPage, fetchAgain)
      .subscribe({
          next: (message: Message[]) => {
            this.message = message;
          },
          error: (error) => {
            this.notification.error(
              this.errorFormatter.format(error),
              "Could not load message.",
            );
          },
        }
      );
  }
}
