import { Injectable } from '@angular/core';
import {jsPDF} from "jspdf";
import QRCode from 'qrcode';
import {TicketPdfListDto} from "../dtos/ticket";

@Injectable({
  providedIn: 'root'
})
export class TicketPdfService {

  async generatePDF(tickets: TicketPdfListDto[]): Promise<void> {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const ticketWidth = pageWidth - 20;
    const ticketHeight = (pageHeight - 100) / 4;

    for (let index = 0; index < tickets.length; index++) {
      const ticket = tickets[index];
      Math.floor(index / 4);
      const ticketIndex = index % 5;
      const y = 20 + ticketIndex * (ticketHeight + 5);

      if (ticketIndex === 0 && index !== 0) {
        doc.addPage();
      }
      this.drawTicket(doc, 10, y, ticketWidth, ticketHeight, ticket);

      // QR-Code-Image
      const qrCodeSize = 33;
      const qrCodeX = ticketWidth - 45;
      const qrCodeY = y + 8.2;
      const qrCodeImage = await this.generateQrCode(ticket.verificationToken);
      doc.addImage(qrCodeImage, 'PNG', qrCodeX, qrCodeY, qrCodeSize, qrCodeSize);
      // QR-Code-Border
      doc.roundedRect(qrCodeX, qrCodeY, qrCodeSize, qrCodeSize, 3, 3)
    }
    doc.save("tickets.pdf");
  }


  private drawTicket(doc: jsPDF, x: number, y: number, width: number, height: number, ticket: TicketPdfListDto) {
    const drawDottedLine = (startX: number, startY: number, endY: number) => {
      let currentY = startY;
      const dotLength = 0.5;
      const dotSpacing = 1.5;
      doc.setDrawColor(255, 255, 255);
      doc.setLineWidth(0.5);
      while (currentY < endY) {
        doc.line(startX, currentY, startX, currentY + dotLength);
        currentY += dotSpacing;
      }
    };

    const extractStreet = (street: string): string => {
      const parts = street.split(',');
      if (parts.length >= 3) {
        parts.splice(3, 3);
      }
      return parts.join(',');
    };

    const formatTimeWithoutSeconds = (date: Date): string => {
      return new Date(date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const radius = 3;
    const halfCircleRadius = height / 4;
    const ticketBackgroundColor = "#7b61ff";
    const circleInset = 15;
    const contentPadding = 7;
    const eventInfoX = x + circleInset + contentPadding;
    const eventInfoY = y + 11.5;

    doc.setFillColor(ticketBackgroundColor);
    doc.roundedRect(x, y, width, height, radius, radius, 'F');

    doc.setFillColor("#FFFFFF");
    doc.circle(x - 3 , y + height / 2, halfCircleRadius, 'F');
    doc.circle(x + width + 3, y + height / 2, halfCircleRadius, 'F');

    drawDottedLine(x + circleInset, y, y + height);
    drawDottedLine(x + width - circleInset, y, y + height);

    doc.setFontSize(20);
    doc.setTextColor("#FFFFFF");
    doc.text(ticket.eventName, eventInfoX, eventInfoY, { align: "left" });

    doc.setFontSize(11);
    const ticketDetails = [
      `Show: ${ticket.performanceName}`,
      `Date: ${new Date(ticket.performanceDateTime).toLocaleDateString()} ${formatTimeWithoutSeconds(ticket.performanceDateTime)}`,
      `Performer: ${ticket.performerName}`,
      ticket.seatIndex != null && ticket.rowIndex != null ?
        `Row: ${ticket.rowIndex} Seat: ${ticket.seatIndex} in Sector: ${ticket.sectorName}`
        :`Standing Sector: ${ticket.sectorName}`,
      extractStreet(ticket.street)
    ];

    ticketDetails.forEach((detail, index) => {
      doc.text(detail, eventInfoX, eventInfoY + 10 + index * 5, { align: "left" });
    });
  }


  private generateQrCode(text: string): Promise<string> {
    return new Promise((resolve, reject) => {
      QRCode.toDataURL(text, {
        errorCorrectionLevel: 'H',
        width: 33,
        scale: 10,
      }, (err, url) => {
        if (err) {
          reject(err);
        } else {
          // Draw the QR code onto a canvas and round its corners
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          const img = new Image();
          img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;

            context.save();
            this.roundRect(context, 0, 0, canvas.width, canvas.height, 30);
            context.clip();
            context.drawImage(img, 0, 0, canvas.width, canvas.height);
            context.restore();

            resolve(canvas.toDataURL());
          };
          img.src = url;
        }
      });
    });
  }
  private roundRect(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius: number): void {
    if (radius > width / 2) radius = width / 2;
    if (radius > height / 2) radius = height / 2;

    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.arcTo(x + width, y + height, x, y + height, radius);
    ctx.arcTo(x, y + height, x, y, radius);
    ctx.arcTo(x, y, x + width, y, radius);
    ctx.closePath();
  }
}
