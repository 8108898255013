<div class="p-2">
  <div class="d-flex flex-row justify-content-end">
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="d-flex flex-row modal-header justify-content-center p-2">
    <h5 class="modal-title">Verification Code</h5>
  </div>
  <div class="modal-body d-flex flex-column align-items-center p-2">
    <p class="my-3 text-center">
      Enter the code sent to the email address <strong>{{ requestEmail }}</strong>.
    </p>
    <form [formGroup]="tokenForm" class="my-3">
      <div class="row d-flex flex-row justify-content-evenly gx-4 px-2">
        <div class="form-group col-3">
          <input type="text" class="form-control rounded-3" formControlName="char1" maxlength="1" (input)="onInput($event, char2)">
        </div>
        <div class="form-group col-3">
          <input type="text" class="form-control rounded-3" formControlName="char2" maxlength="1" (input)="onInput($event, char3)" #char2 >
        </div>
        <div class="form-group col-3">
          <input type="text" class="form-control rounded-3" formControlName="char3" maxlength="1"  (input)="onInput($event, char4)" #char3>
        </div>
        <div class="form-group col-3">
          <input type="text" class="form-control rounded-3" formControlName="char4" maxlength="1" #char4>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex flex-column align-items-center p-2">
    <button type="submit" class="col btn btn-primary w-75 my-3" [disabled]="tokenForm.invalid"
            (click)="submitToken()">
      <i class="bi bi-check-circle"></i> Verify
    </button>
    <div class="my-3">
      <small>Don't see the email? Click <a
        [style]="{'text-decoration': 'underline', 'color': 'blue', 'cursor': 'pointer'}"
        (click)="resendEmail()">here</a> to resend it.</small>
    </div>
  </div>
</div>
