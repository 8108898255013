
<app-back-arrow-button></app-back-arrow-button>
<div class="order-details">
    <div class="order-info">
        <h3>Order Overview</h3>
        <p class="info-box"><strong>Order Date:</strong><br>  {{ formatDate(orderDetails.orderDate) }}</p>
        <p class="info-box"><strong>Total Cost:</strong><br>  {{ orderDetails.totalCost }}€</p>
    </div>
    <div class="order-info">
        <h3>Billing Information:</h3>
        <p class="info-box">
            <strong>Name:</strong> {{ orderDetails.billingInformation.firstName }} {{ orderDetails.billingInformation.lastName }}
        </p>
        <p class="info-box"><strong>Email:</strong> {{ orderDetails.billingInformation.email }}</p>
        <p class="info-box"><strong>Phone Number:</strong> {{ orderDetails.billingInformation.phoneNumber }}</p>
        <p class="info-box"><strong>Address:</strong> {{ orderDetails.billingInformation.address.street }}
            , {{ orderDetails.billingInformation.address.zipCode }}, {{ orderDetails.billingInformation.address.city }}
            , {{ orderDetails.billingInformation.address.country }}</p>
        <p class="info-box"><strong>Payed with:</strong> **** ****
            **** {{ orderDetails.billingInformation.cardData.last4 }}
            ({{ orderDetails.billingInformation.cardData.brand }})
            Expires {{ orderDetails.billingInformation.cardData.expMonth }}
            /{{ orderDetails.billingInformation.cardData.expYear }}</p>
    </div>
</div>

<div *ngFor="let productInfos of groupedBeeProducts" class="order">
    <img [src]="productInfos.image_Url" alt="Product Image">
    <div>
        <h2><strong>{{ productInfos.product_Name }}</strong></h2>  <h2>{{ productInfos.product_Description }} </h2>
        <h2>Quantity: {{ productInfos.count }} =  {{ productInfos.totalCost }}€</h2>
    </div>
</div>
