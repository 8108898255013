import { Component, OnInit } from '@angular/core';
import { BeeServiceService } from "../../services/bee-service.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ErrorFormatterService } from "../../services/error-formatter.service";
import { BeeCartServiceService } from "../../services/bee-cart-service.service";

@Component({
  selector: 'app-bee-product-page',
  templateUrl: './bee-product-page.component.html',
  styleUrl: './bee-product-page.component.scss'
})
export class BeeProductPageComponent implements OnInit {

  products: any[] = []; // All products from API
  filteredProducts: any[] = []; // Products after filtering
  honeyProducts: any[] = []; // Store honey products for local filtering

  selectedFilter: string = 'all'; // Tracks the selected category
  selectedJarSizes: string[] = []; // Tracks selected jar sizes
  selectedTypes: string[] = []; // Tracks selected honey types

  constructor(
      private beeService: BeeServiceService,
      private notification: ToastrService,
      private router: Router,
      private errorFormatter: ErrorFormatterService,
      private cartService: BeeCartServiceService
  ) {}

  ngOnInit(): void {
    // Initially fetch all products
    this.beeService.getProducts().subscribe((data: any) => {
      this.products = data;
      this.filteredProducts = [...this.products]; // Initially, show all products
    }, error => {
      this.notification.error(this.errorFormatter.format(error));
    });
  }

  // Filter products based on the selected category
  filterProducts(category: string): void {
    this.selectedFilter = category;

    if (category === 'honey') {
      // Fetch honey products from API and store them locally
      this.beeService.getProductsByCategory('honey').subscribe((data: any) => {
        this.honeyProducts = data; // Store honey products for local filtering
        this.filteredProducts = [...this.honeyProducts]; // Show all honey products initially
      }, error => {
        this.notification.error(this.errorFormatter.format(error));
      });
    } else {
      // For other categories, fetch products directly from the API
      this.beeService.getProductsByCategory(category).subscribe((data: any) => {
        this.filteredProducts = data; // Show products for the selected category
      }, error => {
        this.notification.error(this.errorFormatter.format(error));
      });
    }
  }

  // Toggle selection of Jar Size for honey
  toggleJarSize(size: string): void {
    if (this.selectedJarSizes.includes(size)) {
      this.selectedJarSizes = this.selectedJarSizes.filter(s => s !== size);
    } else {
      this.selectedJarSizes.push(size);
    }
    this.applyFilters(); // Apply the filter right after selecting jar size
  }

  // Toggle selection of Type for honey
  toggleType(type: string): void {
    if (this.selectedTypes.includes(type)) {
      this.selectedTypes = this.selectedTypes.filter(t => t !== type);
    } else {
      this.selectedTypes.push(type);
    }
    this.applyFilters(); // Apply the filter right after selecting type
  }

  // Apply local filters (Jar Size and Type) to honey products
  applyFilters(): void {
    if (this.selectedFilter === 'honey') {
      this.filteredProducts = this.honeyProducts.filter(product => {
        const matchesJarSize = this.selectedJarSizes.length === 0 || this.selectedJarSizes.includes(product.jarSize);
        const matchesType = this.selectedTypes.length === 0 || this.selectedTypes.includes(product.type);
        return matchesJarSize && matchesType; // Product must match both jar size and type
      });
    }
  }

  // Reset all local filters for honey products
  resetFilters(): void {
    this.selectedJarSizes = [];
    this.selectedTypes = [];
    this.filteredProducts = [...this.honeyProducts]; // Reset to show all honey products
  }

  // View product details
  productDetailView(id: number): void {
    this.router.navigate(['products/detail/', id]);
  }

  // Add product to cart
  addProductToCart(product: any): void {
    this.cartService.addToCart(product);
    this.notification.success(product.product_Name + ' added to cart');
  }
}











/*
import { Component, OnInit } from '@angular/core';
import { BeeServiceService } from "../../services/bee-service.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ErrorFormatterService } from "../../services/error-formatter.service";
import { BeeCartServiceService } from "../../services/bee-cart-service.service";

@Component({
  selector: 'app-bee-product-page',
  templateUrl: './bee-product-page.component.html',
  styleUrl: './bee-product-page.component.scss'
})
export class BeeProductPageComponent implements OnInit {

  products: any[] = []; // All products (from API)
  filteredProducts: any[] = []; // Displayed products after filtering
  honeyProducts: any[] = []; // Only honey products

  selectedFilter: string = 'all'; // Tracks the main product category
  selectedJarSizes: string[] = []; // Tracks selected jar sizes
  selectedTypes: string[] = []; // Tracks selected honey types

  constructor(
      private beeService: BeeServiceService,
      private notification: ToastrService,
      private router: Router,
      private errorFormatter: ErrorFormatterService,
      private cartService: BeeCartServiceService
  ) {}

  ngOnInit(): void {
    // Fetch all products once on initialization
    this.beeService.getProducts().subscribe((data: any) => {
      this.products = data;
      this.filteredProducts = [...this.products]; // Initially, all products are shown
      this.honeyProducts = this.products.filter(p => p.category === 'honey'); // Store honey products
    }, error => {
      this.notification.error((error));
    });
  }

  // Toggle selection of Jar Size
  toggleJarSize(size: string): void {
    if (this.selectedJarSizes.includes(size)) {
      this.selectedJarSizes = this.selectedJarSizes.filter(s => s !== size);
    } else {
      this.selectedJarSizes.push(size);
    }
  }

  // Toggle selection of Type
  toggleType(type: string): void {
    if (this.selectedTypes.includes(type)) {
      this.selectedTypes = this.selectedTypes.filter(t => t !== type);
    } else {
      this.selectedTypes.push(type);
    }
  }

  // Apply filters only when "Honey" is selected
  applyFilters(): void {
    if (this.selectedFilter === 'honey') {
      this.filteredProducts = this.honeyProducts.filter(product => {
        const matchesJarSize = this.selectedJarSizes.length === 0 || this.selectedJarSizes.includes(product.jarSize);
        const matchesType = this.selectedTypes.length === 0 || this.selectedTypes.includes(product.type);
        return matchesJarSize && matchesType;
      });
    } else {
      this.filteredProducts = this.products.filter(product => product.category === this.selectedFilter || this.selectedFilter === 'all');
    }
  }

  // Reset all filters for Honey products
  resetFilters(): void {
    this.selectedJarSizes = [];
    this.selectedTypes = [];
    this.applyFilters(); // Reapply the default filter
  }

  // Filter products based on the selected category
  filterProducts(category: string): void {
    this.selectedFilter = category;

    if (category === 'honey') {
      this.applyFilters(); // Apply filters for Honey products
    } else {
      this.filteredProducts = this.products.filter(product => product.category === category || category === 'all');
    }
  }

  productDetailView(id: number): void {
    this.router.navigate(['products/detail/', id]);
  }

  addProductToCart(product: any): void {
    this.cartService.addToCart(product);
    this.notification.success(product.product_Name + ' added to cart');
  }
}
*/


/*
import { Component, OnInit } from '@angular/core';
import { BeeServiceService } from "../../services/bee-service.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { ErrorFormatterService } from "../../services/error-formatter.service";
import { BeeCartServiceService } from "../../services/bee-cart-service.service";

@Component({
  selector: 'app-bee-product-page',
  templateUrl: './bee-product-page.component.html',
  styleUrl: './bee-product-page.component.scss'
})
export class BeeProductPageComponent implements OnInit {

  products: any[] = [];
  selectedFilter: string = 'all'; // Variable to track the selected filter

  constructor(
      private beeService: BeeServiceService,
      private notification: ToastrService,
      private router: Router,
      private route: ActivatedRoute,
      private errorFormatter: ErrorFormatterService,
      private cartService: BeeCartServiceService
  ) {}

  ngOnInit(): void {
    this.beeService.getProducts().subscribe((data: any) => {
          console.log(data);
          this.products = data;
        }, error => {
          this.notification.error((error));
        }
    );
  }

  filterProducts(category: string): void {
    this.selectedFilter = category; // Set the selected filter

    this.beeService.getProductsByCategory(category).subscribe({
      next: (data: any) => {
        this.products = data;
      },
      error: (error) => {
        this.notification.error(this.errorFormatter.format(error));
      }
    });
  }

  productDetailView(id: number): void {
    console.log(id);
    this.router.navigate(['products/detail/', id]);
  }

  addProductToCart(product: any): void {
    this.cartService.addToCart(product);
    this.notification.success(product.product_Name + ' added to cart');
  }
}
*/
