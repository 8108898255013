import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { HttpStatusCode } from '@angular/common/http';
import { ErrorFormatterService } from 'src/app/services/error-formatter.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent {
  registerForm: UntypedFormGroup;
  // After first submission attempt, form validation will start
  submitted = false;
  // Error flag
  error = false;
  errorMessage = '';
  showPassword: boolean = false;
  showConfirmedPassword: boolean = false;
  specialCharacters = "#?!@$%^&*-";
  passwordPattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{4,}$";

  constructor(private formBuilder: UntypedFormBuilder,
    private userService: UserService,
     private router: Router,
     private errorFormatter: ErrorFormatterService,
     private notification: ToastrService,) {

    this.registerForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email, Validators.minLength(1), Validators.maxLength(254)]],
      password: ["", [Validators.required, Validators.pattern(this.passwordPattern), Validators.minLength(8), Validators.maxLength(128)]],
      confirmPassword: ["", [Validators.required, this.matchControl("password")]],
      firstname: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(256)]],
      lastname: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(256)]],
      admin: [null, [Validators.required]],
    });
    this.registerForm.controls.password.valueChanges.subscribe(() => {
      this.registerForm.controls.confirmPassword.updateValueAndValidity();
    });
  }

  matchControl(matchingControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        !this.registerForm ||
        !this.registerForm.controls ||
        !control ||
        !this.registerForm.controls[matchingControlName]
      ) {
        return null;
      }
      if (
        control.value === this.registerForm.controls[matchingControlName].value
      ) {
        return null;
      }
      // Validation failed, return an error object
      return { match: true };
    };
  }


  onSubmit() {
    this.submitted = true;
    if (this.registerForm.valid) {
      this.userService.createUserByAdmin(this.registerForm.value).subscribe({
        next: () => {
          this.notification.success(
            `User ${this.registerForm.controls.email.value} successfully registered.`,
            "Registration successful!",
          );
          this.router.navigate(["/manage"]);
        },
        error: (error) => {
          if (error.status === HttpStatusCode.Conflict) {
            this.notification.error(
              this.errorFormatter.format(error),
              `Could not register the user ${this.registerForm.controls.email.value}.`,
            );
          } else if (error.status === HttpStatusCode.UnprocessableEntity) {
            this.notification.error(
              this.errorFormatter.format(error),
              `Could not register the user ${this.registerForm.controls.email.value}.`,
            );
          } else {
            this.notification.error(
              "Please try again.",
              `Could not register the user ${this.registerForm.controls.email.value}.`,
            );
          }
        },
      });
    }
  }

  togglePasswordVisibility(field: string): void {
    if (field === 'password') {
      this.showPassword = !this.showPassword;
    } else if (field === 'confirmedPassword') {
      this.showConfirmedPassword = !this.showConfirmedPassword;
    }
  }

  cancel() {
    this.router.navigate(['/manage']);
  }
}
