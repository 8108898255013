<div class="container">
  <div class="col-12 col-md-8 col-lg-6">
    <div class="form-wrapper shadow">
      <div class="d-flex justify-content-between">
        <h1 class="title">Create User</h1>
        <div class="d-flex align-items-center mb-3">
          <i class="bi bi-arrow-left"></i>
          <a [routerLink]="['/manage']" class="ms-2 link-unstyled"><strong>Back</strong></a>
        </div>
      </div>
      <form class="form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="firstname" class="form-label">Firstname</label>
          <input class="form-control"
          id="firstname"
          name="firstname"
          formControlName="firstname"
          [ngClass]="{
            'is-invalid': submitted && registerForm.controls.firstname.errors,
             'is-valid': submitted && !registerForm.controls.firstname.errors
             }">
          <div *ngIf="submitted && registerForm.controls.firstname.errors" class="invalid-feedback d-block">
            <small *ngIf="registerForm.controls.firstname.errors.required">Please enter Firstname</small>
            <small *ngIf="registerForm.controls.firstname.errors.maxlength">
                  Firstname is too long, it must be shorter then 256
                  characters!
                </small>
          </div>
        </div>

        <div class="form-group">
          <label for="lastname" class="form-label">Lastname</label>
          <input class="form-control"
          id="lastname"
          name="lastname"
          formControlName="lastname"
          [ngClass]="{
            'is-invalid': submitted && registerForm.controls.lastname.errors,
             'is-valid': submitted && !registerForm.controls.lastname.errors
             }">
          <div *ngIf="submitted && registerForm.controls.lastname.errors" class="invalid-feedback d-block">
            <small class="text-danger" *ngIf="registerForm.controls.lastname.errors.required">Please enter Lastname</small>
            <small
                  *ngIf="registerForm.controls.lastname.errors.maxlength"
                  class="form-text text-danger"
                >
                Lastname is too long, it must be shorter then 256
                  characters!
                </small>
          </div>
        </div>

        <div class="form-group">
          <label for="email" class="form-label">Email</label>
          <input class="form-control"
          id="email"
          name="email"
          formControlName="email"
          type="email"
          [ngClass]="{
            'is-invalid': submitted && registerForm.controls.email.errors,
             'is-valid': submitted && !registerForm.controls.email.errors
             }">
          <div *ngIf="submitted && registerForm.controls.email.errors" class="invalid-feedback d-block"
        >
          <small *ngIf="registerForm.controls.email.errors.required">
            Please enter Email
          </small>
          <small *ngIf="registerForm.controls.email.errors.email || registerForm.controls.email.errors.pattern">
            Please enter a valid E-Mail!
          </small>
        </div>
        </div>

        <div class="form-group">
          <label for="admin" class="form-label">Admin</label>
          <select class="form-control"
          id="admin"
          name="admin"
          formControlName="admin"
          [ngClass]="{
            'is-invalid': submitted && registerForm.controls.admin.errors,
             'is-valid': submitted && !registerForm.controls.admin.errors
             }">
            <option [ngValue]="true">Yes</option>
            <option [ngValue]="false">No</option>
          </select>
          <div *ngIf="submitted && registerForm.controls.admin.errors" class="invalid-feedback d-block">
            <small *ngIf="registerForm.controls.admin.errors.required">Please choose if the user is an admin</small>
          </div>
        </div>

        <div class="form-group">
          <label for="password">Password</label>
          <div class="input-group">
            <input
              class="form-control"
              id="password"
              name="password"
              formControlName="password"
              type="password"
              maxlength="140"
              [ngClass]="{
                       'is-invalid': submitted && registerForm.controls.password.errors,
                        'is-valid': submitted && !registerForm.controls.password.errors
                        }"
              [type]="showPassword ? 'text' : 'password'"
            />
            <button class="btn btn-secondary" type="button" id="button-see-new-password"
                    (click)="togglePasswordVisibility('password')">
              <i class="bi" [ngClass]="showPassword ? 'bi-eye-slash' : 'bi-eye'"></i>
            </button>
          </div>
          <div *ngIf="submitted && registerForm.controls.password.errors" class="invalid-feedback d-block">
            <small *ngIf="registerForm.controls.password.errors.required">Please enter Password</small>
            <div *ngIf="registerForm.controls.password.errors.pattern">
              <small> Use at least one
                uppercase letter, one lowercase letter, one digit, and one of the
                following special characters: {{ specialCharacters }}
              </small>
            </div>
            <small
              *ngIf="registerForm.controls.password.errors.minlength && !registerForm.controls.password.errors.pattern">Your
              password must be at least 8
              characters long!</small>
            <small
              *ngIf="registerForm.controls.password.errors.maxlength && !registerForm.controls.password.errors.pattern">Your
              password is too long! </small>
          </div>
        </div>
        <div class="form-group">
          <label for="passwordConfirm">Confirm Password</label>
          <div class="input-group">
            <input
              id="passwordConfirm"
              name="passwordConfirm"
              class="form-control"
              formControlName="confirmPassword"
              maxlength="140"
              [ngClass]="{
                       'is-invalid': submitted && registerForm.controls.confirmPassword.errors,
                        'is-valid': submitted && !registerForm.controls.confirmPassword.errors
                        }"
              [type]="showConfirmedPassword ? 'text' : 'password'"
            />
            <button class="btn btn-secondary" type="button" id="button-see-confirmed-password"
                    (click)="togglePasswordVisibility('confirmedPassword')">
              <i class="bi" [ngClass]="showConfirmedPassword ? 'bi-eye-slash' : 'bi-eye'"></i>
            </button>
          </div>
          <div *ngIf="submitted && registerForm.controls.confirmPassword.errors" class="invalid-feedback d-block">
            <small *ngIf="registerForm.controls.confirmPassword.errors.required">Confirm Password!</small>
            <small *ngIf="registerForm.controls.confirmPassword.errors.match">Passwords do not match!</small>
          </div>
        </div>

        <div class="form-actions">
          <button type="submit" class="btn btn-primary">Add User</button>
        </div>
      </form>
    </div>
  </div>
</div>
