
<div>
<div class="input-container">
  <div class="input-group">
    <div class="input-label">
      <label for="eventName">Name</label>
      <input
        [(ngModel)]="eventName"
        class="form-control"
        id="eventName"
        maxlength="150"
        placeholder="Name"
        style="width: 350px"
        type="text"
        [disabled]="disable"
      />
      <div
        *ngIf="
            isMaxLengthExceeded(searchParam.eventName, 150)
          "
        class="small"
      >
        Max length exceeded!
      </div>
    </div>
    <div class="input-label">
      <label for="eventType">Category</label>
      <select
        [(ngModel)]="eventType"
        class="form-select"
        id="eventType"
        type="text"
        [disabled]="disable"
      >
        <option disabled selected value="">Select category</option>
        <option value="ARTS">Arts</option>
        <option value="ENTERTAINMENT">Entertainment</option>
        <option value="FASHION">Fashion</option>
        <option value="HEALTH">Health</option>
        <option value="BUSINESS">Business</option>
        <option value="SCIENCE">Science</option>
        <option value="TRAVEL">Travel</option>
        <option value="ENVIRONMENT">Environment</option>
        <option value="COMMUNITY">Community</option>
        <option value="CHARITY">Charity</option>
        <option value="TECHNOLOGY">Technology</option>
      </select>
    </div>
    <div class="input-label">
      <label for="showContent">Content</label>
      <input
        [(ngModel)]="eventContent"
        class="form-control"
        id="showContent"
        maxlength="200"
        placeholder="Content"
        type="text"
        [disabled]="disable"
      />
      <div
        *ngIf="
            isMaxLengthExceeded(searchParam.eventContent, 200)
          "
        class="small"
      >
        Max length exceeded!
      </div>
    </div>
  </div>
</div>
  <div>
    <div class="mb-3">
      <label class="form-label">Events</label>
      <table class="table table-bordered">
        <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Event Type</th>
          <th>Content</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let event of events">
          <td><button type="button" class="bi bi-arrow-left-circle" name="event" [value]="event.id" (click)="setEventSelection(event)" [disabled]="disable"> </button> </td>
          <td>{{ event.name }}</td>
          <td>{{ event.type }}</td>
          <td>{{ event.content }}</td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>
