export interface Customer {
  name: string;
  lastname: string;
  address: string;
  email: string;
  phoneNumber: string;
}
export interface InvoiceDetail {
  id: number;
  customer: Customer;
  items: InvoiceItem[];
  invoiceDate: Date;
  invoiceType: InvoiceType;
}
export interface InvoiceItem {
  id: number;
  title: string;
  description: string;
  price: number;
  quantity: number;
  vat: number;
}

export enum InvoiceType {
  CANCEL = 'CANCEL',
  NORMAL = 'NORMAL',
}
