import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BeeServiceService} from "../../../services/bee-service.service";
import {BeeCartServiceService} from "../../../services/bee-cart-service.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-bee-detail-product',
  templateUrl: './bee-detail-product.component.html',
  styleUrls: ['./bee-detail-product.component.scss']
})
export class BeeDetailProductComponent implements OnInit {
  product: any;

  quantity: number = 1;

  constructor(private notification: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              private beeService: BeeServiceService,
              private cartService: BeeCartServiceService
  ) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const id = params['id'];
      this.fetchProductDetail(id);
    });
  }

  fetchProductDetail(id: string | number): void {
    // Replace the following with your actual service method to fetch product details by ID
    this.beeService.getProductsById(id).subscribe({
      next: (data: any) => {
        this.product = data;
      },
      error: (error) => {
        console.error("Failed to fetch product details", error);
      }
    });
  }



  increaseQuantity(): void {
    this.quantity++;
  }

  decreaseQuantity(): void {
    if (this.quantity > 1) {
      this.quantity--;
    }
  }

  addProductToCart(product: any, quantity: number): void {
    // Assuming your cart service can handle quantity, pass it along with the product
    this.cartService.addToCart(product, quantity);

    this.notification.success(`${quantity} x ${product.product_Name} added to cart`);
    this.router.navigate(['cart']);
  }
}
