import {ToastrService} from "ngx-toastr";
import {NgbModal, NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {PaymentService} from "../../services/payment.service";
import {Router} from "@angular/router";

import {animate, style, transition, trigger} from "@angular/animations";
import {countries} from "../../dtos/countries";
import {BillingInformationListDto} from "../../dtos/billingInformation";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  lastValueFrom,
  map,
  merge,
  Observable,
  OperatorFunction,
  Subject,
} from "rxjs";
import {loadStripe, Stripe, StripeCardElement, StripeElements} from "@stripe/stripe-js";

import {Component, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {OrderCartDetailListDto} from "../../dtos/order";
import {PaymentIntentRequestDto} from "../../dtos/payment";
import {BeeCartServiceService} from "../../services/bee-cart-service.service";
import {BeeOrder} from "../../dtos/beeOrder";
import {AuthService} from "../../services/auth.service";
import {PaymentModalComponent} from "./payment-modal/payment-modal.component";

@Component({
  selector: 'app-bee-checkout',
  templateUrl: './bee-checkout.component.html',
  styleUrl: './bee-checkout.component.scss',
  animations: [
    trigger("fadeIn", [
      transition(":enter", [
        style({transform: "scale(0)"}),
        animate("600ms {{delay}}ms ease-out", style({transform: "scale(1)"})),
      ]),
    ]),
  ],
})
export class BeeCheckoutComponent implements OnInit {
  order: OrderCartDetailListDto;
  checkoutForm: FormGroup;
  stripePromise: Promise<Stripe | null>;
  stripe: Stripe;
  elements: StripeElements;
  card: StripeCardElement;
  isCardValid: boolean = false;
  private testApiKey = 'pk_test_51PIBzfP4ts0USGe2rTPcP7dXIgDQ51HGShQGuZ82Q4LxCC4wl211ly8DsSBfy0pvHFCLkgVrIzg5Z7lOxIyHg4gV00vSb42EUR';

  products: any[] = []; // Ersetzen Sie any durch Ihren Produkttyp
  totalCost: number = 0;

  COUNTRIES = countries.map((country) => country.name);
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  @ViewChild("instance", {static: true}) instance: NgbTypeahead;


  constructor(
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private notification: ToastrService,
    private modalService: NgbModal,
    private router: Router,
    private beeService: BeeCartServiceService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.loadProducts();
    this.stripePromise = loadStripe(this.testApiKey);
    this.initializeStripe();


    const phoneNumberPattern = "^[0-9]+$";
    const zipCodePattern = "^[a-zA-Z0-9]+$";
    const namePattern = "^[a-zA-ZÀ-ž]+$";
    this.checkoutForm = this.formBuilder.group({
      firstName: [
        "",
        [
          Validators.required,
          Validators.pattern(namePattern),
          Validators.maxLength(255),
        ],
      ],
      lastName: [
        "",
        [
          Validators.required,
          Validators.pattern(namePattern),
          Validators.maxLength(255),
        ],
      ],
      email: [
        "",
        [Validators.required, Validators.email, Validators.maxLength(255)],
      ],
      phoneNumber: [
        "",
        [
          Validators.required,
          Validators.pattern(phoneNumberPattern),
          Validators.maxLength(50),
        ],
      ],
      address: this.formBuilder.group({
        street: ["", [Validators.required, Validators.maxLength(100)]],
        zipCode: [
          "",
          [
            Validators.required,
            Validators.pattern(zipCodePattern),
            Validators.maxLength(50),
          ],
        ],
        city: ["", [Validators.required, Validators.maxLength(100)]],
        country: ["", [Validators.required]],
      }),
    });
  }

  private async initializeStripe(): Promise<void> {
    this.stripe = await this.stripePromise;
    this.elements = this.stripe.elements();

    const cardOptions = {
      hidePostalCode: true
    };

    this.card = this.elements.create('card', cardOptions);
    this.card.mount('#card-element');

    this.card.on('change', (event) => {
      this.isCardValid = event.complete && !event.error;
    });
  }

  async handlePayment(): Promise<void> {
    if (this.checkoutForm.invalid || !this.isCardValid) {
      this.notification.error('Please fill in all required fields correctly.');
      return;
    }

    try {
      const paymentIntentRequest: PaymentIntentRequestDto = {
        amount: this.totalCost, // Example amount in cents
      };

      const response = await lastValueFrom(
        this.paymentService.createPaymentIntent(paymentIntentRequest)
      );

      const {error, paymentIntent} = await this.stripe.confirmCardPayment(response.clientSecret, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.checkoutForm.get('name').value,
            email: this.checkoutForm.get('email').value
          }
        }
      });

      if (error) {
        this.notification.error(error.message);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        this.notification.success('Payment successful!');
        this.router.navigate(['/']);
      }
    } catch (error) {
      this.notification.error('Payment failed. Please try again.');
    }
  }

  loadFormData(): BillingInformationListDto {
    return {
      firstName: this.checkoutForm.get("firstName")?.value,
      lastName: this.checkoutForm.get("lastName")?.value,
      email: this.checkoutForm.get("email")?.value,
      phoneNumber: this.checkoutForm.get("phoneNumber")?.value,
      /*    cardData: {
            last4: this.card._last4,
            brand: this.card._brand,
            expMonth: this.card._expMonth,
            expYear: this.card._expYear,
          },*/
      address: {
        street: this.checkoutForm.get("address.street")?.value,
        zipCode: this.checkoutForm.get("address.zipCode")?.value,
        city: this.checkoutForm.get("address.city")?.value,
        country: this.checkoutForm.get("address.country")?.value,
      },
    };
  }


  loadProducts(): void {
    const products = localStorage.getItem('cart');
    this.products = products ? JSON.parse(products) : [];
    console.log(this.products);
    for (let i = 0; i < this.products.length; i++) {
      console.log(this.products[i].cost);
      this.totalCost = this.totalCost + (this.products[i].cost * this.products[i].quantity);

    }

    console.log(this.totalCost);
  }

  removeProduct(index: number): void {
    this.products.splice(index, 1); // Produkt aus der Liste entfernen
    localStorage.setItem('cart', JSON.stringify(this.products)); // Aktualisieren Sie den localStorage
    // Optional: Benachrichtigung anzeigen
  }


  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>,
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen()),
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
            ? this.COUNTRIES
            : this.COUNTRIES.filter(
              (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1,
            )
        ).slice(0, 10),
      ),
    );
  };


  payForProducts() {


    const modalRef = this.modalService.open(PaymentModalComponent, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    modalRef.componentInstance.amount = this.totalCost * 100;
    modalRef.componentInstance.paymentStatus.subscribe((paymentStatus: any) => {
      if (paymentStatus.type === "success") {
        const paymentIntent = paymentStatus.data;
        console.log('Payment Intent:', paymentIntent);


        const beeOrder: BeeOrder = {
          billingInformation: {
            ...this.loadFormData(),

          },
          beeProducts: this.products,
          totalCost: this.totalCost,
          orderDate: new Date(),
          paymentIntentId: paymentIntent.id,

        };
        console.log(beeOrder);

        this.beeService.payForProducts(beeOrder).subscribe(
          {
            next: () => {
              this.notification.success("Payment successful!");
              this.clearCart();
              this.router.navigate(["/"]);
            },
            error: () => {
              this.notification.error(
                "Please contact our support.",
                "Unexpected error.",
              );
              this.router.navigate(["/"]);
            }

          });
      } else if (paymentStatus.type === "error" || paymentStatus.type === "unknownError") {
        this.notification.error("Payment declined.");
      }

    });


  }


  clearCart(): void {
    localStorage.removeItem('cart');
  }
}
