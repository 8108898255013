import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {CommonModule, NgOptimizedImage} from '@angular/common';

import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {HeaderComponent} from "./components/header/header.component";
import {FooterComponent} from "./components/footer/footer.component";
import {HomeComponent} from "./components/home/home.component";
import {LoginComponent} from "./components/login/login.component";
import {MessageComponent} from "./components/message/message.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {httpInterceptorProviders} from "./interceptors";
import {ManageUserComponent} from "./components/manage/manage-user/manage-user.component";
import {ToastrModule} from "ngx-toastr";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {RegisterComponent} from "./components/register/register.component";
import {MessageDetailsComponent} from "./components/message/message-details/message-details.component";
import {MessageItem} from "./components/message/message-item/message-item";
import {OrderOverviewComponent} from "./components/profile/order-overview/order-overview.component";
import {CreateEventComponent} from "./components/manage/create-event/create-event.component";
import {CreateNewsComponent} from "./components/manage/create-news/create-news.component";
import {CreateUserComponent} from "./components/manage/create-user/create-user.component";
import {CreatePerformanceComponent} from "./components/manage/create-performance/create-performance.component";
import {TicketItemComponent} from "./components/profile/order-overview/ticket-item/ticket-item.component";
import {EventSearchComponent} from "./components/manage/create-performance/event-search/event-search.component";
import {SelectedItemComponent} from "./components/manage/create-performance/selected-item/selected-item.component";
import {ArtistSearchComponent} from "./components/manage/create-performance/artist-search/artist-search.component";
import {
    LocationSearchComponent
} from "./components/manage/create-performance/location-search/location-search.component";
import {
    PerformanceGroupSearchComponent
} from "./components/manage/create-performance/performance-group-search/performance-group-search.component";
import {EditProfileComponent} from "./components/profile/edit-profile/edit-profile.component";
import {
    TokenInputModalComponent
} from "./components/profile/edit-profile/token-input-modal/token-input-modal.component";
import {
    UserConfirmDeleteModalComponent
} from "./components/profile/edit-profile/user-confirm-delete-modal/user-confirm-delete-modal.component";
import {ResetPasswordComponent} from "./components/reset-password/reset-password.component";
import {
    TokenResetPasswordInputModalComponent
} from "./components/reset-password/token-input-modal/token-input-modal.component";

import {TopTenItemComponent} from './components/home/top-ten-item/top-ten-item.component';
import {
    ConfirmUserPasswordResetModalComponent
} from "./components/manage/manage-user/confirm-user-passsword-reset-modal/confirm-user-password-reset-modal.component";
import {CreateEventHallComponent} from "./components/manage/create-event-hall/create-event-hall.component";
import {CreateLocationComponent} from "./components/manage/create-location/create-location.component";
import {BeeHomeLandingPageComponent} from "./components/home/bee-home-landing-page/bee-home-landing-page.component";
import {BeeCartComponent} from "./components/bee-cart/bee-cart.component";
import {BeeDetailProductComponent} from "./components/bee-product-page/bee-detail-product/bee-detail-product.component";
import {BeeProductPageComponent} from "./components/bee-product-page/bee-product-page.component";
import {BeeCheckoutComponent} from "./components/bee-checkout/bee-checkout.component";
import {PaymentModalComponent} from "./components/bee-checkout/payment-modal/payment-modal.component";
import {
    OrderDetailViewComponent
} from "./components/profile/order-overview/order-detailview/order-detailview.component";
import {BackArrowButtonComponent} from "./components/back-arrow-button/back-arrow-button.component";


@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        LoginComponent,
        MessageComponent,
        RegisterComponent,
        ManageUserComponent,
        MessageItem,
        MessageDetailsComponent,
        CreateEventComponent,
        CreateNewsComponent,
        CreateUserComponent,
        CreatePerformanceComponent,
        MessageDetailsComponent,
        OrderOverviewComponent,
        MessageDetailsComponent,
        EditProfileComponent,
        TokenInputModalComponent,
        UserConfirmDeleteModalComponent,
        ResetPasswordComponent,
        TokenResetPasswordInputModalComponent,
        TopTenItemComponent,
        CreateLocationComponent,
        ConfirmUserPasswordResetModalComponent,
        CreateEventHallComponent,
        BeeHomeLandingPageComponent,
        BeeProductPageComponent,
        BeeDetailProductComponent,
        BeeCartComponent,
        PaymentModalComponent,
        BeeCheckoutComponent,
        OrderDetailViewComponent,
        BackArrowButtonComponent,

    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
        NgbModule,
        FormsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            extendedTimeOut: 1000,
            positionClass: "custom-toast-position",
            preventDuplicates: true,
            progressBar: true,
            closeButton: true,
            tapToDismiss: true,
            enableHtml: true,
        }),
        // Needed for Toastr
        BrowserAnimationsModule,
        NgOptimizedImage,
        TicketItemComponent,
        EventSearchComponent,
        SelectedItemComponent,
        ArtistSearchComponent,
        LocationSearchComponent,
        PerformanceGroupSearchComponent,
    ],
    providers: [httpInterceptorProviders],
    bootstrap: [AppComponent],
})
export class AppModule {
}
