


<div>
  <div class="input-container">
    <div class="input-group">
      <div class="input-label col-6">
        <label for="locationSearchTerm">Location Search</label>
        <input
          [(ngModel)]="searchParam"
          class="form-control"
          id="locationSearchTerm"
          maxlength="150"
          placeholder="Name"
          type="text"
          [disabled]=disable
        />
        <div
          *ngIf="
            isMaxLengthExceeded(searchParam, 150)
          "
          class="small"
        >
          Max length exceeded!
        </div>
      </div>
      <div class="input-label col-5">
        <label for="countrySearch">Country</label>
        <input
          [(ngModel)]="countrySearchParam"
          class="form-control"
          id="countrySearch"
          maxlength="150"
          placeholder="Name"
          type="text"
          [disabled]=disable
        />
        <div
          *ngIf="
            isMaxLengthExceeded(searchParam, 150)
          "
          class="small"
        >
          Max length exceeded!
        </div>
      </div>
      <div class="input-label">
        <label for="citySearch">City</label>
        <input
          [(ngModel)]="citySearchParam"
          class="form-control"
          id="citySearch"
          maxlength="150"
          placeholder="Name"
          type="text"
          [disabled]=disable
        />
        <div
          *ngIf="
            isMaxLengthExceeded(searchParam, 150)
          "
          class="small"
        >
          Max length exceeded!
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="mb-3">
      <label class="form-label">Artists</label>
      <table class="table table-bordered">
        <thead>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Street</th>
          <th>City</th>
          <th>Country</th>
          <th>Postal Code</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let location of locations">
          <td><button type="button" class="bi bi-arrow-left-circle" name="location" [value]="location.id" (click)="setLocationSelection(location)" [disabled]="disable"></button></td>
          <td>{{ location.description }}</td>
          <td>{{ location.address.street }}</td>
          <td>{{ location.address.city }}</td>
          <td>{{ location.address.country }}</td>
          <td>{{ location.address.zipCode}}</td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>

