import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LocationListDto} from "../../../../dtos/location";
import {LocationSearchParamDto} from "../../../../dtos/search";
import {debounceTime, Subject, switchMap} from "rxjs";
import {LocationService} from "../../../../services/location.service";
import {EventListDto} from "../../../../dtos/event";

@Component({
  selector: 'app-location-search',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './location-search.component.html',
  styleUrl: './location-search.component.scss'
})
export class LocationSearchComponent implements OnInit{

  @Input() disable = false;
  _searchParam: LocationSearchParamDto = {};
  locations: LocationListDto[] = [];
  @Output() selectedLocation = new EventEmitter<LocationListDto>();
  search$ = new Subject<void>();

  constructor(private locationsService: LocationService) {

  }

  ngOnInit() {
    this.search$.pipe(
      debounceTime(200),
      switchMap(() => this.locationsService.search(this._searchParam))
    ).subscribe((locations) => {
      this.locations = locations;
    });
    this.search$.next();
  }

  isMaxLengthExceeded(input: string, maxLength: number): boolean {
    return input ? input.length >= maxLength : false;
  }

  get searchParam() {
    return this._searchParam.description;
  }

  set searchParam(searchParam) {
    this._searchParam.description = searchParam;
    this.search$.next()
  }

  get countrySearchParam() {
    return this._searchParam.country;
  }

  set countrySearchParam(country) {
    this._searchParam.country = country;
    this.search$.next()
  }

  get citySearchParam() {
    return this._searchParam.city;
  }

  set citySearchParam(city) {
    this._searchParam.city = city;
    this.search$.next()
  }

  setLocationSelection(location: LocationListDto) {
    this.selectedLocation.emit(location);
  }
}
