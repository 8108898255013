<head>
  <link href="https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap" rel="stylesheet">
  <link rel="stylesheet" href="https://cdn.materialdesignicons.com/5.4.55/css/materialdesignicons.min.css">
  <link href="https://fonts.googleapis.com/css2?family=Pacifico&display=swap" rel="stylesheet">


</head>

<nav class="navbar navbar-expand-md sticky-header">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <i class="mdi mdi-bee" style="font-size: 40px;"></i>
      <div class="companyName">NektarOase</div>
    </a>
    <button
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
      class="navbar-toggler custom-toggler"
      data-bs-target="#navbarNavDropdown"
      data-bs-toggle="collapse"
      type="button"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav mx-auto mb-2 mb-md-0">
        <li class="nav-item">
          <a
            [routerLinkActiveOptions]="{ exact: true }"
            class="nav-link"
            href="#"
            routerLink="/"
            routerLinkActive="active"
          ><i class="bi bi-house"></i> Home</a
          >
        </li>

        <li class="nav-item">
          <a class="nav-link" routerLink="/products" routerLinkActive="active"
          ><i class="bi bi-basket-fill "></i> Products</a
          >
        </li>
<!--
        <li class="nav-item">
          <a class="nav-link" routerLink="/message" routerLinkActive="active"
          ><i class="bi bi-card-text"></i> News</a
          >
        </li>-->
        <!--  <li class="nav-item">
            <a class="nav-link" routerLink="/search" routerLinkActive="active"
            ><i class="bi bi-search"></i> Search</a
            >
          </li>-->
        <li class="nav-item">
          <a
            *ngIf=" authService.getUserRole() === 'ADMIN' && authService.isLoggedIn()"
            class="nav-link"
            routerLink="/manage"
            routerLinkActive="active"
          ><i class="bi bi-gear"></i> Manage</a
          >
        </li>
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item">
          <a
            class="nav-link"
            href="#"
            routerLink="/cart"
            routerLinkActive="active"
          ><i class="bi bi-cart"></i> Cart</a
          >
        </li>


        <li class="nav-item dropdown">
          <a
            aria-expanded="false"
            aria-haspopup="true"
            class="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            href="#"
            role="button"

          >
            <i class="bi bi-person"></i> My Profile

          </a>
          <div
            aria-labelledby="navbarDropdownMenuLink"
            class="dropdown-menu dropdown-menu-end"
            id="navbar-dropdown-menu"
          >
            <a
              *ngIf="authService.isLoggedIn()"
              class="dropdown-item"
              routerLink="/profile/order/overview"
            >My Orders</a
            >
            <a
              *ngIf="authService.isLoggedIn()"
              class="dropdown-item"
              routerLink="/profile/settings"
            >Edit Profile</a
            >
            <div
              *ngIf="authService.isLoggedIn()"
              class="dropdown-divider"
            ></div>
            <!-- Dropdown-Trenner -->
            <a
              *ngIf="!authService.isLoggedIn()"
              class="dropdown-item"
              routerLink="/register"
            >Sign Up</a
            >
            <a
              *ngIf="!authService.isLoggedIn()"
              class="dropdown-item"
              routerLink="/login"
            >Login</a
            >
            <a
              (click)="authService.logoutUser()"
              *ngIf="authService.isLoggedIn()"
              class="dropdown-item"
              routerLink="/"
            >Logout</a
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
