<ng-container>
    <div class="imageOfBees">

        <img *ngIf="imageUrl1" [src]="imageUrl1" alt="Bee Home Landing Page Image" loading="lazy" class="first-image">

        <div class="imageOfBees-text">
            <img *ngIf="imageUrl2" [src]="imageUrl2" alt="Second Image" loading="lazy" class="second-image">
            <h1 class="bild-text-titel" style="color:#F2B705 ">Willkommen zu unserer Bienen Farm</h1>
            <p>We sind ein familien Betrieb</p>
        </div>
    </div>

    <div class="beekeeping" id="beekeeping">


        <!-- Einführung und Bedeutung der Imkerei -->
        <div class="bild-text">
            <div class="bild-text image">
                <img src="assets/pictures/heilhonig3.jpg" loading="lazy">
            </div>
            <div class="bild-text text">
                <div class="bild-text-titel">
                    <h2  >Wundermittel Honig</h2>
                </div>
                <div>

                    <p>
                        Honig ist ein natürliches Wundermittel, das reich an Antioxidantien, Vitaminen und Mineralien
                        ist.
                        Er unterstützt das Immunsystem, fördert die Verdauung und wirkt entzündungshemmend.
                        Zudem kann Honig bei der Heilung von Wunden helfen und als natürliches Süßungsmittel eine
                        gesunde
                        Alternative zu raffiniertem Zucker bieten.
                        Regelmäßiger Konsum von Honig kann somit nicht nur Ihre allgemeine Gesundheit verbessern,
                        sondern auch
                        das Wohlbefinden steigern.
                        Entdecken Sie die kraftvolle Wirkung von Honig – direkt aus der Natur </p>
                </div>


            </div>


        </div>


        <div class="bild-text" style="margin-bottom: 20vh">

            <div class="bild-text text">
                <div>
                    <h2 class="bild-text-titel">fleißige Bienen</h2>
                </div>
                <div>

                    <p>
                        Bienen sind unersetzlich für unser Ökosystem, da sie als Hauptbestäuber den Fortbestand vieler
                        Pflanzenarten sichern.
                        Durch ihre Bestäubungsarbeit tragen sie entscheidend zur Vielfalt und Gesundheit unserer Natur
                        bei,
                        indem sie die Vermehrung von über 80% der Blütenpflanzen ermöglichen.
                        Ohne Bienen würden nicht nur viele Obst- und Gemüsesorten verschwinden, sondern auch ganze
                        Ökosysteme
                        ins Wanken geraten.
                        Der Schutz der Bienen bedeutet also auch den Schutz unserer Umwelt und unserer
                        Nahrungsgrundlage. Lernen
                        Sie, warum diese kleinen Insekten für das Gleichgewicht der Natur so unverzichtbar sind.
                    </p>
                </div>


            </div>
            <div class="bild-text image">
                <img src="assets/pictures/fleißigeBiene.jpg" loading="lazy">
            </div>


        </div>

        <h1 class="bild-text-titel"> Geschichte der Farm</h1>

        <div class="bild-text">
            <div class="bild-text image">
                <img src="assets/pictures/IMG_4463%20Kopie.jpg" loading="lazy">
            </div>

            <div class="bild-text text">

                <div>
                    <h2 class="bild-text-titel">Wie alles begann</h2>
                </div>
                <div>
                    <p>
                        my great grandpa has first started out with a small bee farm in the 1900s. later my grandpa
                        would take over
                        and
                    </p>
                </div>

            </div>

            <!--
                  <div id="bee-container">
                    <img id="bee" src="https://www.flaticon.com/free-icons/bee" alt="Bee">
                  </div>-->

        </div>


        <div class="bild-text">
            <div class="bild-text text">

                <div>
                    <h2 class="bild-text-titel"> familiärer zusammenhalt</h2>
                </div>
                <div>
                    <p>
                        since my beloved grandfather has passed away we have been taken over his legacy and still supply
                        a wide range of costumers in hungary

                    </p>
                </div>

            </div>


            <div class="bild-text image">
                <img src="assets/pictures/IMG_3232.jpg" loading="lazy">
            </div>


        </div>


        <div class="bild-text" style="margin-bottom: 20vh">

            <div class="bild-text image">
                <img src="https://i.imgur.com/iTFHdQm.png" loading="lazy">
            </div>


            <div class="bild-text text">

                <div>
                    <h2 class="bild-text-titel">4. Generation</h2>
                </div>
                <div>
                    <p>
                        starting honey production in austria in gießhübl, we have been able to expand our business and
                        reach a wider
                        audience. we are proud to be able to provide our customers with the best quality honey and bee
                        products.
                        lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                </div>

            </div>


        </div>

        <h1 class="bild-text-titel"> Produkte</h1>


        <table style="margin-top: 10vh" class="table">
            <thead>
            <!--   <tr class="table-header">
                   <th></th>
                   <th>Name</th>
                   <th>Ideal..</th>
                   <th>Geschmack</th>
               </tr>-->
            </thead>
            <tbody>
            <tr class="produkt-unterschiede">
                <td>
                    <div class="manipulate-image">
                        <img src="assets/pictures/rapshonig.jpg" loading="lazy" alt="Rapshonig">
                    </div>
                </td>
                <td>
                    <p>

                        <strong class="bild-text-titel">Rapshonig</strong>
                    </p>
                </td>
                <td>
                    <p>
                        Ideal zum Süßen von Joghurt oder Haferflocken, Tee und als Brotaufstrich
                    </p>
                </td>
                <td>
                    <p>
                        Mild
                        Süß
                        Zart cremig
                        Leicht blumig
                    </p>
                </td>
            </tr>

            <tr class="produkt-unterschiede">
                <td>
                    <div class="manipulate-image">
                        <img src="assets/pictures/akazienhonig.jpg" loading="lazy" alt="Akazienhonig">
                    </div>
                </td>
                <td>


                    <p>
                        <strong class="bild-text-titel">Akazienhonig</strong>
                    </p>
                </td>
                <td>
                    <p>
                        Geeignet zu Früchten, Smoothies oder Müsli, milden Käse
                    </p>
                </td>
                <td>
                    <p>
                        Mild
                        Sehr süß
                        Zart fruchtig
                        Wenig intensiv, neutral
                    </p>
                </td>
            </tr>

            <tr class="produkt-unterschiede">
                <td>
                    <div class="manipulate-image">
                        <img src="assets/pictures/bluetenhonig.jpg" loading="lazy" alt="Blütenhonig">
                    </div>
                </td>

                <td>


                    <p>
                        <strong class="bild-text-titel">Blütenhonig</strong>
                    </p>
                </td>
                <td>
                    <p>
                        Optimal für Marinaden von Geflügel und Salatdressing
                    </p>
                </td>
                <td>
                    <p>
                        Vielseitig
                        Leicht fruchtig
                        Blumig
                        Milde Süße
                    </p>
                </td>
            </tr>

            <tr class="produkt-unterschiede">
                <td class="man-bild">
                    <div class="manipulate-image">
                        <img src="assets/pictures/kastanienhonig.jpg" loading="lazy" alt="Kastanienhonig">
                    </div>
                </td>
                <td>


                    <p>
                        <strong class="bild-text-titel">Kastanienhonig</strong>
                    </p>
                </td>

                <td>
                    <p>
                        Perfekt zum Verzehr mit Ziegenkäse oder Blauschimmelkäse
                    </p>
                </td>
                <td>
                    <p>
                        Kräftig
                        Herb-bitter
                        Würzig
                        Dezent malzi
                    </p>
                </td>
            </tr>
            </tbody>
        </table>

    </div>

    <!--

        &lt;!&ndash; Honigproduktion und -verarbeitung &ndash;&gt;
        <div class="honey-production">
            <div class="production-image">
                &lt;!&ndash;    <img src="https://i.imgur.com/iTFHdQm.png" loading="lazy" alt="Honey production">&ndash;&gt;
            </div>
            <div class="production-text">
                <h4>Honey Production</h4>
                <p>
                    The process of producing our honey is a labor of love. After our bees have collected nectar from the
                    surrounding flora, we carefully harvest the honey using methods that preserve its natural qualities.
                    From extraction to filtration and packaging, each step is handled with utmost care to ensure that
                    our customers receive honey that is as pure and flavorful as nature intended.
                </p>
            </div>
        </div>

        &lt;!&ndash; Bienenprodukte und deren Vorteile &ndash;&gt;
        <div class="bee-products">
            <h4>Our Bee Products</h4>
            <p>
                In addition to honey, we offer a range of bee products including beeswax, propolis, and royal jelly.
                Each of these products has unique health benefits and uses, from natural skincare solutions to dietary
                supplements that support overall wellness. Our commitment to quality ensures that you get the best of
                what nature has to offer.
            </p>
        </div>

        &lt;!&ndash; Nachhaltigkeit und Umweltschutz &ndash;&gt;
        <div class="sustainability">
            <h4>Commitment to Sustainability</h4>
            <p>
                We believe that beekeeping should not only produce great honey but also contribute positively to the
                environment. Our practices are designed to protect and support bee populations, ensuring their survival
                for generations to come. By choosing our products, you are also supporting sustainable agriculture and
                environmental conservation.
            </p>
        </div>

        &lt;!&ndash; Bienenpatenschaften und Bildungsinitiativen &ndash;&gt;
        <div class="community-initiatives">
            <h4>Community and Education</h4>
            <p>
                We are proud to offer bee sponsorships and educational programs that allow people to get involved with
                beekeeping. These initiatives help raise awareness about the importance of bees and provide hands-on
                experience in sustainable beekeeping practices. Join us in making a difference for the future of our
                planet.
            </p>
        </div>

        &lt;!&ndash; Zukunftsvision und Innovationen &ndash;&gt;
        <div class="future-vision">
            <h4>The Future of Our Beekeeping</h4>
            <p>
                As we look to the future, we remain committed to innovation in beekeeping. We are exploring new methods
                to enhance our honey production while maintaining the health of our bees and the quality of our
                products. Our goal is to continue growing our family tradition, reaching more people with our products,
                and promoting a healthy, sustainable relationship with nature.
            </p>
        </div>

    -->

</ng-container>


<!-- <div class="AboutUs" id="about-us">
     <h1>About Us</h1>

     <div class="about-us-grandpa">
         <div class="about-us-grandpa image">
             <img src="assets/pictures/IMG_4463%20Kopie.jpg" loading="lazy">
         </div>

         <div class="about-us-grandpa text">

             <div>
                 <h4> how it started</h4>
             </div>
             <div>
                 <p>
                     my great grandpa has first started out with a small bee farm in the 1900s. later my grandpa
                     would take over
                     and
                 </p>
             </div>

         </div>

         &lt;!&ndash;
               <div id="bee-container">
                 <img id="bee" src="https://www.flaticon.com/free-icons/bee" alt="Bee">
               </div>&ndash;&gt;

     </div>


     <div class="about-us-grandpa">
         <div class="about-us-grandpa text">

             <div>
                 <h4> taking over</h4>
             </div>
             <div>
                 <p>
                     since my beloved grandfather has passed away we have been taken over his legacy and still supply
                     a wide range of costumers in hungary

                 </p>
             </div>

         </div>


         <div class="about-us-grandpa image">
             <img src="assets/pictures/IMG_3232.jpg" loading="lazy">
         </div>


     </div>


     <div class="about-us-grandpa">

         <div class="about-us-grandpa image">
             <img src="https://i.imgur.com/iTFHdQm.png" loading="lazy">
         </div>


         <div class="about-us-grandpa text">

             <div>
                 <h4>new chapter</h4>
             </div>
             <div>
                 <p>
                     starting honey production in austria in gießhübl, we have been able to expand our business and
                     reach a wider
                     audience. we are proud to be able to provide our customers with the best quality honey and bee
                     products.
                     lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                     labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                     laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                     voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                     non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                 </p>
             </div>

         </div>


     </div>

 </div>-->


<!--
    <div class="bild-text-produkte">

        <div class="bild-text-produkte image">
            <img src="assets/pictures/bluetenhonig.jpg" loading="lazy">
        </div>

        <div class="bild-text-produkte text">
            <div>
                <h4>Blütenhonig</h4>
            </div>
            <div>
                <p>
                    starting honey production in austria in gießhübl, we have been able to expand our business and
                    reach a wider
                    audience. we are proud to be able to provide our customers with the best quality honey and bee
                    products.
                    lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                    non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </div>

        </div>


    </div>
    <div class="bild-text-produkte">

        <div class="bild-text-produkte image">
            <img src="assets/pictures/kastanienhonig.jpg" loading="lazy">
        </div>

        <div class="bild-text-produkte text">
            <div>
                <h4>Kastanienhonig</h4>
            </div>
            <div>
                <p>
                    starting honey production in austria in gießhübl, we have been able to expand our business and
                    reach a wider
                    audience. we are proud to be able to provide our customers with the best quality honey and bee
                    products.
                    lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                    non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </div>

        </div>


    </div>


    <div class="bild-text-produkte">

        <div class="bild-text-produkte image">
            <img src="assets/pictures/akazienhonig.jpg" loading="lazy">
        </div>

        <div class="bild-text-produkte text">
            <div>
                <h4>Rapshonig</h4>
            </div>
            <div>
                <p>
                    Raps ist eine gelb blühende Pflanze, die oft zur Gewinnung von Pflanzenöl angebaut wird.
                    Sie ist in der Landwirtschaft weit verbreitet und bietet Bienen reichlich Nektar.

                    Rapshonig ist ein heller, schnell kristallisierender Honig, der aus dem Nektar der Rapsblüten
                    gewonnen wird.
                    Er hat eine cremige bis feste Konsistenz, eine fast weiße Farbe und einen milden, leicht süßlichen
                    Geschmack.
                </p>
            </div>

        </div>


    </div>


    <div class="bild-text-produkte">

        <div class="bild-text-produkte image">
            <img src="assets/pictures/akazienhonig.jpg" loading="lazy">
        </div>

        <div class="bild-text-produkte text">
            <div>
                <h4>Akazienhonig</h4>
            </div>
            <div>
                <p>
                    starting honey production in austria in gießhübl, we have been able to expand our business and
                    reach a wider
                    audience. we are proud to be able to provide our customers with the best quality honey and bee
                    products.
                    lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                    non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </div>

        </div>

-->



