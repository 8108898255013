<div class="container shadow bg-white" style="margin-top: 5rem; border-radius:10px;padding:1.5rem;">

  <div class="d-flex justify-content-between">
    <h1 class="text-center title">Profile Settings</h1>
    <div class="d-flex align-items-center mb-3">
      <i class="bi bi-arrow-left"></i>
      <a [routerLink]="['/']" class="ms-2 link-unstyled"><strong>Back</strong></a>
    </div>
  </div>

  <div class="row justify-content-center p-2">
    <div class="col-7">
      <form [formGroup]="emailForm">
        <h2 class="text-start">Email Address Settings</h2>
        <div class="form-group">
          <label for="inputNewEmailAddress">New Email Address</label>
          <input
            class="form-control w-100"
            [ngClass]="{
                           'is-invalid': emailFormSubmitted && emailForm.get('newEmailAddress')?.errors,
                            'is-valid': emailFormSubmitted && !emailForm.get('newEmailAddress')?.errors
                            }"
            formControlName="newEmailAddress"
            id="inputNewEmailAddress"
            type="email"
            maxlength="140"
          />
          <div *ngIf="emailFormSubmitted && emailForm.get('newEmailAddress')?.errors" class="d-block invalid-feedback">
            <small *ngIf="emailForm.get('newEmailAddress')?.hasError('required')">
              New email address is required.
            </small>
            <small *ngIf="emailForm.get('newEmailAddress')?.hasError('email')">
              New email address has incorrect format.
            </small>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-end">
          <button type="submit" class="btn btn-primary w-25" (click)="onEmailUpdate()">
            Update Email
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="row justify-content-center p-2">
    <div class="col-7">
      <form [formGroup]="passwordForm">
        <h2 class="text-start">Password Settings</h2>
        <div class="form-group">
          <label for="inputOldPassword">Old Password</label>
          <div class="input-group mt-1">
            <input
              class="form-control"
              [ngClass]="{
                           'is-invalid': passwordFormSubmitted && passwordForm.get('oldPassword')?.errors,
                            'is-valid': passwordFormSubmitted && !passwordForm.get('oldPassword')?.errors
                            }"
              formControlName="oldPassword"
              id="inputOldPassword"
              [type]="showOldPassword ? 'text' : 'password'"
              aria-describedby="button-see-old-password"
              maxlength="140"
            />
            <button class="btn btn-secondary" type="button" id="button-see-old-password"
                    (click)="togglePasswordVisibility('oldPassword')">
              <i class="bi" [ngClass]="showOldPassword ? 'bi-eye-slash' : 'bi-eye'"></i>
            </button>
          </div>
          <div *ngIf="passwordFormSubmitted && passwordForm.get('oldPassword')?.errors"
               class="d-block invalid-feedback">
            <small *ngIf="passwordForm.get('oldPassword').hasError('required')">
              Old password is required!
            </small>
            <small
              *ngIf="passwordForm.get('oldPassword')?.hasError('minlength') && !passwordForm.get('oldPassword')?.hasError('pattern')">
              Old password should contain at least 8 characters!
            </small>
            <small
              *ngIf="passwordForm.get('oldPassword')?.hasError('maxlength') && !passwordForm.get('oldPassword')?.hasError('pattern')">
              Old password should contain at most 128 characters!
            </small>
            <small *ngIf="passwordForm.get('oldPassword')?.hasError('pattern')">
              Old password has the wrong format!
            </small>
          </div>
        </div>
        <div class="form-group">
          <label for="inputNewPassword">New Password</label>
          <div class="input-group mt-1">
            <input
              class="form-control"
              [ngClass]="{
                           'is-invalid': passwordFormSubmitted && passwordForm.get('newPassword')?.errors,
                            'is-valid': passwordFormSubmitted && !passwordForm.get('newPassword')?.errors
                            }"
              formControlName="newPassword"
              id="inputNewPassword"
              [type]="showNewPassword ? 'text' : 'password'"
              aria-describedby="button-see-new-password"
              maxlength="140"
            />
            <button class="btn btn-secondary" type="button" id="button-see-new-password"
                    (click)="togglePasswordVisibility('newPassword')">
              <i class="bi" [ngClass]="showNewPassword ? 'bi-eye-slash' : 'bi-eye'"></i>
            </button>
          </div>
          <div *ngIf="passwordFormSubmitted && passwordForm.get('newPassword')?.errors"
               class="invalid-feedback d-block">
            <small *ngIf="passwordForm.get('newPassword')?.hasError('required')">
              New password is required.
            </small>
            <small
              *ngIf="passwordForm.get('newPassword')?.hasError('minlength') && !passwordForm.get('newPassword')?.hasError('pattern')">
              New password must be at least 8 characters long.
            </small>
            <small
              *ngIf="passwordForm.get('newPassword')?.hasError('maxlength') && !passwordForm.get('newPassword')?.hasError('pattern')">
              New password cannot be more than 128 characters long.
            </small>
            <small *ngIf="passwordForm.get('newPassword')?.hasError('pattern')">
              Use at least one uppercase letter, one lowercase letter, one digit, and one of the
              following characters: {{ specialCharacters }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label for="inputConfirmedPassword">Confirm New Password</label>
          <div class="input-group mt-1">
            <input
              class="form-control"
              [ngClass]="{
                           'is-invalid': passwordFormSubmitted && passwordForm.get('confirmedPassword')?.errors,
                            'is-valid': passwordFormSubmitted && !passwordForm.get('confirmedPassword')?.errors
                            }"
              formControlName="confirmedPassword"
              id="inputConfirmedPassword"
              [type]="showConfirmedPassword ? 'text' : 'password'"
              aria-describedby="button-see-repeated-new-password"
              maxlength="140"
            />
            <button class="btn btn-secondary" type="button" id="button-see-confirmed-password"
                    (click)="togglePasswordVisibility('confirmedPassword')">
              <i class="bi" [ngClass]="showConfirmedPassword ? 'bi-eye-slash' : 'bi-eye'"></i>
            </button>
          </div>
          <div
            *ngIf="passwordFormSubmitted && passwordForm.get('confirmedPassword')?.errors"
            class="d-block invalid-feedback">
            <small *ngIf="passwordForm.get('confirmedPassword').hasError('required')">
              You have to repeat your new password.
            </small>
            <small *ngIf="passwordForm.get('confirmedPassword')?.hasError('mismatch')">
              Passwords do not match.
            </small>
          </div>
        </div>
        <div class="d-flex flex-row justify-content-end">
          <button type="submit" class="btn btn-primary w-25"
                  (click)="onPasswordUpdate()">Update Password
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="row justify-content-center p-2">
    <div class="col-7">
      <h2 class="text-start">Profile Deletion</h2>
      <div class="my-3">
        Do you want to delete your account? Click <a
        [style]="{'text-decoration': 'underline', 'color': 'blue', 'cursor': 'pointer'}" (click)="onDeleteAccount()">here</a>
        to delete it.
      </div>
    </div>
  </div>
</div>

