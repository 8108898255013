import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Message} from 'src/app/dtos/message';
import {MessageService} from 'src/app/services/message.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {ErrorFormatterService} from 'src/app/services/error-formatter.service';
import {HttpStatusCode} from "@angular/common/http";
import {UserService} from 'src/app/services/user.service';
import {AuthService} from 'src/app/services/auth.service';
import {Location} from "@angular/common";

@Component({
  selector: 'app-message-details',
  templateUrl: './message-details.component.html',
  styleUrl: './message-details.component.scss'
})
export class MessageDetailsComponent implements OnInit {
  message: Message;
  image: SafeResourceUrl;
  userId: number;


  constructor(
    private service: MessageService,
    private userService: UserService,
    private route: ActivatedRoute,
    private notification: ToastrService,
    private sanitizer: DomSanitizer,
    private errorFormatter: ErrorFormatterService,
    private authService: AuthService,
    private location: Location,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.userId = this.authService.getUserId();
    this.loadMessage();
    this.addToViewedMessages();
  }

  loadMessage() {
    const params = this.route.snapshot.paramMap;
    const messageId = Number(params.get('id'));
    this.service.getMessageById(messageId).subscribe({
        next: res => {
          this.message = res
          this.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,'
            + this.message.image);
        },
        error: error => {
          if (error.status === HttpStatusCode.NotFound) {
            this.notification.error(this.errorFormatter.format(error, "News item was not found."), "Could not fetch news item.")
          } else {
            this.notification.error("Please try again.", "Could not fetch news item.");
          }
        }
      }
    );
  }

  addToViewedMessages() {
    const params = this.route.snapshot.paramMap;
    const messageId = Number(params.get('id'));
    this.userService.addNewsToViewedNews(this.userId, messageId).subscribe({
      error: error => {
        if (error.status === HttpStatusCode.NotFound) {
          this.notification.error(this.errorFormatter.format(error), "Could not add news to viewed news.")
        } else {
          this.notification.error("Message not marked as viewed.", "Could not add news to viewed news.");
        }
      }
    })
  }

  goBack() {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/home']);
    }
  }
}


