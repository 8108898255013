
<app-back-arrow-button></app-back-arrow-button>
<h2 style="font-style: oblique" class="title">Checkout</h2>
<div class="container mt-4">


  <div *ngIf="products.length > 0" class="products-items">
    <h3>Products in Cart</h3>
    <div class="list-group">
      <div class="list-group-item product-item" *ngFor="let product of products; let i = index">
        <div class="product-details">
          <img *ngIf="product.image_Url" [src]="product.image_Url" alt="Product Image" loading="lazy">
          <span class="product-name">{{ product.product_Name }}</span>
          <span class="product-quantity">{{ product.quantity }}</span>
          <span class="product-cost">{{ product.cost * product.quantity | currency:'EUR' }}</span>
          <button class="btn btn-danger btn-sm remove-btn" (click)="removeProduct(i)">Remove</button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="products.length === 0">
    <p>No products in the cart.</p>
  </div>
  <div class="payment-info">
    <form [formGroup]="checkoutForm" (ngSubmit)="handlePayment()">
      <h2>Billing Information</h2>
      <div class="row justify-content-between">
        <div class="form-group col-lg-6">
          <label for="inputFirstName">First name</label>
          <input
            [ngClass]="{
            'is-invalid': checkoutForm.controls.firstName.errors && checkoutForm.controls.firstName.touched,
            'is-valid': !checkoutForm.controls.firstName.errors && checkoutForm.controls.firstName.touched
          }"
            class="form-control"
            formControlName="firstName"
            id="inputFirstName"
            type="text"
          />
          <div *ngIf="checkoutForm.controls.firstName.errors && checkoutForm.controls.firstName.touched"
               class="invalid-feedback d-block">
            <small *ngIf="checkoutForm.controls.firstName.errors?.required">Please provide your first name.</small>
            <small *ngIf="checkoutForm.controls.firstName.errors?.pattern">Your first name has wrong format.</small>
            <small *ngIf="checkoutForm.controls.firstName.errors?.maxlength">Your first name is too long.</small>
          </div>
        </div>
        <div class="form-group col-lg-6">
          <label for="inputLastName">Last name</label>
          <input
            [ngClass]="{
            'is-invalid': checkoutForm.controls.lastName.errors && checkoutForm.controls.lastName.touched,
            'is-valid': !checkoutForm.controls.lastName.errors && checkoutForm.controls.lastName.touched
          }"
            class="form-control"
            formControlName="lastName"
            id="inputLastName"
            type="text"
          />
          <div *ngIf="checkoutForm.controls.lastName.errors && checkoutForm.controls.lastName.touched"
               class="invalid-feedback d-block">
            <small *ngIf="checkoutForm.controls.lastName.errors?.required">Please provide your last name.</small>
            <small *ngIf="checkoutForm.controls.lastName.errors?.pattern">Your last name has wrong format.</small>
            <small *ngIf="checkoutForm.controls.lastName.errors?.maxlength">Your last name is too long.</small>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="inputEmail">Email</label>
        <input
          [ngClass]="{
          'is-invalid': checkoutForm.controls.email.errors && checkoutForm.controls.email.touched,
          'is-valid': !checkoutForm.controls.email.errors && checkoutForm.controls.email.touched
        }"
          class="form-control"
          formControlName="email"
          id="inputEmail"
          type="email"
        />
        <div *ngIf="checkoutForm.controls.email.errors && checkoutForm.controls.email.touched"
             class="invalid-feedback d-block">
          <small *ngIf="checkoutForm.controls.email.errors?.required">Please provide your email.</small>
          <small *ngIf="checkoutForm.controls.email.errors?.email">Your email has wrong format.</small>
          <small *ngIf="checkoutForm.controls.email.errors?.maxlength">Your email is too long.</small>
        </div>
      </div>
      <div class="form-group">
        <label for="inputPhoneNumber">Phone number</label>
        <input
          [ngClass]="{
          'is-invalid': checkoutForm.controls.phoneNumber.errors && checkoutForm.controls.phoneNumber.touched,
          'is-valid': !checkoutForm.controls.phoneNumber.errors && checkoutForm.controls.phoneNumber.touched
        }"
          class="form-control"
          formControlName="phoneNumber"
          id="inputPhoneNumber"
          type="text"
        />
        <div *ngIf="checkoutForm.controls.phoneNumber.errors && checkoutForm.controls.phoneNumber.touched"
             class="invalid-feedback d-block">
          <small *ngIf="checkoutForm.controls.phoneNumber.errors?.required">Please provide your phone number.</small>
          <small *ngIf="checkoutForm.controls.phoneNumber.errors?.pattern">Your phone number has wrong format.</small>
          <small *ngIf="checkoutForm.controls.phoneNumber.errors?.maxlength">Your phone number is too long.</small>
        </div>
      </div>
      <div formGroupName="address">
        <div class="row justify-content-between">
          <div class="form-group col-lg-4">
            <label for="inputStreet">Street</label>
            <input
              [ngClass]="{
              'is-invalid': checkoutForm.get('address.street').errors && checkoutForm.get('address.street').touched,
              'is-valid': !checkoutForm.get('address.street').errors && checkoutForm.get('address.street').touched
            }"
              class="form-control"
              formControlName="street"
              id="inputStreet"
              type="text"
            />
            <div *ngIf="checkoutForm.get('address.street').errors && checkoutForm.get('address.street').touched"
                 class="invalid-feedback d-block">
              <small *ngIf="checkoutForm.get('address.street').errors?.required">Please provide your street and house
                number.</small>
              <small *ngIf="checkoutForm.get('address.street').errors?.maxlength">Your street is too long.</small>
            </div>
          </div>
          <div class="form-group col-lg-4">
            <label for="inputZipCode">Postal Code</label>
            <input
              [ngClass]="{
              'is-invalid': checkoutForm.get('address.zipCode').errors && checkoutForm.get('address.zipCode').touched,
              'is-valid': !checkoutForm.get('address.zipCode').errors && checkoutForm.get('address.zipCode').touched
            }"
              class="form-control"
              formControlName="zipCode"
              id="inputZipCode"
              type="text"
            />
            <div *ngIf="checkoutForm.get('address.zipCode').errors && checkoutForm.get('address.zipCode').touched"
                 class="invalid-feedback d-block">
              <small *ngIf="checkoutForm.get('address.zipCode').errors?.required">Please provide your zip code.</small>
              <small *ngIf="checkoutForm.get('address.zipCode').errors?.pattern">Your zip code has wrong format.</small>
              <small *ngIf="checkoutForm.get('address.zipCode').errors?.maxlength">Your zip code is too long.</small>
            </div>
          </div>
          <div class="form-group col-lg-4">
            <label for="inputCity">City</label>
            <input
              [ngClass]="{
              'is-invalid': checkoutForm.get('address.city').errors && checkoutForm.get('address.city').touched,
              'is-valid': !checkoutForm.get('address.city').errors && checkoutForm.get('address.city').touched
            }"
              class="form-control"
              formControlName="city"
              id="inputCity"
              type="text"
            />
            <!-- TODO fixxx hthis
            <div *ngIf="checkoutForm.get('address.city').errors && checkoutForm.get('.address.city').touched"
                  class="invalid-feedback d-block">
               <small *ngIf="checkoutForm.get('address.city').errors?.required">Please provide your city.</small>
               <small *ngIf="checkoutForm.get('address.city').errors?.maxlength">Your city is too long.</small>
             </div>-->
          </div>
        </div>

        <div class="form-group ">
          <label for="inputCountry">Country</label>
          <input
            [ngClass]="{
            'is-invalid': checkoutForm.get('address.country').errors && checkoutForm.get('address.country').touched,
            'is-valid': !checkoutForm.get('address.country').errors && checkoutForm.get('address.country').touched
          }"
            #instance="ngbTypeahead"
            (click)="click$.next($any($event).target.value)"
            (focus)="focus$.next($any($event).target.value)"
            [ngbTypeahead]="search"
            class="form-control"
            formControlName="country"
            id="inputCountry"
          />
          <div *ngIf="checkoutForm.get('address.country').errors && checkoutForm.get('address.country').touched"
               class="invalid-feedback d-block">
            <small *ngIf="checkoutForm.get('address.country').errors?.required">Please provide your country.</small>
          </div>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-between my-3">
        <h3>Total</h3>
        <h3>{{ totalCost.toFixed(2) }}€</h3>
      </div>

      <button
        (click)="payForProducts()"
        [disabled]="!(checkoutForm.valid )"
        class="btn btn-primary btn-lg custom-btn-bd-primary my-3"
        type="button"
      >
        <i class="bi bi-credit-card"></i> Checkout
      </button>

    </form>
  </div>
</div>

