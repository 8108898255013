<div class="site-container">
  <div>
    <h1 class="title">News</h1>
    <div class="news-grid">
      <app-message-item
        *ngFor="let message of getMessage()"
        [message]="message"
        class="list-view"
      ></app-message-item>
    </div>
  </div>
  <ngb-pagination
    (pageChange)="loadMessage($event)"
    *ngIf="totalItems > itemsPerPage"
    [(page)]="currentPage"
    [collectionSize]="totalItems"
    [maxSize]="3"
    [pageSize]="itemsPerPage"
    [rotate]="true"
    class="pager"
    id="pagination"
  ></ngb-pagination>
</div>
