<div
  [routerLink]="['/message/details', message.id]"
  class="news-item hoverable"
>
  <div class="text-container">
    <div [class.long]="!message.image" class="title-date">
      <span class="title">{{ message.title }}</span>
      <span class="date">{{ message.publishedAt | date:'shortDate' }}</span>
    </div>
    <span [class.long]="!message.image" class="desc"
    >{{ message.summary }}</span
    >
  </div>
  <img
    *ngIf="message.image"
    alt=""
    class="news-image"
    height="240"
    [src]="'data:image/png;base64,' + message.image"
    priority="high"
    width="240"
  />
</div>
