import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-confirm-user-password-reset-modal',
  templateUrl: './confirm-user-password-reset-modal.component.html',
  styleUrl: './confirm-user-password-reset-modal.component.scss'
})
export class ConfirmUserPasswordResetModalComponent implements OnInit {
  @Input() accountEmail: string;
  @Output() confirmed = new EventEmitter<void>();

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close();
  }

}
