import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Globals} from '../global/globals';
import {EventCreateDto, EventListDto} from "../dtos/event";
import {EventSearchParamDto} from "../dtos/search";


@Injectable({
  providedIn: 'root'
})

export class EventService{

  private eventBaseUri: string = this.globals.backendUri + '/event';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  /**
   * Persists event to the backend
   *
   * @param event to persist
   */
  createEvent(event:EventCreateDto): Observable<EventCreateDto> {
    console.log('Create event ' + event.name);
    return this.httpClient.post<EventCreateDto>(this.eventBaseUri, event);
  }

  search(searchParam: EventSearchParamDto): Observable<EventListDto[]> {
    let params = new HttpParams();

    if(searchParam.eventName !== undefined) {
      params = params.append("name", searchParam.eventName);
    }

    if(searchParam.eventType !== undefined) {
      params = params.append("type", searchParam.eventType)
    }

    if(searchParam.eventContent !== undefined) {
      params = params.append("content", searchParam.eventContent)
    }


    return this.httpClient.get<EventListDto[]>(this.eventBaseUri, { params });
  }

}
