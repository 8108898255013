
<div>
  <div class="input-container">
    <div class="input-group">
      <div class="input-label">
        <label for="artistSearchTerm">Artist Search</label>
        <input
          [(ngModel)]="searchParam"
          class="form-control"
          id="artistSearchTerm"
          maxlength="150"
          placeholder="Name"
          style="width: 350px"
          type="text"
          [disabled]="disable"
        />
        <div
          *ngIf="
            isMaxLengthExceeded(searchParam, 150)
          "
          class="small"
        >
          Max length exceeded!
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="mb-3">
      <label class="form-label">Artists</label>
      <table class="table table-bordered">
        <thead>
        <tr>
          <th></th>
          <th>Artist Name</th>
          <th>First Name</th>
          <th>Last Name</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let artist of artists">
          <td><button type="button" class="bi bi-arrow-left-circle" name="event" [value]="artist.id" (click)="setArtistSelection(artist)" [disabled]="disable">
          </button> </td>
          <td>{{ artist.artistName }}</td>
          <td>{{ artist.firstName }}</td>
          <td>{{ artist.lastName }}</td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
</div>
