import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgForOf} from "@angular/common";
import {EventListDto} from "../../../../dtos/event";
import {ItemDto} from "../../../../dtos/items";

@Component({
  selector: 'app-selected-item',
  standalone: true,
  imports: [
    NgForOf
  ],
  templateUrl: './selected-item.component.html',
  styleUrl: './selected-item.component.scss'
})
export class SelectedItemComponent {

  @Input() items = new Map<number, ItemDto<any>>();

  @Output() itemRemoved = new EventEmitter<any>

  removeItem(key: any): void {
    this.items.delete(key)
    this.itemRemoved.emit(key)
  }

  getItems(): number[] {
    return Array.from(this.items.keys());
  }

}
