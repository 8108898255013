import {SectorType} from "./sectorType";
import {PerformanceListDto} from "./performance";






export interface SectorListDto {
  id: number;
  name: string;
  capacity: number;
  priceInCents: number;
  sectorType: SectorType;
  performance: PerformanceListDto;
  rows: SectorRowListDto[];
}

export interface SectorRowListDto {

  id: number;
  rowIndex: number;
  rowLength: number;
  sectorId: number;
}

export interface SectorListDtoModified {
  isHovered: boolean;
  id: number;
  name: string;
  capacity: number;
  priceInCents: number;
  sectorType: SectorType;
  performanceId: number;
  rows: SectorRowListDtoModified[];
}

export interface SectorRowListDtoModified {

  id: number;
  rowIndex: number;
  rowLength: number;
  sectorId: number;
  selectedSeatIndices?: number[];//my implementation
  reservedSeatIndices?: any;//my implementation
  colorOfSeat?:  Map<number, any>;//my implementation
}


export interface SectorRowListDtoToSend {
  id: number;
  rowIndex: number;
  rowLength: number;
  sectorId: number;
}

export interface SectorListDtoToSend {
  id: number;
  name: string;
  capacity: number;
  priceInCents: number;
  sectorType: SectorType;
  performanceId: number;
  rows: SectorRowListDtoToSend[];
}
export class SectorCreateDto {
  name: string;
  performanceId: number;
  capacity: number;
  priceInCents: number;
  sectorType: SectorType;
  rows: SectorRowListDtoToSend[];
  constructor(
    name: string,
    capacity: number,
    priceInCents: number,
    performanceId: number,
    sectorType: SectorType,
    rows: SectorRowListDtoToSend[]
  ) {
    this.name = name;
    this.capacity = capacity;
    this.priceInCents = priceInCents;
    this.performanceId = performanceId;
    this.sectorType = sectorType;
    this.rows = rows;
  }
}
export class Sector {
  name: string;
  capacity: number;
  priceInCents: number;
  sectorType: SectorType;
  rows: boolean[][];
  submitted: boolean;
  constructor(
    name: string,
    capacity: number,
    priceInCents: number,
    sectorType: SectorType,
    rows: boolean[][],
    submitted: boolean
  ) {
    this.name = name;
    this.capacity = capacity;
    this.priceInCents = priceInCents;
    this.sectorType = sectorType;
    this.rows = rows;
    this.submitted = submitted;
  }
  toDto(performanceId:number): SectorCreateDto {
    let calculatedCapacity = 0;
    const rowList: SectorRowListDtoToSend[] = [];

    if (this.rows && this.rows.length > 0) {
      this.rows.forEach((row, rowIndex) => {
        rowList.push({
          id: null,
          rowIndex: rowIndex,
          rowLength: row.length,
          sectorId: null
        });

        calculatedCapacity += row.length;
      });
    } else {
      calculatedCapacity = Math.round(this.capacity);
    }

    return new SectorCreateDto(
      this.name,
      calculatedCapacity,
      Math.round(this.priceInCents * 100),
      performanceId,
      this.sectorType,
      rowList
    );
  }
}
