import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ArtistService} from "../../../../services/artist.service";
import {ArtistListDto} from "../../../../dtos/artist";
import {debounceTime, Subject, switchMap} from "rxjs";
import {ArtistSearchParamDto, EventSearchParamDto} from "../../../../dtos/search";
import {EventListDto} from "../../../../dtos/event";
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-artist-search',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './artist-search.component.html',
  styleUrl: './artist-search.component.scss'
})
export class ArtistSearchComponent implements OnInit{

  @Input() disable = false;
  @Output() selectedArtist = new EventEmitter<ArtistListDto>();
  _searchParam  = '';
  artists: ArtistListDto[] = [];
  search$ = new Subject<void>();

  constructor(private artistService: ArtistService) {
  }

  ngOnInit() {
    this.search$.pipe(
      debounceTime(200),
      switchMap(() => this.artistService.search(this._searchParam))
    ).subscribe((artists) => {
      this.artists = artists;
    });
    this.search$.next();
  }

  get searchParam() {
    return this._searchParam;
  }

  set searchParam(searchParam) {
    this._searchParam = searchParam
    this.search$.next();
  }

  isMaxLengthExceeded(input: string, maxLength: number): boolean {
    return input ? input.length >= maxLength : false;
  }

  setArtistSelection(artist: ArtistListDto) {
    this.selectedArtist.emit(artist);
  }

}
