import {Component, OnInit} from '@angular/core';
import {BeeCartServiceService} from "../../services/bee-cart-service.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-bee-cart',
  templateUrl: './bee-cart.component.html',
  styleUrl: './bee-cart.component.scss'
})
export class BeeCartComponent implements OnInit{

  cartItems: any[] = [];

  constructor(
    private authService: AuthService,
    private cartService: BeeCartServiceService,
    private router :Router,
    private route: ActivatedRoute
    ) {}

  ngOnInit(): void {
    this.cartItems = this.cartService.getCart();
  }

  removeFromCart(productId: number): void {
    this.cartService.removeFromCart(productId);
    this.cartItems = this.cartService.getCart(); // Aktualisieren Sie die Anzeige
  }

  clearCart(): void {
    this.cartService.clearCart();
    this.cartItems = []; // Leeren Sie die Anzeige
  }

  increaseQuantity(productId: number): void {
    this.cartService.increaseQuantity(productId);
    this.cartItems = this.cartService.getCart(); // Refresh the cart display
  }

  decreaseQuantity(productId: number): void {
    this.cartService.decreaseQuantity(productId);
    this.cartItems = this.cartService.getCart(); // Refresh the cart display
  }

  checkout() {
    //check availability of items in the cart
    this.cartService.checkout(this.cartItems);

    //if logged in go to checkoutu page

    console.log(this.authService.isLoggedIn())
    if (this.authService.isLoggedIn()) {
      // Wenn eingeloggt, zur Checkout-Seite navigieren
      this.router.navigate(['checkout']);
    } else {
      // Wenn nicht eingeloggt, Benutzer auffordern, sich anzumelden oder ein Konto zu erstellen
      // Hier könnten Sie z.B. ein Modal öffnen oder zur Login-Seite navigieren
      this.router.navigate(['login'], { queryParams: { redirect: 'cart' } });
    }
  }

  cartTotal(): number {
    return this.cartService.cartTotal();
  }
}
