import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Globals} from "../global/globals";
import { LocationListDto } from '../dtos/location';
import {LocationSearchParamDto} from "../dtos/search";

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private locationBaseUri: string = this.globals.backendUri + '/locations';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  search(searchTerm: LocationSearchParamDto): Observable<LocationListDto[]> {
    let params = new HttpParams();

    if(searchTerm.description !== undefined) {
      params = params.append("description", searchTerm.description);
    }

    if(searchTerm.country !== undefined) {
      params = params.append('country', searchTerm.country);
    }

    if(searchTerm.city !== undefined) {
      params = params.append('city', searchTerm.city);
    }

    return this.httpClient.get<LocationListDto[]>(this.locationBaseUri,{params});
  }

  saveLocation(location: Location): Observable<LocationListDto> {
    console.log(location)
    return this.httpClient.post<LocationListDto>(`${this.locationBaseUri}`, location)
  };
}
