import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {Globals} from "../global/globals";
import { ArtistListDto } from '../dtos/artist';
import {ArtistSearchParamDto} from "../dtos/search";

@Injectable({
  providedIn: 'root',
})
export class ArtistService {
  private artistBaseUri: string = this.globals.backendUri + '/artists';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  getArtists(): Observable<ArtistListDto[]> {
    return this.httpClient.get<ArtistListDto[]>(this.artistBaseUri);
  }

  search(searchString: string){
    let params = new HttpParams().set("name", searchString)

    return this.httpClient.get<ArtistListDto[]>(this.artistBaseUri, { params });
  }
}
