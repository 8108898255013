import {Injectable} from '@angular/core';
import {AdminRegisterDto} from "../dtos/user";
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {BeeOrder} from "../dtos/beeOrder";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BeeCartServiceService {

  private beeCartBaseUri: string = this.globals.backendUri + "/bee/cart";

  private beeCheckoutBaseUri: string = this.globals.backendUri + "/checkout/order";


  constructor(private httpClient: HttpClient, private globals: Globals,) {
  }

  addToCart(product: any, quantity: number = 1): void {
    let cart = this.getCart();
    const productIndex = cart.findIndex(item => item.id === product.id);
    if (productIndex !== -1) {
      // Product already exists in the cart, increase its quantity by the specified amount
      cart[productIndex].quantity += quantity;
    } else {
      // Set the specified quantity for new product
      product.quantity = quantity;
      cart.push(product);
    }
    localStorage.setItem('cart', JSON.stringify(cart));
  }

  getCart(): any[] {
    const cart = localStorage.getItem('cart');
    return cart ? JSON.parse(cart) : [];
  }

  removeFromCart(productId: number): void {
    let cart = this.getCart();
    cart = cart.filter(product => product.id !== productId);
    localStorage.setItem('cart', JSON.stringify(cart));
  }

  clearCart(): void {
    localStorage.removeItem('cart');
  }

  increaseQuantity(productId: number): void {
    let cart = this.getCart();
    const productIndex = cart.findIndex(item => item.id === productId);
    if (productIndex !== -1) {
      cart[productIndex].quantity += 1;
      localStorage.setItem('cart', JSON.stringify(cart));
    }
  }

  decreaseQuantity(productId: number): void {
    let cart = this.getCart();
    const productIndex = cart.findIndex(item => item.id === productId);
    if (productIndex !== -1) {
      cart[productIndex].quantity -= 1;
      if (cart[productIndex].quantity < 1) {
        cart.splice(productIndex, 1); // Remove the item if quantity is 0
      }
      localStorage.setItem('cart', JSON.stringify(cart));
    }
  }


  checkout(cartItems: any[]) {
    return this.httpClient.post<AdminRegisterDto>(this.beeCartBaseUri + "/reserveItems", cartItems);
  }

  payForProducts(beeOrder: BeeOrder): Observable<any> {
    return this.httpClient.post(this.beeCheckoutBaseUri, beeOrder);

  }

  cartTotal() {
    let cart = this.getCart();
    console.log(cart);

    if (cart.length > 0) {
      return cart.map(item => item.cost * item.quantity).reduce((prev, next) => prev + next);
    }
    return 0;
  }
}
