import { Component, Input } from '@angular/core';
import { TopTen } from 'src/app/dtos/event';

@Component({
  selector: 'app-top-ten-item',
  templateUrl: './top-ten-item.component.html',
  styleUrl: './top-ten-item.component.scss'
})
export class TopTenItemComponent  {

  @Input() topTen: TopTen;
  @Input() rank: number;
  @Input() ticketAmount: number;
  isHovered: boolean = false;

  ticketPercentage() {
    return (this.topTen.tickets/ this.ticketAmount);
  }
}
