
<app-back-arrow-button></app-back-arrow-button>
<div *ngIf="cartItems.length > 0; else emptyCart">
    <h1><strong>Your Cart</strong></h1>
    <table>
        <thead>

        </thead>
        <tbody>
        <tr *ngFor="let item of cartItems">
            <img *ngIf="item.image_Url" [src]="item.image_Url" alt="Product Image" loading="lazy">

            <td><strong>{{ item.product_Name }}</strong></td>
            <td>{{ item.cost | currency }}</td>
            <td>Quantity: <strong>{{ item.quantity }}</strong></td>
            <td>
                <button class="red" (click)="decreaseQuantity(item.id)">-</button>
                <button (click)="increaseQuantity(item.id)">+</button>
            <td>
                <button class="red" (click)="removeFromCart(item.id)">Remove</button>
            </td>
        </tr>
        </tbody>
    </table>
    <br>
    <button class="red" (click)="clearCart()">Clear Cart</button>
    <button (click)="checkout()">Checkout</button>
    <h2 class="totalCost">Total: {{ cartTotal() | currency }}</h2>
</div>
<ng-template #emptyCart>
    <div style="text-align: center; margin-top: 15vh; border:1px solid #8B572A; ">
        <img src="path/to/empty-cart-image.png" alt="Empty Cart" style="width: 200px; height: auto;">
        <h2>Your cart looks a little empty!</h2>
        <p>Looks like you haven't added anything to your cart yet.</p>
        <a href="#products"
           style="display: inline-block; background-color: #007bff; color: white; padding: 10px 20px; text-decoration: none; border-radius: 5px; margin-top: 20px;">Start
            Shopping</a>
    </div>
</ng-template>
