import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SectorType } from "../../../../dtos/sectorType";
import { NgClass, NgForOf, NgIf, NgStyle } from "@angular/common";
import { TicketListDto } from "../../../../dtos/ticket";
import { OrderType } from "../../../../dtos/orderType";

@Component({
  selector: "app-ticket-item",
  standalone: true,
  imports: [NgForOf, NgIf, NgClass, NgStyle],
  templateUrl: "./ticket-item.component.html",
  styleUrl: "./ticket-item.component.scss",
})
export class TicketItemComponent {
  @Input() orderDetails: any;
  @Input() ticket: any;
  @Input() backgroundColor: string;
  @Input() isInCart: boolean = false;
  @Output() removeTicketFromCart = new EventEmitter<number>();
  protected readonly SectorType = SectorType;
  protected readonly OrderType = OrderType;

  convertDate(date: Date) {
    return new Date(date).toLocaleDateString("en-GB");
  }

  convertTime(date: Date) {
    return new Date(date).toLocaleTimeString("en-GB").slice(0, 5);
  }

  ticketValid(ticket: TicketListDto) {
    return (
      ticket.orderType === OrderType.PURCHASED ||
      ticket.orderType === OrderType.RESERVATION ||
      ticket.orderType === OrderType.IN_CART
    );
  }

  getTypeOfTicket(ticket: TicketListDto) {
    if (ticket.orderType === OrderType.CANCELLATION) {
      return "Cancelled";
    }
    return "Refunded";
  }

  removeTicket(ticketId: number) {
    this.removeTicketFromCart.emit(ticketId);
  }
}
