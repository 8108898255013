import {NgModule} from '@angular/core';
import {mapToCanActivate, RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {AuthGuard} from './guards/auth.guard';
import {MessageComponent} from './components/message/message.component';
import {ManageUserComponent} from "./components/manage/manage-user/manage-user.component";
import {AdminGuard} from "./guards/admin.guard";
import {RegisterComponent} from "./components/register/register.component";
import {MessageDetailsComponent} from './components/message/message-details/message-details.component';

import {OrderOverviewComponent} from "./components/profile/order-overview/order-overview.component";

import {CreateEventComponent} from "./components/manage/create-event/create-event.component";
import {CreateNewsComponent} from "./components/manage/create-news/create-news.component";
import {CreateUserComponent} from "./components/manage/create-user/create-user.component";
import {CreatePerformanceComponent} from "./components/manage/create-performance/create-performance.component";
import {EditProfileComponent} from "./components/profile/edit-profile/edit-profile.component";
import {ResetPasswordComponent} from "./components/reset-password/reset-password.component";
import {CreateEventHallComponent} from "./components/manage/create-event-hall/create-event-hall.component";
import {CreateLocationComponent} from "./components/manage/create-location/create-location.component";
import {BeeHomeLandingPageComponent} from "./components/home/bee-home-landing-page/bee-home-landing-page.component";
import {BeeProductPageComponent} from "./components/bee-product-page/bee-product-page.component";
import {BeeDetailProductComponent} from "./components/bee-product-page/bee-detail-product/bee-detail-product.component";
import {BeeCartComponent} from "./components/bee-cart/bee-cart.component";
import {BeeCheckoutComponent} from "./components/bee-checkout/bee-checkout.component";
import {
  OrderDetailViewComponent
} from "./components/profile/order-overview/order-detailview/order-detailview.component";


const routes: Routes = [
    {
      path: "",
      component: BeeHomeLandingPageComponent,
    },
    {
      path: "products",
      component: BeeProductPageComponent,
    },
    {
      path: "products/detail/:id", component: BeeDetailProductComponent,
    },
    {
      path: "cart", component: BeeCartComponent,
    },
    {
      path: "checkout", component: BeeCheckoutComponent,
    },


//stll from sepm project;
    /* {
       path: "",
       component:
       HomeComponent,
       canActivate:
         mapToCanActivate([AuthGuard]),
     }
     ,*/
    /* {
       path: 'eventhall/:id', component:
       TicketSelectionComponent, canActivate:
         mapToCanActivate([AuthGuard])
     }
     ,*/
    {
      path: "login", component:
      LoginComponent
    }
    ,
    /*{
      path: 'search', component:
      SearchComponent, canActivate:
        mapToCanActivate([AuthGuard])
    }
    ,*/
    {
      path: "register", component:
      RegisterComponent
    }
    ,
    {
      path: "reset-password", component:
      ResetPasswordComponent
    }
    ,
    {
      path: "message",
      canActivate:
        mapToCanActivate([AuthGuard]),
      children:
        [
          {path: "", component: MessageComponent},
          {path: "details/:id", component: MessageDetailsComponent},
        ],
    }
    ,
    /*   {
         path: "cart",
         component:
         CartComponent,
         canActivate:
           mapToCanActivate([AuthGuard]),
       }
       ,*/
    {
      path: "profile",
      canActivate:
        mapToCanActivate([AuthGuard]),
      children:
        [
          {
            path: "order",
            children: [
              {path: "overview", component: OrderOverviewComponent},
              { path: "details/:id", component: OrderDetailViewComponent },
            ],
          },
          {
            path: "settings",
            component: EditProfileComponent,
          }
        ],
    }
    ,
    {
      path: 'manage', canActivate:
        mapToCanActivate([AdminGuard]), children:
        [
          {path: '', component: ManageUserComponent},
          {path: 'event', component: CreateEventComponent},
          {path: 'news', component: CreateNewsComponent},
          {path: 'location', component: CreateLocationComponent},
          {path: 'eventhall', component: CreateEventHallComponent},
          {path: 'user', component: CreateUserComponent},
          {path: 'performance', component: CreatePerformanceComponent}
        ]
    }
    ,
    /* {
       path: 'top-ten/:id', component:
       PerformanceListComponent, canActivate:
         mapToCanActivate([AuthGuard])
     }
     ,*/
    {
      path: "**", redirectTo:
        ""
    }
    ,
  ]
;

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
