<div class="site-container">
    <div *ngIf="!authService.isLoggedIn()">{{ logIn() }}</div>
    <div *ngIf="authService.isLoggedIn()">
      <div class="two-columns">
        <div class="news">
          <div class="news-text-container">
            <h1 class="title ms-lg-3">The Most Important News For You!</h1>
          </div>
          <div *ngIf="messages.length !== 0" class="card-deck">
            <app-message-item
              *ngFor="let message of messages"
              [message]="message"
              class="list-view"
            >
            </app-message-item>
          </div>
          <div *ngIf="messages.length === 0" class="message-container">
            <h2>You're all caught up! Check back later for more news.</h2>
          </div>
        </div>
        <div class="events">
          <div class="event-text-container">
              <h1 class="title">Top Ten Events!</h1>
            <select
            (ngModelChange)="searchChanged()"
            [(ngModel)]="category"
            class="form-select mt-2"
          >
            <option value="" selected>All</option>
            <option value="ARTS">Arts</option>
            <option value="ENTERTAINMENT">Entertainment</option>
            <option value="FASHION">Fashion</option>
            <option value="HEALTH">Health</option>
            <option value="BUSINESS">Business</option>
            <option value="SCIENCE">Science</option>
            <option value="TRAVEL">Travel</option>
            <option value="TECHNOLOGY">Technology</option>
            <option value="ENVIRONMENT">Environment</option>
            <option value="COMMUNITY">Community</option>
            <option value="CHARITY">Charity</option>
            </select>
          </div>
          <div class="top-ten-item-container">
        <app-top-ten-item
          *ngFor="let event of topten, let i = index"
          [topTen]="event"
          [rank]="i + 1"
          [ticketAmount]="ticketAmount"
          class="top-ten-item">
        </app-top-ten-item>
          </div>
      </div>
    </div>
  </div>
</div>
