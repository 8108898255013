import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EventSearchParamDto, SearchParamDto} from "../../../../dtos/search";
import {EventListDto} from "../../../../dtos/event";
import {EventService} from "../../../../services/event.service";
import {debounceTime, Subject, switchMap} from "rxjs";
import {EventType} from "../../../../dtos/eventType";

@Component({
  selector: 'app-event-search',
  standalone: true,
  imports: [
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    NgForOf
  ],
  templateUrl: './event-search.component.html',
  styleUrl: './event-search.component.scss'
})
export class EventSearchComponent implements OnInit{

  @Input() disable = false;
  searchParam: EventSearchParamDto  = {};
  events: EventListDto[] = [];
  @Output() selectedEvent= new EventEmitter<EventListDto>();
  search$ = new Subject<void>();

  constructor(private eventService: EventService) {
  }
  ngOnInit(): void {
    this.search$.pipe(
      debounceTime(200),
      switchMap(() => this.eventService.search(this.searchParam))
    ).subscribe((events) => {
      this.events = events;
    });
    this.search$.next();
  }

  isMaxLengthExceeded(input: string, maxLength: number): boolean {
    return input ? input.length >= maxLength : false;
  }

  setEventSelection(event: EventListDto) {
    this.selectedEvent.emit(event);
  }

  set eventName(eventName: string) {
    this._set({eventName})
  }

  get eventName(): string {
    return this.searchParam.eventName;
  }

  set eventContent(eventContent: string) {
    this._set({eventContent})
  }

  get eventContent() {
    return this.searchParam.eventContent;
  }

  set eventType (eventType: EventType) {
    this._set({eventType})
  }

  get eventType() {
    return this.searchParam.eventType;
  }

  private _set(patch: Partial<EventSearchParamDto>) {
    Object.assign(this.searchParam, patch);
    this.search$.next();
  }

}
