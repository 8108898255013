<div class="p-2">
  <div class="d-flex flex-row justify-content-end">
    <button type="button" class="btn-close" aria-label="Close" [disabled]="cancelButtonDisabled" (click)="dismissModal()"></button>
  </div>
  <div class="d-flex flex-row modal-header justify-content-center p-2">
    <h5 class="modal-title">Payment</h5>
  </div>
  <div class="modal-body">
    <div id="card-element"></div>
    <div class="d-flex flex-row justify-content-between my-3">
      <h5>Total</h5>
      <h5>{{ cartTotal() | currency:'EUR':'symbol':'1.2-2' }}</h5>
    </div>
  </div>
  <div class="modal-footer d-flex flex-column align-items-center p-2">
    <button type="button" class="btn btn-primary w-75 my-3" [disabled]="!isCardValid" (click)="confirmPayment()">
      <i class="bi bi-credit-card"></i> Pay
    </button>
  </div>
</div>

