import {Component, TemplateRef, ViewChild} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {AuthRequest} from "../../dtos/auth-request";
import {ErrorFormatterService} from "../../services/error-formatter.service";
import {ToastrService} from "ngx-toastr";
import {HttpStatusCode} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent {
  loginForm: UntypedFormGroup;
  // After first submission attempt, form validation will start
  submitted = false;
  @ViewChild("lockedModal") lockedModal: TemplateRef<any>;
  showPassword: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private errorFormatter: ErrorFormatterService,
    private notification: ToastrService,
    private modalService: NgbModal,
  ) {
    const passwordPattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{4,}$";
    this.loginForm = this.formBuilder.group({
      username: ["", [Validators.required, Validators.email, Validators.maxLength(128)]],
      password: ["", [Validators.required, Validators.pattern(passwordPattern), Validators.minLength(8), Validators.maxLength(128)]],
    });
  }

  /**
   * Form validation will start after the method is called, additionally an AuthRequest will be sent
   */
  loginUser() {
    this.submitted = true;
    if (this.loginForm.valid) {
      const authRequest: AuthRequest = new AuthRequest(
        this.loginForm.controls.username.value,
        this.loginForm.controls.password.value,
      );
      this.authenticateUser(authRequest);
    }
  }

  /**
   * Send authentication data to the authService. If the authentication was successfully, the user will be forwarded to the message page
   *
   * @param authRequest authentication data from the user login form
   */
  authenticateUser(authRequest: AuthRequest) {
    this.authService.loginUser(authRequest).subscribe({
      next: () => {
        this.notification.success(
          `User ${authRequest.email} successfully logged in.`,
          "Login successful!",
        );
        this.activatedRoute.queryParams.subscribe(params => {
          const redirectRoute = params['redirect'] || '/';
          this.router.navigate([redirectRoute]);
        });
      },
      error: (error) => {
        this.resetForm();
        if (error.status === HttpStatusCode.NotFound) {
          this.notification.error(
            this.errorFormatter.format(error),
            `Could not log in the user ${authRequest.email}.`,
            {
              timeOut: 3000,
            },
          );
        } else if (error.status === HttpStatusCode.Locked) {
          this.modalService.open(this.lockedModal, {centered: true});
        } else if (error.status === HttpStatusCode.Unauthorized) {
          this.loginForm.reset();
          this.notification.error(
            this.errorFormatter.format(error),
            `Could not log in the user ${authRequest.email}.`,
            {
              enableHtml: true,
            },
          );
        } else {
          this.notification.error(
            "Please try again.",
            `Could not log in the user ${authRequest.email}.`,
            {
              timeOut: 3000,
            },
          );
        }
      },
    });
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  resetForm() {
    this.submitted = false;
    this.loginForm.reset();
  }

}
