import {Component} from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back-arrow-button',
  templateUrl: './back-arrow-button.component.html',
  styleUrls: ['./back-arrow-button.component.scss']
})
export class BackArrowButtonComponent {
    constructor(private location: Location) {
    }

    goBack() {
        this.location.back();
    }

}
