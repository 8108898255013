
<app-back-arrow-button></app-back-arrow-button>
<div *ngFor="let order of orders" class="order" (click)="getOrderWithId(order.id)">
    <div class="order-header">
        <h2>Order #{{ order.id }} - {{ convertDate(order.orderDate) }}</h2>
    </div>
    <div class="order-details">
        <div class="order-info-container">
            <div class="order-info">
                <h3>Order Date</h3>
                <p>{{ convertDate(order.orderDate) }}</p>
            </div>
            <div class="order-info">
                <h3>Products</h3>
                <p>{{ order.beeProducts.length }} Products</p>
            </div>
            <div class="order-info">
                <h3>Total Cost</h3>
                <p>{{ order.totalCost }}€</p>
            </div>
            <div class="order-info">
                <h3>Billing Address</h3>
                <p>{{ order.billingInformation.address.zipCode }}, {{ order.billingInformation.address.country }}</p>
            </div>
        </div>
        <div class="order-image">
            <img [src]="order.beeProducts[0].image_Url" alt="Product Image">
        </div>
    </div>
    <hr>
</div>
