import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {PerformanceCreateDto} from '../dtos/performance';
import {Globals} from "../global/globals";

@Injectable({
  providedIn: 'root',
})
export class PerformanceService {
  private performanceBaseUri: string = this.globals.backendUri + '/performance';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

  createPerformance(performance: PerformanceCreateDto): Observable<PerformanceCreateDto> {
    return this.httpClient.post<PerformanceCreateDto>(`${this.performanceBaseUri}`, performance);
  }
}
