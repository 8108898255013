import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {OrderType} from "../../../dtos/orderType";
import {ToastrService} from "ngx-toastr";
import {InvoiceService} from "../../../services/invoice.service";
import {TicketPdfService} from "../../../services/ticket-pdf.service";
import {BeeServiceService} from "../../../services/bee-service.service";
import {BeeOrder} from "../../../dtos/beeOrder";
import {Router} from "@angular/router";

@Component({
  selector: "app-order-overview",
  templateUrl: "./order-overview.component.html",
  styleUrl: "./order-overview.component.scss",
})
export class OrderOverviewComponent implements OnInit {
  orders: BeeOrder[] = [];
  filteredOrders: BeeOrder[] = [];
  @ViewChild("container") container: ElementRef;

  protected readonly OrderType = OrderType;


  constructor(
    private beeOrderService: BeeServiceService,
    private invoiceService: InvoiceService,
    private ticketPdfService: TicketPdfService,
    private router: Router,
    private notification: ToastrService) {
  }

  convertDate(date
                :
                Date
  ) {
    return new Date(date).toLocaleDateString();
  }


  ngOnInit() {
    const test: string = "test";
    this.beeOrderService.getOrders(test).subscribe({
      next: (orders) => {
        this.orders = orders;
        this.filteredOrders = orders;
      },
      error: () => {
        this.notification.error("Error while fetching orders", "Please try again");
      },
    });

  }

  getOrderWithId(id: number) {
    console.log("Order with id: ", id);
    this.router.navigate(["/profile/order/details/", id]);
  }


}
