<footer>
  <div class="footer-content">
    <div class="footer-section links">
      <h2>Quick Links</h2>
      <ul>
        <li><a href="#">Home</a></li>
        <li><a href="#products">Products</a></li>
        <li><a href="#about-us">About Us</a></li>
        <!--<li><a href="#news">News</a></li>-->
        <li><a href="#contact">Contact Us</a></li>
      </ul>
    </div>
    <div class="footer-section contact">
      <h2>Contact Us</h2>
      <p>Email: support&#64;yourhoneyshop.com</p>
      <p>Phone: +43 67639000134</p>
      <p>Address: Bruder, Gießhübl, Austria</p>
    </div>
    <div class="footer-section newsletter">
      <h2>Newsletter</h2>
      <p>Subscribe to our newsletter to get the latest news and updates:</p>
      <form>
        <input type="email" placeholder="Enter your email">
        <button type="submit">Subscribe</button>
      </form>
    </div>
  </div>
  <div class="footer-bottom">
    <p>&copy; 2024 NektarOase All rights reserved.</p>
    <ul class="footer-legal">
      <li><a href="/privacy-policy">Privacy Policy</a></li>
      <li><a href="/terms-of-service">Terms of Service</a></li>
      <li><a href="/refund-policy">Refund Policy</a></li>
    </ul>
    <div class="footer-social">
      <a href="#"><img src="facebook-icon.png" alt="Facebook"></a>
      <a href="#"><img src="twitter-icon.png" alt="Twitter"></a>
      <a href="#"><img src="instagram-icon.png" alt="Instagram"></a>
    </div>
  </div>
</footer>
