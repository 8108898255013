/*
import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ErrorFormatterService } from "../../../services/error-formatter.service";
import { BeeServiceService } from "../../../services/bee-service.service";

@Component({
  selector: 'app-bee-home-landing-page',
  templateUrl: './bee-home-landing-page.component.html',
  styleUrls: ['./bee-home-landing-page.component.scss']
})
export class BeeHomeLandingPageComponent implements OnInit {

  imageUrl1: string | undefined;
  imageUrl2: string | undefined;

  private imageVisible: boolean = false; // Tracks whether the first image is visible

  constructor(
      private beeService: BeeServiceService,
      private notification: ToastrService,
      private router: Router,
      private errorFormatter: ErrorFormatterService,
      private renderer: Renderer2,
      private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.beeService.getImages().subscribe((data: any) => {
          this.loadImages(data);
        }, error => {
          this.notification.error((error));
        }
    );
    this.playBackgroundMusic();
  }

  loadImages(data: any): void {
    this.imageUrl1 = data.imageUrl1;
    this.imageUrl2 = data.imageUrl2;

    setTimeout(() => this.fadeOutFirstImage(), 3000); // 3-second delay for fading out the first image
  }




  fadeOutFirstImage(): void {
    const firstImageElement = this.el.nativeElement.querySelector('.first-image');
    const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');

    if (firstImageElement && textElement) {
      // Fade out the first image
      this.renderer.addClass(firstImageElement, 'fade-out');

      // Wait for the fade-out to complete, then hide the image and show the text
      setTimeout(() => {
        this.renderer.addClass(firstImageElement, 'hidden'); // Hide the first image
        this.renderer.removeClass(textElement, 'hidden'); // Ensure the text is visible
        this.renderer.addClass(textElement, 'show'); // Fade in the text
      }, 3000); // Adjust timing to match the fade-out duration
    }
  }


  fadeInImage(): void {
    const firstImageElement = this.el.nativeElement.querySelector('.first-image');
    const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');
    if (firstImageElement && !this.imageVisible) {
      this.imageVisible = true;
      this.renderer.removeClass(firstImageElement, 'fade-out');
      this.renderer.addClass(textElement, 'hidden'); // Hide the second image
      this.renderer.addClass(firstImageElement, 'fade-in');
      setTimeout(() => {
        this.renderer.addClass(firstImageElement, 'show');
        this.startFadeOutTimer();
      }, 2); // Trigger fade-in
    }
  }

  startFadeOutTimer(): void {
    setTimeout(() => this.fadeOutFirstImage(), 3000); // Automatic fade-out after 3 seconds
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const firstImageElement = this.el.nativeElement.querySelector('.first-image');
    if (window.scrollY === 0 && firstImageElement) {
      this.fadeInImage();
    }
  }



  playBackgroundMusic(): void {
    const audio = new Audio('assets/music/forest-ambience-with-cuckoo-birds-chirping-123046.mp3');
    audio.loop = true;
    audio.volume = 0.05;
    audio.load();
    audio.play().catch(error => console.error("Audio playback failed", error));
  }
}


*/
import {Component, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {ErrorFormatterService} from "../../../services/error-formatter.service";
import {BeeServiceService} from "../../../services/bee-service.service";

@Component({
    selector: 'app-bee-home-landing-page',
    templateUrl: './bee-home-landing-page.component.html',
    styleUrls: ['./bee-home-landing-page.component.scss']
})
export class BeeHomeLandingPageComponent implements OnInit {
    imageUrl1: string | undefined;
    imageUrl2: string | undefined;

    private imageVisible: boolean = false; // Tracks whether the first image is visible
    private fadeTimer: any; // Timer for fade-out

    constructor(
        private beeService: BeeServiceService,
        private notification: ToastrService,
        private router: Router,
        private errorFormatter: ErrorFormatterService,
        private renderer: Renderer2,
        private el: ElementRef
    ) {
    }

    ngOnInit(): void {
        this.beeService.getImages().subscribe((data: any) => {
            this.loadImages(data);
        }, error => {
            this.notification.error((error));
        });
        this.playBackgroundMusic();

        // Show the first image at the start
        this.showFirstImage();
    }

    loadImages(data: any): void {
        this.imageUrl1 = data.imageUrl1;
        this.imageUrl2 = data.imageUrl2;

        setTimeout(() => this.fadeOutFirstImage(), 3000); // 3-second delay for fading out the first image
    }

    // Shows the first image and starts the fade-out timer and hides second image
    showFirstImage(): void {
        const firstImageElement = this.el.nativeElement.querySelector('.first-image');
        const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');

        if (firstImageElement && textElement) {
            // Ensure first image is visible and text is hidden at the start
            this.renderer.removeClass(firstImageElement, 'hidden');
            this.renderer.removeClass(firstImageElement, 'fade-out');
            this.renderer.addClass(firstImageElement, 'show');
            this.renderer.addClass(textElement, 'hidden'); // Ensure text is hidden initially

            // Start the timer to fade out the first image
            this.startFadeOutTimer();
            this.fadeInSecondImage();
        }
    }


    fadeInFirstImage(): void {
        console.log("start fadeInFirstImage");
        const firstImageElement = this.el.nativeElement.querySelector('.first-image');
        const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');

        if (firstImageElement && textElement && !this.imageVisible) {
            console.log("fadeIn 1 if bedingung : show und fade-out");
            this.renderer.removeClass(firstImageElement, 'hidden'); // Show first image
            this.imageVisible = true;
            this.renderer.removeClass(firstImageElement, 'fade-out');
            setTimeout(() => {
                this.renderer.addClass(firstImageElement, 'fade-in');
                this.renderer.addClass(firstImageElement, 'show'); // Ensure the first image is fully shown
                // Start the timer only after the image is fully shown
                setTimeout(() => this.startFadeOutTimer(), 3000);
            }, 3000); // Short delay to trigger fade-in
        }
    }

    fadeInSecondImage(): void {
        console.log("start fadeInSecondImage");
        const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');
        if (textElement) {

            setTimeout(() => {
                this.renderer.removeClass(textElement, 'hidden');
                this.renderer.removeClass(textElement, 'fade-out');
                this.renderer.addClass(textElement, 'fade-in');
                this.renderer.addClass(textElement, 'show');

            }, 3000);


        }
    }


    fadeOutSecondImage(): void {
        console.log("start fadeOutSecondImage");
        const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');
        if (textElement) {
            this.renderer.removeClass(textElement, 'show');
            this.renderer.addClass(textElement, 'fade-out');
            this.renderer.addClass(textElement, 'hidden');

        }
    }

//fades out first image and shows second image
    fadeOutFirstImage(): void {

        console.log("start fadeOutFirstImage");
        const firstImageElement = this.el.nativeElement.querySelector('.first-image');

        const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');
        if (firstImageElement) {
            console.log("fadeOut 1 : show und fade-out");
            this.renderer.removeClass(firstImageElement, 'fade-in');
            this.renderer.removeClass(firstImageElement, 'show');
            this.renderer.addClass(firstImageElement, 'fade-out');
            this.imageVisible = false;
            console.log("fadeOut 1 : show und fade-out is       done");
            setTimeout(() => {
                this.renderer.addClass(firstImageElement, 'hidden'); // Hide the first image after fade out

                console.log("fadeOut 1 : is hidden and now calling fadeInsecondImage");
                this.fadeInSecondImage();
            }, 10);
        }
    }

    // Starts the fade-out process after a delay
    startFadeOutTimer(): void {
        console.log("startFadeOutTimer for first image");
        this.fadeTimer = setTimeout(() => this.fadeOutFirstImage(), 3000); // Automatic fade-out after 3 seconds
    }

    // Clears the fade-out timer when needed
    clearFadeTimer(): void {
        if (this.fadeTimer) {
            clearTimeout(this.fadeTimer);
            this.fadeTimer = null;
        }
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
        const firstImageElement = this.el.nativeElement.querySelector('.first-image');
        const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');

        if (window.scrollY === 0 && firstImageElement && textElement) {
            this.clearFadeTimer(); // Clear any existing fade timer
            this.fadeOutSecondImage(); // Fade out the second image (text)startFadeOutTimer
            setTimeout(() => {  }, 10000);
            this.fadeInFirstImage();

        }
    }

    playBackgroundMusic(): void {
        const audio = new Audio('assets/music/forest-ambience-with-cuckoo-birds-chirping-123046.mp3');
        audio.loop = true;
        audio.volume = 0.05;
        audio.load();
        audio.play().catch(error => console.error("Audio playback failed", error));
    }
}


/*
funktioniert fast ausßer wenn man raufscrollt!!
import {Component, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {ErrorFormatterService} from "../../../services/error-formatter.service";
import {BeeServiceService} from "../../../services/bee-service.service";

@Component({
    selector: 'app-bee-home-landing-page',
    templateUrl: './bee-home-landing-page.component.html',
    styleUrls: ['./bee-home-landing-page.component.scss']
})
export class BeeHomeLandingPageComponent implements OnInit {

    imageUrl1: string | undefined;
    imageUrl2: string | undefined;

    private imageVisible: boolean = false; // Tracks whether the first image is visible
    private fadeTimer: any; // Timer for fade-out

    constructor(
        private beeService: BeeServiceService,
        private notification: ToastrService,
        private router: Router,
        private errorFormatter: ErrorFormatterService,
        private renderer: Renderer2,
        private el: ElementRef
    ) {
    }

    ngOnInit(): void {
        this.beeService.getImages().subscribe((data: any) => {
                this.loadImages(data);
            }, error => {
                this.notification.error((error));
            }
        );
        this.playBackgroundMusic();

        // Show the first image at the start
        this.showFirstImage();
    }

    loadImages(data: any): void {
        this.imageUrl1 = data.imageUrl1;
        this.imageUrl2 = data.imageUrl2;

        setTimeout(() => this.fadeOutFirstImage(), 3000); // 3-second delay for fading out the first image
    }

    // Shows the first image and starts the fade-out timer
    showFirstImage(): void {
        const firstImageElement = this.el.nativeElement.querySelector('.first-image');
        const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');

        if (firstImageElement && textElement) {
            // Ensure first image is visible and text is hidden at the start
            this.renderer.removeClass(firstImageElement, 'hidden');
            this.renderer.removeClass(firstImageElement, 'fade-out');
            this.renderer.addClass(firstImageElement, 'show');
            this.renderer.addClass(textElement, 'hidden'); // Make sure text is hidden initially

            // Start the timer to fade out the first image
            this.startFadeOutTimer();
        }
    }

    fadeOutFirstImage(): void {
        const firstImageElement = this.el.nativeElement.querySelector('.first-image');
        const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');

        if (firstImageElement && textElement) {
            // Fade out the first image
            this.renderer.addClass(firstImageElement, 'fade-out');

            // Wait for the fade-out to complete, then hide the image and show the text
            setTimeout(() => {
                this.renderer.addClass(firstImageElement, 'hidden'); // Hide the first image
                this.renderer.removeClass(textElement, 'hidden'); // Ensure the text is visible
                this.renderer.addClass(textElement, 'show'); // Fade in the text
            }, 3000); // Adjust timing to match the fade-out duration
        }
    }

    fadeInFirstImage(): void {
        const firstImageElement = this.el.nativeElement.querySelector('.first-image');
        const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');

        if (firstImageElement && textElement && !this.imageVisible) {
            this.imageVisible = true;
            this.renderer.removeClass(firstImageElement, 'fade-out');
            this.renderer.addClass(textElement, 'hidden');
            this.renderer.addClass(textElement, 'fade-out'); // Start fading out the second image (text)
            this.renderer.addClass(textElement, 'hidden');
            setTimeout(() => {
                this.renderer.addClass(textElement, 'hidden'); // Hide the second image
                this.renderer.addClass(firstImageElement, 'fade-in');

                setTimeout(() => {
                    this.renderer.addClass(firstImageElement, 'show');
                    this.startFadeOutTimer();
                }, 10); // Trigger fade-in after a short delay
            }, 300); // Adjust to sync with fade-out effect
        }
    }

    // Starts the fade-out process after a delay
    startFadeOutTimer(): void {
        this.fadeTimer = setTimeout(() => this.fadeOutFirstImage(), 3000); // Automatic fade-out after 3 seconds
    }

    // Clears the fade-out timer when needed
    clearFadeTimer(): void {
        if (this.fadeTimer) {
            clearTimeout(this.fadeTimer);
            this.fadeTimer = null;
        }
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event: Event): void {
        const firstImageElement = this.el.nativeElement.querySelector('.first-image');
        const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');

        if (window.scrollY === 0 && firstImageElement && textElement) {
            this.clearFadeTimer(); // Clear any existing fade timer
            this.fadeOutSecondImage(); // Fade out the second image (text)
            this.fadeInFirstImage(); // Show the first image again when scrolled to top
        }
    }


    fadeOutSecondImage(): void {

        const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');
        if (textElement) {
            this.renderer.addClass(textElement, 'fade-out');
            this.renderer.addClass(textElement, 'hidden');
        }


    }

    playBackgroundMusic(): void {
        const audio = new Audio('assets/music/forest-ambience-with-cuckoo-birds-chirping-123046.mp3');
        audio.loop = true;
        audio.volume = 0.05;
        audio.load();
        audio.play().catch(error => console.error("Audio playback failed", error));
    }
}
*/


/*import {Component, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {ErrorFormatterService} from "../../../services/error-formatter.service";
import {BeeServiceService} from "../../../services/bee-service.service";

@Component({
  selector: 'app-bee-home-landing-page',
  templateUrl: './bee-home-landing-page.component.html',
  styleUrl: './bee-home-landing-page.component.scss'
})
export class BeeHomeLandingPageComponent implements OnInit {

  imageUrl: string | undefined;



  private imageVisible: boolean = false; // Verfolgt, ob das Bild sichtbar war

  constructor(
    private beeService: BeeServiceService,
    private notification: ToastrService,
    private router: Router,
    private errorFormatter: ErrorFormatterService,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
  }


  ngOnInit(): void {
    this.beeService.getImages().subscribe((data: any) => {
        this.loadImage(data);
      }, error => {
        this.notification.error((error));
      }
    );
    this.playBackgroundMusic();
  }

  loadImage(data: any): void {
    let string = data.imageUrl;
    fetch(string)
      .then(response => response.blob())
      .then(blob => {
        this.imageUrl = URL.createObjectURL(blob);
        setTimeout(() => this.fadeOutImage(), 3000); // 3 Sekunden Verzögerung
      })
      .catch(error => {
        console.error('Error fetching the image:', error);
      });
  }

  fadeInImage(): void {
    const imageElement = this.el.nativeElement.querySelector('.imageOfBees img');
    if (imageElement && !this.imageVisible) {
      this.imageVisible = true;
      this.renderer.removeClass(imageElement, 'fade-out');
      this.renderer.addClass(imageElement, 'fade-in');
      setTimeout(() => {
        this.renderer.addClass(imageElement, 'show');
        this.startFadeOutTimer();
      }, 10); // Trigger fade-in
    }
  }

  fadeOutImage(): void {
    const imageElement = this.el.nativeElement.querySelector('.imageOfBees img');
    if (imageElement && this.imageVisible) {
      this.renderer.removeClass(imageElement, 'show');
      this.renderer.addClass(imageElement, 'hide');
      this.imageVisible = false;
    }
  }

  startFadeOutTimer(): void {
    setTimeout(() => this.fadeOutImage(), 3000); // Automatisches Ausblenden nach 3 Sekunden
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const imageElement = this.el.nativeElement.querySelector('.imageOfBees img');
    if (window.scrollY === 0 && imageElement) {
      this.fadeInImage();
    }
  }
  //birds-chirping-calm-173695.mp3
  //forest-ambience-with-cuckoo-birds-chirping-123046.mp3
  //calm-nature-sounds-196258.mp3
  playBackgroundMusic(): void {
    const audio = new Audio('assets/music/forest-ambience-with-cuckoo-birds-chirping-123046.mp3');
    audio.loop = true;
    audio.volume = 0.05;
    audio.load();
    audio.play().catch(error => console.error("Audio playback failed", error));
  }
}*/


/*

import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ErrorFormatterService } from "../../../services/error-formatter.service";
import { BeeServiceService } from "../../../services/bee-service.service";

@Component({
  selector: 'app-bee-home-landing-page',
  templateUrl: './bee-home-landing-page.component.html',
  styleUrls: ['./bee-home-landing-page.component.scss']
})
export class BeeHomeLandingPageComponent implements OnInit {

  imageUrl1: string | undefined;
  imageUrl2: string | undefined;

  private imageVisible: boolean = false; // Verfolgt, ob das erste Bild sichtbar war

  constructor(
      private beeService: BeeServiceService,
      private notification: ToastrService,
      private router: Router,
      private errorFormatter: ErrorFormatterService,
      private renderer: Renderer2,
      private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.beeService.getImages().subscribe((data: any) => {
          this.loadImages(data);
        }, error => {
          this.notification.error((error));
        }
    );
    this.playBackgroundMusic();
  }

  loadImages(data: any): void {
    this.imageUrl1 = data.imageUrl1;
    this.imageUrl2 = data.imageUrl2;

    setTimeout(() => this.fadeOutFirstImage(), 3000); // 3 Sekunden Verzögerung für das Ausblenden des ersten Bildes
  }



// src/app/components/home/bee-home-landing-page/bee-home-landing-page.component.ts
  fadeOutFirstImage(): void {
    const firstImageElement = this.el.nativeElement.querySelector('.first-image');
    const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');
    if (firstImageElement) {
      this.renderer.addClass(firstImageElement, 'fade-out');
      setTimeout(() => {
        this.renderer.addClass(firstImageElement, 'hidden'); // Hide the first image
        this.showSecondImage();
        if (textElement) {
          this.renderer.removeClass(textElement, 'hidden');
          this.renderer.addClass(textElement, 'show');
        }
      }, 5000); // Wait until the first image is fully faded out (according to the transition duration)
    }
  }
  showSecondImage(): void {
    const secondImageElement = this.el.nativeElement.querySelector('.second-image');
    if (secondImageElement) {
      this.renderer.removeClass(secondImageElement, 'hidden');
      this.renderer.addClass(secondImageElement, 'show');
    }
  }

  fadeInImage(): void {
    const firstImageElement = this.el.nativeElement.querySelector('.first-image');
    if (firstImageElement && !this.imageVisible) {
      this.imageVisible = true;
      this.renderer.removeClass(firstImageElement, 'fade-out');
      this.renderer.addClass(firstImageElement, 'fade-in');
      setTimeout(() => {
        this.renderer.addClass(firstImageElement, 'show');
        this.startFadeOutTimer();
      }, 10); // Trigger fade-in
    }
  }

  fadeOutImage(): void {
    const firstImageElement = this.el.nativeElement.querySelector('.first-image');
    if (firstImageElement && this.imageVisible) {
      this.renderer.removeClass(firstImageElement, 'show');
      this.renderer.addClass(firstImageElement, 'hide');
      this.imageVisible = false;
    }
  }

  startFadeOutTimer(): void {
    setTimeout(() => this.fadeOutFirstImage(), 3000); // Automatisches Ausblenden nach 3 Sekunden
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const firstImageElement = this.el.nativeElement.querySelector('.first-image');
    if (window.scrollY === 0 && firstImageElement) {
      this.fadeInImage();
    }
  }

  playBackgroundMusic(): void {
    const audio = new Audio('assets/music/forest-ambience-with-cuckoo-birds-chirping-123046.mp3');
    audio.loop = true;
    audio.volume = 0.05;
    audio.load();
    audio.play().catch(error => console.error("Audio playback failed", error));
  }
}


*/

/*


import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ErrorFormatterService } from "../../../services/error-formatter.service";
import { BeeServiceService } from "../../../services/bee-service.service";

@Component({
  selector: 'app-bee-home-landing-page',
  templateUrl: './bee-home-landing-page.component.html',
  styleUrls: ['./bee-home-landing-page.component.scss']
})
export class BeeHomeLandingPageComponent implements OnInit {

  imageUrl1: string | undefined;
  imageUrl2: string | undefined;

  private imageVisible: boolean = false; // Verfolgt, ob das erste Bild sichtbar war

  constructor(
      private beeService: BeeServiceService,
      private notification: ToastrService,
      private router: Router,
      private errorFormatter: ErrorFormatterService,
      private renderer: Renderer2,
      private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.beeService.getImages().subscribe((data: any) => {
          this.loadImages(data);
        }, error => {
          this.notification.error((error));
        }
    );
    this.playBackgroundMusic();
  }

  loadImages(data: any): void {
    this.imageUrl1 = data.imageUrl1;
    this.imageUrl2 = data.imageUrl2;

    setTimeout(() => this.fadeOutFirstImage(), 3000); // 3 Sekunden Verzögerung für das Ausblenden des ersten Bildes
  }
  fadeOutFirstImage(): void {
    const firstImageElement = this.el.nativeElement.querySelector('.first-image');
    const textElement = this.el.nativeElement.querySelector('.imageOfBees-text');

    if (firstImageElement && textElement) {
      // Fade out the first image
      this.renderer.addClass(firstImageElement, 'fade-out');

      // Wait for the fade-out to complete, then hide the image and show the text
      setTimeout(() => {
        this.renderer.addClass(firstImageElement, 'hidden'); // Hide the first image
        this.renderer.removeClass(textElement, 'hidden');
        this.renderer.addClass(textElement, 'show'); // Fade in the text
      }, 2000); // Adjust timing to match the fade-out duration
    }
  }

  showSecondImage(): void {
    const secondImageElement = this.el.nativeElement.querySelector('.second-image');

    if (secondImageElement) {
      this.renderer.addClass(secondImageElement, 'show'); // Trigger the fade-in effect for the second image
    }
  }



  fadeInImage(): void {
    const firstImageElement = this.el.nativeElement.querySelector('.first-image');
    if (firstImageElement && !this.imageVisible) {
      this.imageVisible = true;
      this.renderer.removeClass(firstImageElement, 'fade-out');
      this.renderer.addClass(firstImageElement, 'fade-in');
      setTimeout(() => {
        this.renderer.addClass(firstImageElement, 'show');
        this.startFadeOutTimer();
      }, 2); // Trigger fade-in
    }
  }

  fadeOutImage(): void {
    const firstImageElement = this.el.nativeElement.querySelector('.first-image');
    if (firstImageElement && this.imageVisible) {
      this.renderer.removeClass(firstImageElement, 'show');
      this.renderer.addClass(firstImageElement, 'hide');
      this.imageVisible = false;
    }
  }

  startFadeOutTimer(): void {
    setTimeout(() => this.fadeOutFirstImage(), 3000); // Automatisches Ausblenden nach 3 Sekunden
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    const firstImageElement = this.el.nativeElement.querySelector('.first-image');
    if (window.scrollY === 0 && firstImageElement) {
      this.fadeInImage();
    }
  }

  playBackgroundMusic(): void {
    const audio = new Audio('assets/music/forest-ambience-with-cuckoo-birds-chirping-123046.mp3');
    audio.loop = true;
    audio.volume = 0.05;
    audio.load();
    audio.play().catch(error => console.error("Audio playback failed", error));
  }
}

*/


/*

function createTrail() {
  const interval = setInterval(() => {
    const trailDot = document.createElement('div');
    trailDot.style.width = '5px';
    trailDot.style.height = '5px';
    trailDot.style.backgroundColor = 'yellow';
    trailDot.style.position = 'absolute';
    // Adjust starting position and increment to match the bee's path
    trailDot.style.top = `${Math.random() * 100}px`;
    trailDot.style.left = `${Math.random() * 200}px`;
    document.getElementById('bee-container').appendChild(trailDot);
  }, 100);

  setTimeout(() => {
    clearInterval(interval);
  }, 5000); // Stop creating trail dots after the bee animation ends
}

createTrail();
*/


/*import { Component, OnInit } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { ErrorFormatterService } from "../../../services/error-formatter.service";
import { BeeServiceService } from "../../../services/bee-service.service";

@Component({
  selector: 'app-bee-home-landing-page',
  templateUrl: './bee-home-landing-page.component.html',
  styleUrls: ['./bee-home-landing-page.component.scss']
})
export class BeeHomeLandingPageComponent implements OnInit {
  imageUrls: string[] = []; // Now handling an array of URLs

  constructor(
    private beeService: BeeServiceService,
    private notification: ToastrService,
    private router: Router,
    private errorFormatter: ErrorFormatterService,
  ) {}

/!*  ngOnInit(): void {
    this.beeService.getImages().subscribe((data: any[]) => {
      data.forEach(imageData => {
        this.loadImage(imageData).then(url => this.imageUrls.push(url)).catch(error => this.notification.error(error));
      });
    }, error => {
      this.notification.error(this.errorFormatter.format(error));
    });
    this.playBackgroundMusic();
  }

  async loadImage(data: any): Promise<string> {
    try {
      const response = await fetch(data.imageUrl);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error fetching the image:', error);
      throw new Error('Failed to load image');
    }
  }*!/
 imageUrl: string | undefined;

  ngOnInit(): void {
    this.beeService.getImages().subscribe((data: any) => {
        this.loadImage(data);
      }, error => {
        this.notification.error((error));
      }
    );
    this.playBackgroundMusic();
  }

  async loadImage(data: any): Promise<void> {
    let string = data.imageUrl;
    fetch(string)
      .then(response => response.blob())
      .then(blob => {
        this.imageUrl = URL.createObjectURL(blob);
      })
      .catch(error => {
        console.error('Error fetching the image:', error);
      });
  }


  //birds-chirping-calm-173695.mp3
  //forest-ambience-with-cuckoo-birds-chirping-123046.mp3
  //calm-nature-sounds-196258.mp3
  playBackgroundMusic(): void {
    const audio = new Audio('assets/music/birds-chirping-calm-173695.mp3');
    audio.loop = true;
    audio.volume = 0.05;
    audio.load();
    audio.play().catch(error => console.error("Audio playback failed", error));
  }
}*/
