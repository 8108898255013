import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Globals} from '../global/globals';
import {TopTen} from "../dtos/event";
import { EventType } from '../dtos/eventType';
import { PerformanceEventDto } from '../dtos/performance';

@Injectable({
  providedIn: 'root'
})
export class TopTenService {

  private topTenBaseUri: string = this.globals.backendUri + '/top-ten';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }

    /**
     * Find the top ten events with the most sold tickets
     * The user is able to choose an optional event type
     * 
     * @param eventType the event type of which to get the events
     * @returns a list of the top ten events
     */
    getTopTen(eventType: EventType): Observable<TopTen[]> {
      let params = new HttpParams();
      params = params.append('type', eventType);
      return this.httpClient.get<TopTen[]>(this.topTenBaseUri, { params });
    }

    /**
     * Find the event and its corresponding performances for the given event id
     * 
     * @param id the id of the event to get
     * @returns a dto that consists the event and its corresponding performances
     */
    getPerformancesAndEventByEventId(id: number): Observable<PerformanceEventDto> {
      return this.httpClient.get<PerformanceEventDto>(this.topTenBaseUri + '/event/' + id);
    }
}
