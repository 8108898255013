import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-user-confirm-delete-modal',
  templateUrl: './user-confirm-delete-modal.component.html',
  styleUrl: './user-confirm-delete-modal.component.scss'
})
export class UserConfirmDeleteModalComponent implements OnInit {
  @Input() accountEmail: string;
  @Output() confirmed = new EventEmitter<void>();

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close();
  }

}
