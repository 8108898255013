import {Component, ViewChild} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import {Router} from "@angular/router";
import {ErrorFormatterService} from "../../../services/error-formatter.service";
import {ToastrService} from "ngx-toastr";
import {HttpStatusCode} from "@angular/common/http";
import {LocationService} from "../../../services/location.service";
import {countries} from "../../../dtos/countries";
import {debounceTime, distinctUntilChanged, filter, map, merge, Observable, OperatorFunction, Subject} from "rxjs";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-create-location',
  templateUrl: './create-location.component.html',
  styleUrl: './create-location.component.scss'
})
export class CreateLocationComponent {
  locationForm: UntypedFormGroup;
  // After first submission attempt, form validation will start
  submitted = false;
  // Error flag
  error = false;

  COUNTRIES = countries.map((country) => country.name);
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  @ViewChild("instance", { static: false }) instance: NgbTypeahead;


  constructor(private formBuilder: UntypedFormBuilder,
              private locationService: LocationService,
              private router: Router,
              private errorFormatter: ErrorFormatterService,
              private notification: ToastrService,) {

    this.locationForm = this.formBuilder.group({
      description: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(254)]],
      city: ["", [Validators.required,  Validators.minLength(1), Validators.maxLength(128)]],
      zipCode: ["", [Validators.required,Validators.minLength(1), Validators.maxLength(128)]],
      country: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(128)]],
      street: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(128)]],
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.locationForm.valid) {
      this.locationService.saveLocation(this.locationForm.value).subscribe({
        next: () => {
          this.notification.success(
            `Location successfully created.`,
          );
          this.router.navigate(["/manage"]);
        },
        error: (error) => {
          if (error.status === HttpStatusCode.UnprocessableEntity) {
            this.notification.error(
              this.errorFormatter.format(error),
              `Could not create the location`,
            );
          } else {
            this.notification.error(
              "Please try again.",
              `Could not create the location`,
            );
          }
        },
      });
    }
  }

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>,
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen()),
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
            ? this.COUNTRIES
            : this.COUNTRIES.filter(
              (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1,
            )
        ).slice(0, 10),
      ),
    );
  };

}
