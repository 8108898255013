import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";

@Component({
  selector: 'app-token-input-modal',
  templateUrl: './token-input-modal.component.html',
  styleUrl: './token-input-modal.component.scss'
})
export class TokenResetPasswordInputModalComponent implements OnInit {
  @Input() requestEmail: string;
  @Output() resendVerificationEmail = new EventEmitter<void>();
  @Output() tokenProvided = new EventEmitter<string>();
  tokenForm: FormGroup;

  constructor(public activeModal: NgbActiveModal, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.tokenForm = this.formBuilder.group({
      char1: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
      char2: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
      char3: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]],
      char4: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(1)]]
    });
  }

  onInput(event: Event, nextInput: HTMLInputElement): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length >= input.maxLength) {
      nextInput.focus();
    }
  }

  submitToken() {
    if (this.tokenForm.valid) {
      const token = this.tokenForm.value.char1 + this.tokenForm.value.char2 +
        this.tokenForm.value.char3 + this.tokenForm.value.char4;
      this.tokenProvided.emit(token);
    }
  }

  resendEmail(){
    this.resendVerificationEmail.emit();
  }

  protected readonly event = event;
}
