import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AuthService} from '../services/auth.service';
import {Observable} from 'rxjs';
import {Globals} from '../global/globals';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private globals: Globals) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authUri = this.globals.backendUri + '/authentication';
    const registerUri = this.globals.backendUri + '/users/register';
    const resetPasswordUri = this.globals.backendUri + '/users/password-reset';
    const resetPasswordReqUri = this.globals.backendUri + '/users/password-reset/request';
    const beeUri = this.globals.backendUri + '/bee';
    const homeUri = this.globals.backendUri+ '';

    // Do not intercept authentication or registration requests
    if (req.url === authUri|| req.url === registerUri || req.url === resetPasswordUri|| req.url === resetPasswordReqUri ||req.url === beeUri || req.url === homeUri) {
      return next.handle(req);
    }

    const authReq = req.clone({
      headers: req.headers.set('Authorization', 'Bearer ' + this.authService.getToken())
    });

    return next.handle(authReq);
  }
}
