import { Component, Input } from "@angular/core";
import { MessageListDto } from "../../../dtos/message";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-message-item",
  templateUrl: "./message-item.html",
  styleUrl: "./message-item.scss",
})
export class MessageItem {
  @Input() message: MessageListDto;

  constructor(private sanitizer: DomSanitizer) {}
}
