<div class="event-container mt-3 position-relative">
  <div class="d-flex justify-content-between col-lg-9">
    <h1 class="title">Create Event-Hall</h1>
    <div class="d-flex align-items-end" style="margin-bottom:1.5rem;">
      <i class="bi bi-arrow-left"></i>
      <a [routerLink]="['/manage']" class="ms-2 link-unstyled"><strong>Back</strong></a>
    </div>
  </div>
  <div class="row">
    <!-- Left Container -->
    <div class="event-hall col-lg-9">
      <div style="margin-top: 10rem" class="d-flex justify-content-center align-items-center"
           *ngIf="sectors.length === 0">
        <h2>No Sectors Added</h2>
      </div>
      <div class="row">
        <div class="scroll-container d-flex flex-row" style="overflow-y: auto; max-height: 80vh; scrollbar-width: thin">
          <!-- Button Container -->
          <div *ngIf="selectedSector && selectedSectorType && selectedSectorType === SectorType.SITTING"
               class="button-container col-2 d-flex ">
            <div class="button-group w-100">
              <div *ngFor="let row of getRowsSelectedSector(); let rowIndex = index" class="w-100">
                <div class="btn-container d-flex align-items-center justify-content-center flex-column w-100">
                  <h6>Seats in row {{ rowIndex + 1 }}</h6>
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <input type="range"
                           class="form-range custom-range flex-grow-1"
                           id="seatRange-{{rowIndex}}"
                           style="margin-top: 0.5rem;"
                           min="0"
                           [max]="maxSeatsInRow"
                           [(ngModel)]="selectedSector.rows[rowIndex].length"
                           (ngModelChange)="adjustSeats(rowIndex, $event)">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Hall Container -->
          <div class="hall-container col-10">
            <!-- Standing Container -->
            <div class="d-flex justify-content-center align-items-center"
                 style="height: 80vh;"
                 *ngIf="selectedSector && selectedSectorType && selectedSectorType === SectorType.STANDING">
              <div class="d-flex justify-content-center align-items-center">
                <div
                  class="rectangle d-flex justify-content-center align-items-center"
                  [style.height.px]="selectedSector.capacity ? Math.max(Math.min(selectedSector.capacity / 1.5, 400), 100) : 200"
                  [style.width.px]="selectedSector.capacity ? Math.max(Math.min(selectedSector.capacity / 0.5, 800), 200) : 500">
                  <h2 style="color: white"> Standing Sector </h2>
                </div>
              </div>
            </div>
            <!-- Sitting Container -->
            <div *ngIf="selectedSectorType && selectedSectorType === SectorType.SITTING" class="hall">
              <div *ngFor="let row of getRowsSelectedSector(); let rowIndex = index"
                   class="d-flex justify-content-center"
                   draggable="true"
                   (dragstart)="onDragStart($event, rowIndex)"
                   (dragover)="onDragOver($event)"
                   (drop)="onDrop($event, rowIndex)"
              >
                <ng-container *ngIf="getSeats(rowIndex).length > 0; else emptyRow">
                  <div *ngFor="let seat of getSeats(rowIndex); let seatIndex = index"
                       [style.height.px]="seatSize"
                       [style.width.px]="seatSize / 1.5"
                       [class.selected]="this.selectedSector.rows[rowIndex][seatIndex]"
                       class="seat flex-row">
                  </div>
                </ng-container>
                <ng-template #emptyRow>
                  <div [style.height.px]="seatSize + 10"
                       [style.width.px]="seatSize / 1.5"
                       class="empty-row flex-row">
                  </div>
                </ng-template>
                <div *ngIf="this.deleteMode && this.selectedSector.rows.length > 1" style="width: 50px"
                     class="d-flex justify-content-center align-items-center">
                  <i class="bi bi-trash fs-3"
                     style="color: white;
                cursor: pointer;"
                     (click)="deleteSectorRowAtIndex(rowIndex)"
                  (keydown.enter)="deleteSectorRowAtIndex(rowIndex)">
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Right Container -->
    <div class="right-container col-lg-3">
      <form #sectorForm="ngForm" (ngSubmit)="addSector(sectorForm)">
        <div class="row">
          <div class="col-12 mt-2">
            <h5>Select Sector</h5>
          </div>
          <div class="col-12 mt-1">
            <select class="form-select" id="sector" [(ngModel)]="selectedSector" name="sectorSelect"
                    (change)="onSectorChange(sectorForm)">
              <!-- Anzeige, wenn keine Sektoren hinzugefügt wurden -->
              <ng-container *ngIf="sectorsArray.length === 0">
                <option disabled selected>No sectors added</option>
              </ng-container>
              <ng-container *ngIf="sectorsArray.length >= maxSectors">
                <optgroup label="Options">
                  <option disabled>Create new sector (Limit reached)</option>
                </optgroup>
              </ng-container>
              <ng-container *ngIf="sectorsArray.length !== 0 && sectorsArray.length < maxSectors">
                <optgroup label="Options">
                  <option [ngValue]="null">Create new sector</option>
                </optgroup>
              </ng-container>
              <optgroup label="Existing Sectors" *ngIf="sectorsArray.length > 0">
                <ng-container *ngFor="let sector of sectorsArray">
                  <option [ngValue]="sector">{{ sector.name }}</option>
                </ng-container>
              </optgroup>
            </select>
          </div>


          <div class="col-12 mt-1">
            <hr>
          </div>
          <div class="col-12 m2-1">
            <h5>{{ selectedSector ? 'Edit Sector' : 'Add Sector' }}</h5>
          </div>
          <div class="col-12 m1-2">
            <label for="name">Sector name</label>
            <input type="text"
                   class="form-control"
                   id="name"
                   name="name"
                   [(ngModel)]="formValues.name" required [ngClass]="{
                           'is-invalid': sectorForm.controls.name?.touched &&  sectorForm.controls.name?.invalid,
                            'is-valid':   !sectorForm.controls.name?.invalid
                            }">
            <div *ngIf="sectorForm.controls.name?.touched  && sectorForm.controls.name?.invalid" class="error">
              <small class="text-danger" *ngIf="sectorForm.controls.name.errors.required">Please enter a sector
                name</small>
            </div>
          </div>
          <div class="col-12 mt-2">
            <label for="sectorType">Sector type</label>
            <select class="form-select" id="sectorType" name="sectorType" [(ngModel)]="selectedSectorType" required
                    [ngClass]="{
                           'is-invalid':  sectorForm.controls.sectorType?.touched && sectorForm.controls.sectorType?.invalid,
                            'is-valid':   !sectorForm.controls.sectorType?.invalid
                            }">
              <option *ngFor="let type of getSectorTypes()" [ngValue]="type">{{ type }}</option>
            </select>
            <div
              *ngIf="sectorForm.controls.sectorType?.touched && sectorForm.controls.sectorType?.value && sectorForm.controls.sectorType?.invalid"
              class="error">
              <small class="text-danger" *ngIf="sectorForm.controls.sectorType.errors.required">Please select sector
                type</small>
            </div>
          </div>
          <div class="col-12 mt-2">
            <label for="price">Price</label>
            <input type="text" class="form-control" id="price" name="price" [(ngModel)]="formValues.price" required
                   [ngClass]="{
                           'is-invalid': (sectorForm.controls.price?.touched && sectorForm.controls.price?.invalid) || (sectorForm.controls.price?.touched && isInvalidPrice(sectorForm.controls.price.value)),
                            'is-valid':   !sectorForm.controls.price?.invalid
                            }">
            <div *ngIf=" sectorForm.controls.price?.touched  && sectorForm.controls.price?.invalid" class="error">
              <small class="text-danger" *ngIf="sectorForm.controls.price.errors.required">Please enter a price</small>
            </div>
            <div *ngIf="sectorForm.controls.price?.touched && isInvalidPrice(sectorForm.controls.price.value)"
                 class="error">
              <small class="text-danger">Please enter a price in the format 15.99</small>
            </div>
          </div>
          <div class="col-12 mt-2"
               *ngIf="selectedSectorType === SectorType.STANDING"
          >
            <label for="capacity">Capacity</label>
            <input type="number" step="1" class="form-control" id="capacity" name="capacity"
                   [(ngModel)]="formValues.capacity" required [ngClass]="{
                           'is-invalid': sectorForm.controls.capacity?.touched && sectorForm.controls.capacity?.invalid,
                            'is-valid':   !sectorForm.controls.capacity?.invalid
                            }">
            <div *ngIf="sectorForm.controls.capacity?.touched  && sectorForm.controls.capacity?.invalid" class="error">
              <small class="text-danger" *ngIf="sectorForm.controls.capacity.errors.required">Please enter a valid
                capacity</small>
            </div>
          </div>
          <div class="col-12 d-flex flex-row">
            <button *ngIf="selectedSector"
                    (click)="updateSector()"
                    class="btn btn-primary w-100 mt-3"
                    [disabled]="sectorForm.invalid || isInvalidPrice(sectorForm.controls.price?.value)"
            >
              Update Sector
            </button>
            <button *ngIf="!selectedSector"
                    type="submit"
                    class="btn btn-primary w-100 mt-3"
                    [disabled]="sectorForm.invalid || isInvalidPrice(sectorForm.controls.price?.value)"
            >
              Add Sector
            </button>
            <button type="button" class="btn btn-danger w-100 mt-3"
                    (click)="openDeleteModal()"
                    [disabled]="!selectedSector"
                    style="margin-left: 10px">
              <i class="bi bi-trash3" style="margin-right: 5px"></i>Delete Sector
            </button>
          </div>
          <div class="col-12 mt-1"
               *ngIf="selectedSector && selectedSectorType && selectedSectorType === SectorType.SITTING">
            <hr>
          </div>
          <div class="col-12 m2-1"
               *ngIf="selectedSector && selectedSectorType && selectedSectorType === SectorType.SITTING">
            <h5>Edit Rows</h5>
          </div>
          <div class="col-12 d-flex flex-row"
               *ngIf="selectedSector && selectedSectorType && selectedSectorType === SectorType.SITTING">
            <button class="btn btn-primary w-100 mt-3"
                    (click)="addRowToSelectedSector()">
              Add Row
            </button>
            <button class="btn btn-danger w-100 mt-3"
                    style="margin-left: 10px"
                    (click)="this.deleteMode=!this.deleteMode"
                    [disabled]="selectedSector?.rows?.length === 1">
              <i
                *ngIf="!this.deleteMode"
                class="bi bi-trash3"></i>
              <i
                *ngIf="this.deleteMode"
                class="bi bi-trash3-fill "></i>
              Delete Rows
            </button>
          </div>
        </div>
        <div class="col-12 mt-1">
          <hr>
        </div>
        <div class="col-12 mt-3">
          <h5>Save Sectors</h5>
        </div>
        <div class="col-12 mt-3 flex-row d-flex">
          <button type="button" class="btn btn-primary w-100 mt-2" style="margin-right: 10px"
                  [disabled]="this.sectors.length===0" (click)="openPerformanceModal()">Select Performance
          </button>
          <button type="button" class="btn btn-primary w-100 mt-2"
                  [disabled]="this.sectors.length===0 || !this.selectedPerformance" (click)="saveEventHall()">Save
            Event-Hall
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-template #deleteModal let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h5 class="modal-title">Delete Confirmation</h5>
    <button
      (click)="modal.dismiss('X')"
      aria-label="Close"
      class="close"
      style="
          background-color: transparent;
          border: none;
          font-size: 2rem;
          color: dimgrey;
        "
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Are you sure to proceed with the deletion of this sector? <br>
      Please be advised that all modifications made to this sector will be permanently lost.
    </p>
  </div>
  <div class="modal-footer">
    <button
      (click)="deleteSector(modal, sectorForm)"
      class="btn btn-danger"
      type="button"
    >
      Yes, Proceed
    </button>
  </div>
</ng-template>
<ng-template #performanceModal let-modal>
  <div class="d-flex justify-content-center align-items-center">
    <div class="modal-content" style="min-width: 700px;">
      <div class="modal-header d-flex justify-content-between align-items-center">
        <input
          (ngModelChange)="searchChanged()"
          [(ngModel)]="searchParam"
          class="form-control"
          placeholder="Search performance by name"
          style="margin: 0 1rem"
          type="text"
        />
      </div>
      <div class="modal-body" style="min-width: 700px; height: 70vh; overflow-y: auto; scrollbar-width: thin">
        <ul class="list-group" style="height: 70vh">
          <li *ngFor="let performance of performances"
              class="list-group-item"
              [class.selected]="performance.id === selectedPerformance?.id"
              (click)="selectPerformance(performance)"
          (keydown.enter)="selectPerformance(performance)">
            <div style="cursor: pointer">
              <h6>{{ performance.name }}</h6>
              <div>
                <div><strong>Date:</strong> {{ performance.dateTime | date:'d/M/yyyy h:mm a' }}</div>
                <div><strong>Event:</strong> {{ performance.event.name }}</div>
                <div>
                  <strong>{{ performance.artists && performance.artists[0] ? 'Artist:' : 'Performer Group:' }}</strong> {{ performance.artists && performance.artists[0] ? performance.artists[0].artistName : performance.performerGroups[0].name }}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="cancelPerformance()">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="savePerformance()">Save selected Performance</button>
      </div>
    </div>
  </div>
</ng-template>
