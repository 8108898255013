<div class="container">
  <div class="d-flex row justify-content-center align-items-center">
    <div class="col-md-8 col-lg-5 col-sm-12">
      <div class="form-wrapper">
        <div class="d-flex justify-content-between">
          <h1 class="title">Create a Show</h1>
          <div class="d-flex align-items-center mb-3">
            <i class="bi bi-arrow-left"></i>
            <a [routerLink]="['/manage']" class="ms-2 link-unstyled"><strong>Back</strong></a>
          </div>
        </div>
        <form [formGroup]="createPerformanceForm" (ngSubmit)="onSubmit()">
          <div class="mb-3 ">
            <label for="performanceName" class="form-label">Performance Name</label>
            <input class="form-control" 
            id="performanceName" 
            type="text" 
            formControlName="name"
            [ngClass]="{
              'is-invalid': submitted && createPerformanceForm.get('name').invalid,
               'is-valid': submitted && !createPerformanceForm.get('name').invalid
               }">
            <div *ngIf="createPerformanceForm.get('name').invalid && (createPerformanceForm.get('name').dirty || createPerformanceForm.get('name').touched)" class="invalid-feedback d-block">
              <div *ngIf="createPerformanceForm.get('name').errors.required">Name is required.</div>
              <div *ngIf="createPerformanceForm.get('name').errors.maxlength">Name cannot be more than 255 characters.</div>
            </div>
          </div>
          <div class="mb-3">
            <label for="dateTime" class="form-label">Date and Time</label>
            <input class="form-control" 
            id="dateTime" 
            type="datetime-local" 
            formControlName="dateTime"
            [ngClass]="{
              'is-invalid': submitted && createPerformanceForm.get('dateTime').invalid,
               'is-valid': submitted && !createPerformanceForm.get('dateTime').invalid
               }">
            <div *ngIf="createPerformanceForm.get('dateTime').invalid && (createPerformanceForm.get('dateTime').dirty || createPerformanceForm.get('dateTime').touched)" class="invalid-feedback d-block">
              <div *ngIf="createPerformanceForm.get('dateTime').errors.required">Date and Time are required.</div>
              <div *ngIf="createPerformanceForm.get('dateTime').errors.dateNotInPast">Date and Time cannot be in the past.</div>
            </div>
          </div>
          <div class="mb-3">
            <label for="duration" class="form-label">Duration (in seconds)</label>
            <input class="form-control" 
            id="duration" 
            type="number" 
            formControlName="durationInSeconds"
            [ngClass]="{
              'is-invalid': submitted && createPerformanceForm.get('durationInSeconds').invalid,
               'is-valid': submitted && !createPerformanceForm.get('durationInSeconds').invalid
               }">
            <div *ngIf="createPerformanceForm.get('durationInSeconds').invalid && (createPerformanceForm.get('durationInSeconds').dirty || createPerformanceForm.get('durationInSeconds').touched)" class="invalid-feedback d-block">
              <div *ngIf="createPerformanceForm.get('durationInSeconds').errors.required">Duration is required.</div>
              <div *ngIf="createPerformanceForm.get('durationInSeconds').errors.min">Duration cannot be negative.</div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Event</label>
            <app-selected-item
              [items]=selectedEvents
              (itemRemoved)="eventRemoved()"
            >

            </app-selected-item>
            <div *ngIf="submitted &&selectedEvents.size === 0" class="text-danger small">
              <div>Event is required.</div>
            </div>
          </div>
          <!-- Location Selection -->
          <div class="mb-3">
            <label class="form-label">Location</label>
            <app-selected-item
              [items]=selectedLocation
              (itemRemoved)="locationRemoved()"
            >

            </app-selected-item>
            <div *ngIf="submitted &&selectedLocation.size === 0" class="text-danger small">
              <div *ngIf="createPerformanceForm.get('locationId').errors.required">Location is required.</div>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Artists</label>
            <app-selected-item
              [items]=selectedArtists
              (itemRemoved)="artistRemoved()"
            >
            </app-selected-item>
            <div *ngIf="submitted && selectedArtists.size === 0 && selectedPerformerGroups.size === 0" class="text-danger small">
              <div>At least one Performer is required.</div>
            </div>
          </div>

          <!-- Performer Group Selection -->
          <div class="mb-3">
            <label class="form-label">Performer Groups</label>
            <app-selected-item
              [items]=selectedPerformerGroups
              (itemRemoved)="performerGroupsRemoved()"
            >

            </app-selected-item>
            <div *ngIf="submitted &&selectedArtists.size === 0 && selectedPerformerGroups.size === 0" class="text-danger small">
              <div>At least one Performer is required.</div>
            </div>
          </div>
          <div class="form-actions">
            <button type="submit" class="btn btn-primary">Add Show</button>
          </div>
        </form>
      </div>
    </div>
    <div class="col-md-8 col-lg-7 col-sm-12">
      <div class="search-container">
        <div class="search-content-buttons" >
          <button class="btn"
            (click)="changeContent('event')"
            [class.active]="selectedButton === 'event'"
            (keydown.enter)="changeContent('event')"
          >
            Event
          </button>
          <button class="btn"
            (click)="changeContent('location')"
            [class.active]="selectedButton === 'location'"
            (keydown.enter)="changeContent('location')"
          >
            Location
          </button>
          <button class="btn"
            (click)="changeContent('artist')"
            [class.active]="selectedButton === 'artist'"
            (keydown.enter)="changeContent('artist')"
          >
            Artist
          </button>

          <button class="btn"
            (click)="changeContent('performanceGroups')"
            [class.active]="selectedButton === 'performanceGroups'"
            (keydown.enter)="changeContent('performanceGroups')"
          >
            Performance Groups
          </button>

        </div>
        <div></div>
        <div *ngIf="showContent === 'location'" class="col-12">
          <app-location-search
            [disable]="disableLocation"
            (selectedLocation)=setLocationSelection($event)>

          </app-location-search>
        </div>

        <div *ngIf="showContent === 'artist'" class="col-12">
          <app-artist-search
            [disable]="disablePerformer"
            (selectedArtist)=setArtistSelection($event)>

          </app-artist-search>
        </div>

        <div *ngIf="showContent === 'event'" class="col-12">
          <app-event-search
            [disable]="disableEvents"
          (selectedEvent)=setEventSelection($event)>

          </app-event-search>
        </div>

        <div *ngIf="showContent === 'performanceGroups'" class="col-12">
          <app-performance-group-search
            [disable]="disablePerformer"
            (selectedPerformanceGroup)=setPerformerGroupSelection($event)>

          </app-performance-group-search>
        </div>


      </div>
  </div>
</div>
</div>
