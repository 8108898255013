import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  UntypedFormBuilder, UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {Router} from '@angular/router';
import {UserService} from "../../services/user.service";
import {ErrorFormatterService} from "../../services/error-formatter.service";
import {PasswordReset} from "../../dtos/passwordReset";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastrService} from "ngx-toastr";
import {TokenResetPasswordInputModalComponent} from "./token-input-modal/token-input-modal.component";


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  requestForm: UntypedFormGroup;
  resetForm: UntypedFormGroup;
  @Output() onClose = new EventEmitter<boolean>();
  requestSubmitted: boolean = false;
  resetSubmitted: boolean = false;
  isLoading: boolean = false;
  showResetForm: boolean = false;
  isError: boolean = false;
  email: string = "";
  specialCharacters = "#?!@$%^&*-";

  showNewPassword: boolean = false;
  showConfirmedPassword: boolean = false;

  private errorFormatter: ErrorFormatterService;


  constructor(private formBuilder: UntypedFormBuilder, private userService: UserService,
              private router: Router, private errorFormatterService: ErrorFormatterService,
              private modalService: NgbModal, private notification: ToastrService) {
    this.requestForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(128)]]
    });

    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')]],
      passwordRepeat: ['', [Validators.required, this.matchControl('password')]]
    });
    this.resetForm.controls.password.valueChanges.subscribe(() => {
      this.resetForm.controls.passwordRepeat.updateValueAndValidity();
    });
  }


  matchControl(matchingControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if (!this.resetForm || !this.resetForm.controls || !control || !this.resetForm.controls[matchingControlName]) {
        return null;
      }

      if (control.value === this.resetForm.controls[matchingControlName].value) {
        return null;
      }

      // Validation failed, return an error object
      return {match: true};
    };
  }


  sendResetRequest() {

    this.requestSubmitted = true;
    if (this.requestForm.valid) {
      this.isLoading = true;
      this.email = this.requestForm.controls.email.value;
      this.userService.sendPasswordResetRequest(this.email).subscribe({
        next: val => {
          this.showResetForm = true;
          this.isError = false;
          this.notification.success("Verification email sent.");

        },
        error: error => {
          this.notification.error("User not found in the System", "Could not send verification email.", {
            enableHtml: true,
          },);
          this.isError = true;
        }
      }).add(() => {
        this.isLoading = false;
      });
    } else {

    }
  }

  resetPassword() {
    this.resetSubmitted = true;
    const modalRef = this.modalService.open(TokenResetPasswordInputModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.requestEmail = this.email;
    modalRef.componentInstance.resendVerificationEmail.subscribe(() => {
      this.resendVerificationEmail();
    });
    modalRef.componentInstance.tokenProvided.subscribe(token => {
      if (this.resetForm.valid) {
        let passwordResetInfo = new PasswordReset(
          this.requestForm.controls.email.value,
          token,
          this.resetForm.controls.password.value
        );


        this.isLoading = true;
        this.userService.sendResetPassword(passwordResetInfo).subscribe({
          next: val => {
            this.showResetForm = false;
            this.isError = false;
            this.onClose.emit(true);
            this.notification.success("Password reset successful.");
            modalRef.close()
            this.router.navigate(['/login']);
          },
          error: error => {
            if (error.status === 400) {
              this.notification.error(error.error.message, "Could not reset password.");
              this.isError = true;
            } else {
              this.notification.error("Could not reset password.");
              this.isError = true;
            }
          }
        }).add(() => {
          this.showResetForm = false;
          this.isLoading = false;
        });
      } else {
        this.notification.error("Please fill out the form correctly.")
      }
    });
  }


  resendVerificationEmail() {
    this.userService.sendPasswordResetRequest(this.email).subscribe({
      next: () => {
        this.notification.success("Verification email sent.")
      },
      error: () => {
        this.notification.error("Could not send verification email.")
      }
    })
  }

  togglePasswordVisibility(field: string): void {
    if (field === 'newPassword') {
      this.showNewPassword = !this.showNewPassword;
    } else if (field === 'confirmedPassword') {
      this.showConfirmedPassword = !this.showConfirmedPassword;
    }
  }


  ngOnInit(): void {

  }
}
