<div class="container">
  <div class="row">
    <div class="col-md-4">
      <span class="anchor" id="formLogin"></span>

      <div class="card rounded-0">
        <div class="card-header text-center mb-0">
          <i class="bi bi-person" style="font-size: 6rem; line-height: 1rem">
          </i>
          <h3 class="mb-0" style="font-weight: bold">Login</h3>
        </div>

        <div class="card-body">
          <form (ngSubmit)="loginUser()" [formGroup]="loginForm" class="form">
            <div class="form-performerGroup">
              <label for="inputUsername">Email</label>
              <input
                aria-describedby="usernameHelp"
                class="form-control"
                formControlName="username"
                id="inputUsername"
                name="username"
                placeholder="Type your email"
                type="email"
                maxlength="140"
                [ngClass]="{
                           'is-invalid': submitted && loginForm.controls.username.errors,
                            'is-valid': submitted && !loginForm.controls.username.errors
                            }"
              />
              <div *ngIf="submitted && loginForm.controls.username.errors" class="invalid-feedback d-block">
                <small *ngIf="loginForm.controls.username.errors?.required">Your email is required!</small>
                <small *ngIf="loginForm.controls.username.errors?.email">Your email has the wrong format!</small>
                <small *ngIf="loginForm.controls.password.errors?.maxlength">Your email is too long! </small>
              </div>
            </div>

            <div class="form-performerGroup mt-3">
              <label for="password">Password</label>
              <div class="input-group">
                <input
                  (keyup.enter)="loginUser()"
                  class="form-control"
                  formControlName="password"
                  id="password"
                  name="password"
                  placeholder="Type your password"
                  maxlength="140"
                  [type]="showPassword ? 'text' : 'password'"
                  [ngClass]="{
                           'is-invalid': submitted && loginForm.controls.password.errors,
                            'is-valid': submitted && !loginForm.controls.password.errors
                            }"
                />
                <button class="btn btn-secondary" type="button" id="button-see-password"
                        (click)="togglePasswordVisibility()">
                  <i class="bi" [ngClass]="showPassword ? 'bi-eye-slash' : 'bi-eye'"></i>
                </button>
              </div>
              <div *ngIf="submitted && loginForm.controls.password.errors" class="invalid-feedback d-block">
                <small *ngIf="loginForm.controls.password.errors?.required">Your password is required!</small>
                <small *ngIf="loginForm.controls.password.errors?.pattern">Your password has the wrong format!</small>
                <small *ngIf="loginForm.controls.password.errors?.minlength && !loginForm.controls.password.errors?.pattern">Your password must be at least 8 characters long!</small>
                <small *ngIf="loginForm.controls.password.errors?.maxlength && !loginForm.controls.password.errors?.pattern">Your password is too long! </small>
              </div>
              <p class="text-muted mt-1">
                <a
                  class="forgot-password-link"
                  routerLink="/reset-password"
                  style="color: grey; font-size: 0.75rem;"
                >Forgot Password?</a
                >
              </p>
            </div>
            <div style="text-align: center">
              <button
                (click)="loginUser()"
                class="btn btn-success float-right btn-primary"
                type="button"
              >
                Sign In
              </button>
            </div>
            <div class="text-center" style="margin-top: 1rem">
              <p class="text-muted mb-0">
                Don't have an account?
                <a class="register-link" routerLink="/register">Sign Up now!</a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <ng-template #lockedModal let-modal>
    <div class="modal-header d-flex justify-content-between">
      <h5 class="modal-title">Account suspended</h5>
      <button
        (click)="modal.dismiss('X')"
        aria-label="Close"
        class="close"
        style="
          background-color: transparent;
          border: none;
          font-size: 2rem;
          color: dimgrey;
        "
        type="button"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        Your account is currently suspended.<br/>
        Contact an admin for further information.
      </p>
    </div>
    <div class="modal-footer">
      <button
        (click)="modal.dismiss('close')"
        class="btn btn-secondary"
        type="button"
      >
        Okay
      </button>
    </div>
  </ng-template>
</div>
