export enum EventType {
  ARTS = "ARTS",
  ENTERTAINMENT = "ENTERTAINMENT",
  FASHION = "FASHION",
  HEALTH = "HEALTH",
  BUSINESS = "BUSINESS",
  SCIENCE = "SCIENCE",
  TRAVEL = "TRAVEL",
  TECHNOLOGY = "TECHNOLOGY",
  ENVIRONMENT = "ENVIRONMENT",
  COMMUNITY = "COMMUNITY",
  CHARITY = "CHARITY",
  DISABLED = "",
}
