<div class="container">
  <div class="row">
    <div class="col-md-4">
      <span class="anchor" id="formResetPassword"></span>

      <div class="card rounded-0">
        <div class="card-header text-center mb-0">
          <i class="bi bi-lock" style="font-size: 6rem; line-height: 1rem"></i>
          <h2 class="mb-0" style="font-weight: bold; margin-top:2rem">Reset your Password</h2>
          <h6 class="mb-0" style="font-weight: lighter; margin-top:2rem"> Please enter your email address. You will
            receive a token via email
            to create a new password. Do not close this page! </h6>
        </div>
        <div class="card-body">
          <div *ngIf="!isLoading && !showResetForm">
            <form class="form" [formGroup]="requestForm" (ngSubmit)="sendResetRequest()">
              <div class="form-performerGroup">
                <label for="inputEmail">E-Mail</label>
                <input
                  aria-describedby="emailHelp"
                  class="form-control"
                  formControlName="email"
                  id="inputEmail"
                  name="email"
                  placeholder="Type your email"
                  type="email"
                  maxlength="140"
                  [ngClass]="{
                              'is-invalid': requestSubmitted && requestForm.controls.email.errors,
                              'is-valid': requestSubmitted && !requestForm.controls.email.errors
                            }"
                />
                <div
                  *ngIf="requestSubmitted && requestForm.controls.email.errors"
                  class="invalid-feedback d-block"
                >
                  <small *ngIf="requestForm.controls.email.errors.required">
                    Your email is required!
                  </small>
                  <small *ngIf="requestForm.controls.email.errors.email"
                  >Your email is not valid!
                  </small>
                </div>
              </div>

              <div style="text-align: center; margin-top:2rem">
                <button
                  (click)="sendResetRequest()"
                  class="btn btn-success float-right btn-primary"
                  type="button"
                >
                  Reset Password

                </button>
              </div>
            </form>
          </div>

          <div *ngIf="isLoading" class="tw-pt-10">
            <div class="loader tw-mx-auto"></div>
          </div>

          <div *ngIf="!isLoading && showResetForm">
            <form class="form" [formGroup]="resetForm" (ngSubmit)="resetPassword()">


              <label for="password">Password</label>
              <div class="input-group">

                <input
                  aria-describedby="passwordHelp"
                  class="form-control"
                  formControlName="password"
                  id="password"
                  name="password"
                  placeholder="Type your password"
                  type="password"
                  [type]="showNewPassword ? 'text' : 'password'"
                  [ngClass]="{
                              'is-invalid': resetForm.controls.password.errors,
                              'is-valid': !resetForm.controls.password.errors
                            }"
                />
                <button class="btn btn-secondary" type="button" id="button-see-password"
                        (click)="togglePasswordVisibility('newPassword')">
                  <i class="bi" [ngClass]="showNewPassword ? 'bi-eye-slash' : 'bi-eye'"></i>
                </button>
              </div>
              <div
                *ngIf="resetForm.controls.password.errors"
                class="invalid-feedback d-block"
              >
                <small
                  *ngIf="resetForm.controls.password.errors.required"

                >
                  Please enter your new password!
                </small>
                <small
                  *ngIf="resetForm.controls.password.errors.minlength"

                >
                  Your password must be at least 8 characters long!
                </small>
                <small
                  *ngIf="resetForm.controls.password.errors.maxlength"

                >
                  Your password is too long, it must be shorter then 256
                  characters!
                </small>
                <small *ngIf="resetForm.controls.password.errors.pattern">
                  New password must contain at least one uppercase letter, one lowercase letter, one digit, and one of
                  the
                  following special characters: {{ specialCharacters }}
                </small>
              </div>

              <div class="form-performerGroup input-form">
                <label for="password">Confirm Password</label>
                <div class="input-group">

                  <input
                    aria-describedby="passwordRepeatHelp"
                    class="form-control"
                    formControlName="passwordRepeat"
                    id="passwordRepeat"
                    name="passwordRepeat"
                    placeholder="Confirm your Password"
                    [type]="showConfirmedPassword ? 'text' : 'password'"
                    [ngClass]="{
                              'is-invalid': resetForm.controls.passwordRepeat.errors,
                              'is-valid':  !resetForm.controls.passwordRepeat.errors
                            }"
                  />
                  <button class="btn btn-secondary" type="button" id="button-see-password-confirm"
                          (click)="togglePasswordVisibility('confirmedPassword')">
                    <i class="bi" [ngClass]="showConfirmedPassword ? 'bi-eye-slash' : 'bi-eye'"></i>
                  </button>

                </div>
                <div
                  *ngIf="resetForm.controls.passwordRepeat.errors"
                  class="invalid-feedback d-block"
                >
                  <small
                    *ngIf="resetForm.controls.passwordRepeat.errors.required"
                  >
                    Repeat your password!
                  </small>
                  <small
                    *ngIf="resetForm.controls.passwordRepeat.errors.match"
                  >
                    The passwords do not match!
                  </small>
                </div>
              </div>


              <div style="text-align: center; margin-top:2rem">
                <button
                  (click)="resetPassword()"
                  class="btn btn-success float-right btn-primary"
                  type="button"
                  [disabled]="resetForm.invalid"
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
