import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Observable } from 'rxjs';
import {PerformerGroupListDto} from "../dtos/performerGroup";
import {Globals} from "../global/globals";

@Injectable({
  providedIn: 'root',
})
export class PerformerGroupService {
  private performerGBaseUri: string = this.globals.backendUri + '/performer-groups';

  constructor(private httpClient: HttpClient, private globals: Globals) {
  }


  search(name: string): Observable<PerformerGroupListDto[]> {
    let params = new HttpParams().set("name", name);
    return this.httpClient.get<PerformerGroupListDto[]>(this.performerGBaseUri, {params});
  }
}
