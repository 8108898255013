<div class="container">
  <div *ngIf="message" class="details">
    <div class="d-flex justify-content-between w-100">
      <h1 class="title">{{ message.title }}</h1>
      <div class ="d-flex gap-3">
        <div class="published-at">{{ message.publishedAt | date:'shortDate' }}</div>
        <div class="d-flex align-items-end" >
          <i class="bi bi-arrow-left"></i>
          <a (click)="goBack()" class="ms-2 link-unstyled" style="cursor:pointer"><strong>Back</strong></a>
        </div>
      </div>
    </div>
    <div class="summary">{{ message.summary }}</div>

    <div class="image-text">
      <img *ngIf="message.image" alt="" class="news-image"
           height="300" [src]="'data:image/png;base64,' + message.image" width="800">
      <div class="text">{{ message.text }}</div>
    </div>
  </div>
</div>
