
import { Injectable } from "@angular/core";
import { Globals } from "../global/globals";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
  AdminRegisterDto,
  EmailUpdateDto,
  PasswordUpdateDto,
  RegisterRequestDto,
  UserDataUpdateRequestDto,
  UserListDto,
  UserSearchDto
} from "../dtos/user";
import { Observable } from "rxjs";
import {PasswordReset} from "../dtos/passwordReset";
import { Message } from "../dtos/message";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private userBaseUri: string = this.globals.backendUri + "/users";

  constructor(
    private httpClient: HttpClient,
    private globals: Globals,
  ) {}

  search(searchParams: UserSearchDto): Observable<UserListDto[]> {
    let params = new HttpParams();
    if (searchParams.searchTerm) {
      params = params.append("searchTerm", searchParams.searchTerm);
    }
    if (searchParams.status) {
      params = params.append("status", searchParams.status);
    }
    if (searchParams.pageNumber) {
      params = params.append("pageNumber", searchParams.pageNumber);
    }
    if (searchParams.pageSize) {
      params = params.append("pageSize", searchParams.pageSize);
    }
    return this.httpClient.get<UserListDto[]>(this.userBaseUri, { params });
  }

  getSearchAmount(searchParams: UserSearchDto): Observable<number> {
    let params = new HttpParams();
    if (searchParams.searchTerm) {
      params = params.append("searchTerm", searchParams.searchTerm);
    }
    if (searchParams.status) {
      params = params.append("status", searchParams.status);
    }
    return this.httpClient.get<number>(this.userBaseUri + "/amount", {
      params,
    });
  }

  register(registerRequestDto: RegisterRequestDto): Observable<any> {
    return this.httpClient.post(
      this.userBaseUri + "/register",
      registerRequestDto,
      { responseType: "text" },
    );
  }

  requestUserDataUpdate(userDataUpdate: UserDataUpdateRequestDto): Observable<any> {
    return this.httpClient.patch(`${this.userBaseUri}/token`, userDataUpdate);
  }

  updateUserEmail(id: number, emailUpdateData: EmailUpdateDto): Observable<any> {
    return this.httpClient.put(`${this.userBaseUri}/${id}/email`, emailUpdateData);
  }

  updateUserPassword(id: number, passwordUpdateData: PasswordUpdateDto): Observable<any> {
    return this.httpClient.put(`${this.userBaseUri}/${id}/password`, passwordUpdateData);
  }

  getUserById(id: number): Observable<UserListDto> {
    return this.httpClient.get<UserListDto>(`${this.userBaseUri}/${id}`);
  }

  deleteUserById(id: number): Observable<any> {
    return this.httpClient.delete(`${this.userBaseUri}/${id}`);
  }

  sendPasswordResetRequest(email: string): Observable<any> {
    return this.httpClient.post(this.userBaseUri + "/password-reset/request", {email});
  }
  sendResetPassword(passwordReset: PasswordReset): Observable<any> {
    return this.httpClient.post(this.userBaseUri + "/password-reset", passwordReset);
  }


  resetUserPassword(id: number): Observable<any> {
    return this.httpClient.patch(`${this.userBaseUri}/${id}/password`, {});
  }

  /**
   * Get the unread messages for a user
  */
  getUnreadMessages(): Observable<Message[]> {
    return this.httpClient.get<Message[]>(this.userBaseUri + "/messages");
  }

  /**
   * Add message to list of viewd messages of user
   *
   * @param id the id of user to add the message as viewed
   * @param messageId the id of the message to add to the user as viewed
   */
  addNewsToViewedNews(id: number, messageId: number) {
    return this.httpClient.patch(`${this.userBaseUri}/${id}/messages`, messageId);
  }

   /**
   * The user with the given id is locked or unlocked according to its given status
   *
   * @param id the id of the user to lock or unlock
   * @param locked the status of the user to change it to
   */
   lockUser(id: number, locked: boolean) {
    return this.httpClient.patch(this.userBaseUri + "/lock/" + id, locked);
  }

  /**
   * Admin creates a new user in the system
   *
   * @param user the user to create
   * @returns the newly created user
   */
  createUserByAdmin(user: AdminRegisterDto): Observable<AdminRegisterDto> {
    return this.httpClient.post<AdminRegisterDto>(this.userBaseUri + "/admin", user);
  }




}
