import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Globals} from "../global/globals";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BeeServiceService {


  private beeBaseUrl: string = this.globals.backendUri + "/bee";

  constructor(
    private httpClient: HttpClient,
    private globals: Globals,
  ) {
  }


  getProducts(): any {
    return this.httpClient.get(this.beeBaseUrl + "/products");
  }


  //get image for the home page
  getImages(): Observable<any> {
    return this.httpClient.get(this.beeBaseUrl + "/landingImage");
  }

  getProductsByCategory(category: string) {
    return this.httpClient.get(this.beeBaseUrl + "/products/category/" + category);

  }

  getProductsById(id: string | number): Observable<any> {
    return this.httpClient.get(this.beeBaseUrl + "/products/detail/" + id);
  }


  private orderBaseUrl: string = this.globals.backendUri + '/orders';

  getOrders(test:string): Observable<any> {

    return this.httpClient.get(this.orderBaseUrl);

  }

  getOrderById(id: string | number): Observable<any> {
    return this.httpClient.get(this.orderBaseUrl + '/details/' + id);

  }
}
