import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Sector} from "../../../dtos/sector";
import {NgForm} from "@angular/forms";
import {SectorType} from "../../../dtos/sectorType";
import {ToastrService} from "ngx-toastr";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PerformanceListDto} from "../../../dtos/performance";
import {PerformanceService} from "../../../services/perfomance.service";
import {debounceTime, Subject} from "rxjs";
import {Router} from "@angular/router";
import {HttpStatusCode} from "@angular/common/http";
import {ErrorFormatterService} from "../../../services/error-formatter.service";

@Component({
  selector: 'app-create-event-hall',
  templateUrl: './create-event-hall.component.html',
  styleUrl: './create-event-hall.component.scss'
})
export class CreateEventHallComponent implements OnInit{
  seatSize: number = 80;
  defaultSeatsInRow = 8;
  maxSeatsInRow= 20
  maxRows = 10;
  maxSectors:number = 6;
  sectors: Sector[] = [];
  selectedSector: Sector;
  selectedSectorType: SectorType;
  selectedPerformance: PerformanceListDto;
  performances: PerformanceListDto[] = [];
  searchChangedObservable = new Subject<void>();
  searchParam: string;
  deleteMode:boolean = false;
  formValues = {
    name: undefined,
    sectorType: SectorType.SITTING,
    price: undefined,
    capacity: undefined
  };
  protected readonly SectorType = SectorType;
  protected readonly Math = Math;


  @ViewChild('deleteModal', { static: true }) deleteModal: TemplateRef<any>;
  @ViewChild('performanceModal', { static: true }) performanceModal: TemplateRef<any>;
  constructor(private notification: ToastrService,
              private modalService: NgbModal,
              private performanceService: PerformanceService,
              private errorFormatter : ErrorFormatterService,
              private router: Router) {

  }
  ngOnInit(): void {
    this.searchChangedObservable.pipe(debounceTime(250)).subscribe({
      next: () => {
        this.fetchPeformances(this.searchParam)
      },
    });
  }

  onSectorChange(form: NgForm) {
    if (this.selectedSector) {
      this.formValues = {
        name: this.selectedSector.name,
        sectorType: this.selectedSector.sectorType,
        price: this.selectedSector.priceInCents,
        capacity: this.selectedSector.capacity
      };
      this.selectedSectorType = this.selectedSector.sectorType;
    } else {
      this.formValues = {
        name: undefined,
        sectorType: undefined,
        price: undefined,
        capacity: undefined
      };
      this.selectedSectorType = null;
      form.control.markAsUntouched();
      Object.keys(form.controls).forEach(field => {
        const control = form.control.get(field);
        control?.markAsUntouched({ onlySelf: true });
      });
    }
  }


  deleteSector(modal: any, form: NgForm) {
    this.sectors = this.sectors.filter(sector => sector !== this.selectedSector);
    this.selectedSector = null;
    this.selectedSectorType = null;
    form.reset();
    modal.dismiss('close');
  }

  addSector(form: NgForm) {
    if (!form.valid) {
      return;
    }

    let rows: boolean[][] = [];
    if (this.selectedSectorType !== SectorType.STANDING) {
      rows.push(new Array(this.defaultSeatsInRow).fill(false));
    }

    const newSector = new Sector(
      this.formValues.name,
      this.formValues.capacity,
      this.formValues.price,
      this.selectedSectorType,
      rows,
      true
    );

    if (this.selectedSector) {
      // Update existing sector
      this.selectedSector.name = newSector.name;
      this.selectedSector.sectorType = newSector.sectorType;
      this.selectedSector.priceInCents = newSector.priceInCents;
      this.selectedSector.capacity = newSector.capacity;
      const index = this.sectors.findIndex(sector => sector === this.selectedSector);
      if (index !== -1) {
        this.sectors[index] = this.selectedSector;
      }
    } else {
      // Add new sector
      this.sectors.push(newSector);
      this.selectedSector = newSector;
      this.notification.success("Successfully created sector " + newSector.name);
    }
  }

  updateSector(){
    this.selectedSector.name = this.formValues.name;
    this.selectedSector.sectorType = this.selectedSectorType;
    this.selectedSector.priceInCents = this.formValues.price;
    this.selectedSector.capacity = this.formValues.capacity;
    if (this.selectedSector.rows === null || this.selectedSector.rows.length === 0) {
      this.selectedSector.rows = this.selectedSector.sectorType === SectorType.STANDING
        ? []
        : [new Array(this.defaultSeatsInRow).fill(false)];
    }
    const index = this.sectors.findIndex(sector => sector === this.selectedSector);
    if (index !== -1) {
      this.sectors[index] = this.selectedSector;
      this.notification.success("Successful Updated Sector " + this.formValues.name)
    }
  }
  getSectorTypes() {
    return Object.values(SectorType);
  }
  isInvalidPrice(value:string): boolean {
    if (!value) {
      return false;
    }
    const regex = /^\d+(\.\d{1,2})?$/;
    return !regex.test(value);
  }

  getSeats(rowIndex: number): any[] {
    return this.selectedSector.rows[rowIndex]
  }
  fetchPeformances(performanceName:string) {
    this.performanceService.getPerformancesWithSectorLimit(this.sectors.length, performanceName).subscribe({
      next: (data) => {
        this.performances = data;
        console.log(this.performances)
      },
      error: () => {
        this.notification.error(
          "Please try again.",
          "Could not fetch Performance data.",
        );
      },
    });
  }

  getRowsSelectedSector() {
    return this.selectedSector ? this.selectedSector.rows : [];
  }
  get sectorsArray() {
    return this.sectors;
  }
  addRowToSelectedSector(){
    if(this.selectedSector.rows.length <= this.maxRows){
      this.selectedSector.rows.push(new Array(this.defaultSeatsInRow).fill(false))
    }else{
      this.notification.error(`Maximum number of ${this.maxRows} rows reached`)
    }
  }
  deleteSectorRowAtIndex(index:number){
    if (index >= 0 && index < this.selectedSector.rows.length) {
      this.selectedSector.rows.splice(index, 1);
    }
  }
  adjustSeats(rowIndex: number, newLength: number) {
    if (this.selectedSector && this.selectedSector.rows && this.selectedSector.rows[rowIndex]) {
      const currentLength = this.selectedSector.rows[rowIndex].length;
      if (newLength > currentLength) {
        while (this.selectedSector.rows[rowIndex].length < newLength) {
          this.selectedSector.rows[rowIndex].push(false);
        }
      } else if (newLength < currentLength) {
        this.selectedSector.rows[rowIndex].length = newLength;
      }
    }
  }

  onDragStart(event: DragEvent, rowIndex: number) {
    event.dataTransfer?.setData('text/plain', rowIndex.toString());
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  onDrop(event: DragEvent, targetIndex: number) {
    event.preventDefault();
    const sourceIndex = parseInt(event.dataTransfer?.getData('text/plain') || '0', 10);
    this.moveRow(sourceIndex, targetIndex);
  }

  moveRow(sourceIndex: number, targetIndex: number) {
    if (this.selectedSector
      && sourceIndex !== targetIndex
      && sourceIndex >= 0
      && targetIndex >= 0
      && sourceIndex < this.selectedSector.rows.length
      && targetIndex < this.selectedSector.rows.length) {
      const [movedRow] = this.selectedSector.rows.splice(sourceIndex, 1);
      this.selectedSector.rows.splice(targetIndex, 0, movedRow);
    }
  }
  openDeleteModal() {
    this.modalService.open(this.deleteModal);
  }
  cancelPerformance(){
    this.selectedPerformance = null;
    this.searchParam = '';
    this.modalService.dismissAll();
  }
  savePerformance(){
    this.modalService.dismissAll();
    this.searchParam = '';
    this.notification.success("Successful Selected Performance: " + this.selectedPerformance.name);
  }
  openPerformanceModal() {
    this.performanceService.getPerformancesWithSectorLimit(this.sectors.length, null).subscribe({
      next: (data) => {
        this.performances = data;
      },
      error: () => {
        this.notification.error(
          "Please try again.",
          "Could not fetch Performance data.",
        );
      },
      complete: () => {
        this.modalService.open(this.performanceModal)
      },
    });
  }
  selectPerformance(performance: PerformanceListDto) {
    this.selectedPerformance = performance;
  }
  searchChanged(): void {
    this.searchChangedObservable.next();
  }
  saveEventHall(){
    let sectorCreateDtos = this.sectors.map(sector => sector.toDto(this.selectedPerformance.id));
    this.performanceService.saveEventHall(sectorCreateDtos).subscribe({
      next: (data) => {
        this.notification.success("Successful Created Event Hall");
        this.router.navigate(['/eventhall/'+this.selectedPerformance.id]);
      },
      error: (error) => {
        if (error.status === HttpStatusCode.UnprocessableEntity) {
          this.notification.error(
            this.errorFormatter.format(error),
            `Could not save Event Hall.`,
          );
        }else {
          this.notification.error(
            "Please try again.",
            "Could not create Event Hall",
          );
        }
      }
    });
  }
}
