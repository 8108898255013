import {Component, OnInit} from '@angular/core';
import {EventService} from "../../../services/event.service";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import { EventCreateDto} from "../../../dtos/event";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import { HttpStatusCode } from '@angular/common/http';
import { ErrorFormatterService } from 'src/app/services/error-formatter.service';


@Component({
  selector: 'app-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})

export class CreateEventComponent implements OnInit{
  createEventForm: FormGroup;
  submitted = false;
  invalidFileType = false;
  createEventDto: EventCreateDto = new EventCreateDto();
  image : any;

  constructor(
    private eventService: EventService,
    private formBuilder: FormBuilder,
    private errorFormatter: ErrorFormatterService,
    private router: Router,
    private notification: ToastrService
  ) {}

  ngOnInit(): void {
    this.createEventForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      description: ['', [Validators.required, Validators.maxLength(1000)]],
      startDateTime: ['', Validators.required],
      endDateTime: ['', Validators.required],
      category: ['', Validators.required],
      content: ['', Validators.required],
      image:[null ]

    });
  }

  /**
   * Sends event creation request
   */
  createEvent() {
    this.submitted = true;
    console.log(this.createEventDto)
    console.log(this.createEventForm.value)
    if (this.createEventForm.valid) {
      this.eventService.createEvent(this.createEventForm.value).subscribe({
        next: () => {
          this.notification.success('Successfully created event: ' + this.createEventForm.controls.name.value);
          this.router.navigate(["/manage"]);
        },
        error: error => {
          if (error.status === HttpStatusCode.UnprocessableEntity) {
            this.notification.error(
              this.errorFormatter.format(error),
              `Could not create event.`,
            );
          } else {
            this.notification.error(
              "Please try again.",
              `Could not create event.`,
            );

          }
        }
      });
    }
  }

  onFileSelected(event: any ,createEvent : EventCreateDto) {
    const file: File = event.target.files[0];
    if (!file || file.type !== 'image/png') {
      this.invalidFileType = true;
      return;
    } else {
      this.invalidFileType = false;
    }
    const reader = new FileReader();
    reader.addEventListener("load", (event: any) => {
      console.log(createEvent)
      this.createEventForm.patchValue({image: event.target.result.split(",")[1]})
    }
    )

    if (file) {
      reader.readAsDataURL(file);
    }
  }

}
