<div class="name-artist-date">
  <div>
    <span class="name"> {{ ticket.sector.performance.event.name }}</span>
  </div>

  <div>
    <span class="artist">{{ ticket.sector.performance.artist }}</span>
  </div>

  <div>
    <span class="date">{{
        convertDate(ticket.sector.performance.dateTime)
      }}</span>
  </div>
</div>

<div class="time-location">
  <div>
    <span class="time">{{
        convertTime(ticket.sector.performance.dateTime)
      }}</span>
  </div>
  <div>
    <span class="location">{{
        ticket.sector.performance.location.address.street
      }}</span>
  </div>
</div>

<div class="price-seat">
  <div>
    <span class="time"
    >{{ (ticket.sector.priceInCents / 100.0).toFixed(2) }}€</span
    >
  </div>
  <div>
    <span
      *ngIf="ticket.sector.sectorType === SectorType.SITTING"
      class="location"
    >Row {{ ticket.rowIndex }} Seat {{ ticket.seatIndex }} Sector&nbsp;{{
        ticket.sector.id
      }}</span
    >
    <span
      *ngIf="ticket.sector.sectorType === SectorType.STANDING"
      class="location"
    >Standing Sector</span
    >
  </div>
</div>
<i (click)="removeTicket(ticket.id)" (keydown.enter)="removeTicket(ticket.id)"
   [class.hidden]="!isInCart"
     class="bi bi-trash h3"
></i>
<div
  [ngStyle]="{ 'background-color': backgroundColor }"
  class="circle circle-left"
></div>
<div
  [ngStyle]="{ 'background-color': backgroundColor }"
  class="circle circle-right"
></div>
<div
  [ngStyle]="{ 'border-right': '5px dotted ' + backgroundColor }"
  class="dotted dotted-left"
></div>
<div
  [ngStyle]="{ 'border-right': '5px dotted ' + backgroundColor }"
  class="dotted dotted-right"
></div>
<div *ngIf="!ticketValid(ticket)" class="refunded">
  {{ getTypeOfTicket(ticket) }}
</div>
