import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Message } from "../dtos/message";
import { Observable, shareReplay } from "rxjs";
import { Globals } from "../global/globals";

@Injectable({
  providedIn: "root",
})
export class MessageService {
  private messageBaseUri: string = this.globals.backendUri + "/messages";
  private viewedBaseUri: string = this.globals.backendUri + "/viewed";
  private messageCache: { [page: number]: Observable<Message[]> } = {};

  constructor(
    private httpClient: HttpClient,
    private globals: Globals,
  ) {}

  /**
   * Loads the specified amount of messages for a page from the backend if it is not already cached
   */
  getPageOfMessages(
    pageNumber: number,
    pageSize: number,
    fetchAgain: boolean,
  ): Observable<Message[]> {
    if (!this.messageCache[pageNumber] || fetchAgain) {
      this.messageCache[pageNumber] = this.httpClient
        .get<
          Message[]
        >(this.messageBaseUri + "/page/" + pageNumber + "-" + pageSize)
        .pipe(shareReplay(1));
    }
    return this.messageCache[pageNumber];
  }

  getAmountOfMessages(): Observable<number> {
    return this.httpClient.get<number>(this.messageBaseUri + "/amount");
  }

  /**
   * Loads specific message from the backend
   *
   * @param id of message to load
   */
  getMessageById(id: number): Observable<Message> {
    return this.httpClient.get<Message>(this.messageBaseUri + "/" + id);
  }

  /**
   * Persists message to the backend
   *
   * @param message to persist
   */
  createMessage(message: Message): Observable<Message> {
    return this.httpClient.post<Message>(this.messageBaseUri, message);
  }
}
