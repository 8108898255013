


<app-back-arrow-button></app-back-arrow-button>
<section id="products" class="product-section">
    <div class="container">
        <h1 class="nameDetailProduct">Our Products</h1>

        <!-- Main category filter buttons -->
        <div class="filter">
            <button (click)="filterProducts('all')">All</button>
            <button (click)="filterProducts('honey')">Honey</button>
            <button (click)="filterProducts('beeswax')">Beeswax</button>
            <button (click)="filterProducts('pollen')">Pollen</button>
            <button (click)="filterProducts('propolis')">Propolis</button>
            <button (click)="filterProducts('alcohol')">Alcohol</button>
        </div>

        <!-- Show additional filters only when 'Honey' is selected -->
        <div class="filter" *ngIf="selectedFilter === 'honey'">
            <!-- Dropdown for Jar Size -->
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                    Jar Size
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" (click)="toggleJarSize('100')">
                        <input type="checkbox" [checked]="selectedJarSizes.includes('100')"/> 100ml
                    </a></li>
                    <li><a class="dropdown-item" (click)="toggleJarSize('200')">
                        <input type="checkbox" [checked]="selectedJarSizes.includes('200')"/> 200ml
                    </a></li>
                    <li><a class="dropdown-item" (click)="toggleJarSize('500')">
                        <input type="checkbox" [checked]="selectedJarSizes.includes('500')"/> 500ml
                    </a></li>
                    <li><a class="dropdown-item" (click)="toggleJarSize('1000')">
                        <input type="checkbox" [checked]="selectedJarSizes.includes('1000')"/> 1000ml
                    </a></li>
                </ul>
            </div>


            <!-- Dropdown for Type -->
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                    Type
                </button>
                <ul class="dropdown-menu">
                    <li><a class="dropdown-item" (click)="toggleType('raps')">
                        <input type="checkbox" [checked]="selectedTypes.includes('Rapshonig')"/> Rapshonig
                    </a></li>
                    <li><a class="dropdown-item" (click)="toggleType('akazien')">
                        <input type="checkbox" [checked]="selectedTypes.includes('Akazienhonig')"/> Akazienhonig
                    </a></li>
                    <li><a class="dropdown-item" (click)="toggleType('blueten')">
                        <input type="checkbox" [checked]="selectedTypes.includes('Blütenhonig')"/> Blütenhonig
                    </a></li>
                    <li><a class="dropdown-item" (click)="toggleType('kastanien')">
                        <input type="checkbox" [checked]="selectedTypes.includes('Kastanienhonig')"/> Kastanienhonig
                    </a></li>
                </ul>
            </div>

            <!-- Apply and Reset buttons --><!--
            <button class="btn btn-primary" (click)="applyFilters()">Apply</button>-->
            <button class="btn btn-secondary" (click)="resetFilters()">Reset</button>
        </div>

        <!-- Product Grid -->
        <div class="product-grid">
            <div class="product-item" *ngFor="let product of filteredProducts" (click)="productDetailView(product.id)">
                <div [ngClass]="{'out-of-stock': product.available_Quantity < 1}">
                    <img *ngIf="product.image_Url" [src]="product.image_Url" alt="Product Image" loading="lazy" />
                    <h2>{{ product.product_Name }}</h2>
                    <p>{{ product.product_Description }}</p>
                    <p class="price">{{ product.cost | currency }}</p>
                    <button (click)="addProductToCart(product)">Add to Cart</button>
                </div>
            </div>
        </div>
    </div>
</section>
