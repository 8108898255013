import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {UserService} from "../../../services/user.service";
import {Router} from "@angular/router";
import {UserListDto} from "../../../dtos/user";
import {ToastrService} from "ngx-toastr";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TokenInputModalComponent} from "./token-input-modal/token-input-modal.component";
import {AuthService} from "../../../services/auth.service";
import {ErrorFormatterService} from "../../../services/error-formatter.service";
import {HttpStatusCode} from "@angular/common/http";
import {UserConfirmDeleteModalComponent} from "./user-confirm-delete-modal/user-confirm-delete-modal.component";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrl: './edit-profile.component.scss'
})
export class EditProfileComponent implements OnInit {

  emailForm: FormGroup;
  passwordForm: FormGroup;
  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmedPassword: boolean = false;
  emailFormSubmitted: boolean = false;
  passwordFormSubmitted: boolean = false;
  user: UserListDto;
  emailToSentTokenTo: string;
  specialCharacters = "#?!@$%^&*-";


  constructor(private formBuilder: FormBuilder, private userService: UserService, private router: Router,
              private notification: ToastrService, private modalService: NgbModal,
              private authService: AuthService, private errorFormatter: ErrorFormatterService) {
  }

  ngOnInit(): void {
    const userId = this.authService.getUserId();
    this.userService.getUserById(userId).subscribe({
      next: user => {
        this.user = user;
      },
      error: () => {
        this.notification.error("Could not load user data.");
        this.router.navigate(["/"]);
      }
    });

    this.emailForm = this.formBuilder.group({
      newEmailAddress: ["", [Validators.required, Validators.email, Validators.maxLength(255)]]
    })

    const passwordPattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{4,}$";
    this.passwordForm = this.formBuilder.group({
      oldPassword: ["", [Validators.required, Validators.pattern(passwordPattern), Validators.minLength(8), Validators.maxLength(128)]],
      newPassword: ["", [Validators.required, Validators.pattern(passwordPattern), Validators.minLength(8), Validators.maxLength(128)]],
      confirmedPassword: ["", [Validators.required, this.matchNewPassword.bind(this)]],
    })
    this.passwordForm.controls.newPassword.valueChanges.subscribe(() => {
      this.passwordForm.controls.confirmedPassword.updateValueAndValidity();
    });
  }

  matchNewPassword(control: AbstractControl): ValidationErrors | null {
    if (!this.passwordForm) return null;
    const newPassword = this.passwordForm.get('newPassword')?.value;
    const confirmedPassword = control.value;
    return newPassword === confirmedPassword ? null : {mismatch: true};
  }

  togglePasswordVisibility(field: string): void {
    if (field === 'oldPassword') {
      this.showOldPassword = !this.showOldPassword;
    } else if (field === 'newPassword') {
      this.showNewPassword = !this.showNewPassword;
    } else if (field === 'confirmedPassword') {
      this.showConfirmedPassword = !this.showConfirmedPassword;
    }
  }

  onEmailUpdate(): void {
    this.emailFormSubmitted = true;
    if (this.emailForm.valid) {
      this.emailToSentTokenTo = this.emailForm.get('newEmailAddress')?.value;
      this.userService.requestUserDataUpdate({
        requesterEmail: this.user.email,
        emailToSendTokenTo: this.emailToSentTokenTo
      }).subscribe({
        next: () => {
          const modalRef = this.modalService.open(TokenInputModalComponent, {
            centered: true,
          });
          modalRef.componentInstance.requestEmail = this.emailForm.get('newEmailAddress')?.value;
          modalRef.componentInstance.resendVerificationEmail.subscribe(() => {
            this.resendVerificationEmail();
          });
          modalRef.componentInstance.tokenProvided.subscribe((result: string) => {
            this.userService.updateUserEmail(this.user.id, {
              newEmail: this.emailForm.get('newEmailAddress')?.value,
              token: result
            }).subscribe({
              next: () => {
                this.notification.success("Email address updated successfully.");
                this.authService.logoutUser();
                modalRef.dismiss();
              },
              error: error => {
                this.resetEmailForm();
                if (error.status === HttpStatusCode.UnprocessableEntity) {
                  this.notification.error(this.errorFormatter.format(error), "Could not update email address.");
                } else {
                  this.notification.error("Could not update email address.");
                }
              }
            });
          });
        },
        error: error => {
          this.resetEmailForm();
          if (error.status === HttpStatusCode.NotFound) {
            this.notification.error("Could not update email address.");
          } else {
            this.notification.error(this.errorFormatter.format(error), "Could not update email address.");
          }
        }
      })
    }
  }

  onPasswordUpdate(): void {
    this.passwordFormSubmitted = true;
    if (this.passwordForm.valid) {
      this.emailToSentTokenTo = this.user.email;
      this.userService.requestUserDataUpdate({
        requesterEmail: this.user.email,
        emailToSendTokenTo: this.emailToSentTokenTo
      }).subscribe({
        next: () => {
          const modalRef = this.modalService.open(TokenInputModalComponent, {
            centered: true,
          });
          modalRef.componentInstance.requestEmail = this.user.email;
          modalRef.componentInstance.resendVerificationEmail.subscribe(() => {
            this.resendVerificationEmail();
          });
          modalRef.componentInstance.tokenProvided.subscribe((result: string) => {
            this.userService.updateUserPassword(this.user.id, {
              email: this.user.email,
              oldPassword: this.passwordForm.get('oldPassword')?.value,
              newPassword: this.passwordForm.get('newPassword')?.value,
              confirmedPassword: this.passwordForm.get('confirmedPassword')?.value,
              token: result
            }).subscribe({
              next: () => {
                this.notification.success("Password updated successfully.");
                this.authService.logoutUser();
              },
              error: error => {
                this.resetPasswordForm();
                if (error.status === HttpStatusCode.UnprocessableEntity) {
                  this.notification.error(this.errorFormatter.format(error), "Could not update password.");
                } else {
                  this.notification.error("Could not update password.");
                }
              }
            });
            modalRef.dismiss();
          });
        },
        error: () => {
          this.resetPasswordForm();
          this.notification.error("Could not update password.");
        }
      });
    }
  }

  onDeleteAccount(): void {
    if (this.authService.getUserRole() === 'ADMIN' && this.authService.getUserId() === this.user.id){
      this.notification.error("Admin cannot delete the own account.");
      return;
    }
    const modalRef = this.modalService.open(UserConfirmDeleteModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.accountEmail = this.user.email;
    modalRef.componentInstance.confirmed.subscribe({
      next: () => {
        this.userService.deleteUserById(this.user.id).subscribe({
          next: () => {
            this.notification.success("Account deleted successfully.");
            this.authService.logoutUser();
          },
          error: error => {
            this.notification.error("Could not delete account.");
          }
        })
        modalRef.dismiss();
      },
      error: () => {
        this.notification.error("Could not delete account.");
        modalRef.dismiss();
      }
    });
  }

  resendVerificationEmail() {
    this.userService.requestUserDataUpdate({
      requesterEmail: this.user.email,
      emailToSendTokenTo: this.emailToSentTokenTo
    }).subscribe({
      next: () => {
        this.notification.success("Verification email sent.")
      },
      error: () => {
        this.notification.error("Could not send verification email.")
      }
    })
  }


  resetEmailForm() {
    this.emailFormSubmitted = false;
    this.emailForm.reset();
  }

  resetPasswordForm() {
    this. passwordFormSubmitted = false;
    this.passwordForm.reset();
  }

}
