<div class="container">
  <div class="col-12 col-md-8 col-lg-6">
    <div class="form-wrapper shadow">
      <div class="d-flex justify-content-between">
        <h1 class="title">Create Location</h1>
        <div class="d-flex align-items-center mb-3">
          <i class="bi bi-arrow-left"></i>
          <a [routerLink]="['/manage']" class="ms-2 link-unstyled"><strong>Back</strong></a>
        </div>
      </div>
      <form class="form" [formGroup]="locationForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="description" class="form-label">Description</label>
          <input class="form-control"
                 id="description"
                 name="description"
                 formControlName="description"
                 [ngClass]="{
            'is-invalid': submitted && locationForm.controls.description.errors,
             'is-valid': submitted && !locationForm.controls.description.errors
             }">
          <div *ngIf="submitted && locationForm.controls.description.errors" class="invalid-feedback d-block">
            <small *ngIf="locationForm.controls.description.errors.required">Please enter Description</small>
            <small *ngIf="locationForm.controls.description.errors.maxlength">
              Description is too long, it must be shorter then 256
              characters!
            </small>
          </div>
        </div>

        <div class="form-group">
          <label for="street" class="form-label">Street</label>
          <input class="form-control"
                 id="street"
                 name="street"
                 formControlName="street"
                 [ngClass]="{
            'is-invalid': submitted && locationForm.controls.street.errors,
             'is-valid': submitted && !locationForm.controls.street.errors
             }">
          <div *ngIf="submitted && locationForm.controls.street.errors" class="invalid-feedback d-block">
            <small class="text-danger" *ngIf="locationForm.controls.street.errors.required">Please enter Street</small>
            <small
              *ngIf="locationForm.controls.street.errors.maxlength"
              class="form-text text-danger"
            >
              Street is too long, it must be shorter then 129
              characters!
            </small>
          </div>
        </div>

        <div class="form-group">
          <label for="zipCode" class="form-label">Postal Code</label>
          <input class="form-control"
                 id="zipCode"
                 name="zipCode"
                 formControlName="zipCode"
                 [ngClass]="{
            'is-invalid': submitted && locationForm.controls.zipCode.errors,
             'is-valid': submitted && !locationForm.controls.zipCode.errors
             }">
          <div *ngIf="submitted && locationForm.controls.zipCode.errors" class="invalid-feedback d-block">
            <small class="text-danger" *ngIf="locationForm.controls.zipCode.errors.required">Please enter Postal Code</small>
            <small
              *ngIf="locationForm.controls.zipCode.errors.maxlength"
              class="form-text text-danger"
            >
              Postal Code is too long, it must be shorter then 129
              characters!
            </small>
          </div>
        </div>

        <div class="form-group">
          <label for="city" class="form-label">City</label>
          <input class="form-control"
                 id="city"
                 name="city"
                 formControlName="city"
                 [ngClass]="{
            'is-invalid': submitted && locationForm.controls.city.errors,
             'is-valid': submitted && !locationForm.controls.city.errors
             }">
          <div *ngIf="submitted && locationForm.controls.city.errors" class="invalid-feedback d-block">
            <small class="text-danger" *ngIf="locationForm.controls.city.errors.required">Please enter City</small>
            <small
              *ngIf="locationForm.controls.city.errors.maxlength"
              class="form-text text-danger"
            >
              City is too long, it must be shorter then 129
              characters!
            </small>
          </div>
        </div>

        <div class="form-group">
          <label for="country" class="form-label">Country</label>
          <input
            #instance="ngbTypeahead"
            (click)="click$.next($any($event).target.value)"
            (focus)="focus$.next($any($event).target.value)"
            [ngbTypeahead]="search"
            class="form-control"
            id="country"
            formControlName="country"
            [ngClass]="{
            'is-invalid': submitted && locationForm.controls.country.errors,
             'is-valid': submitted && !locationForm.controls.country.errors
             }"
          />
          <div *ngIf="submitted && locationForm.controls.country.errors" class="invalid-feedback d-block">
            <small class="text-danger" *ngIf="locationForm.controls.country.errors.required">Please enter Country</small>
            <small
              *ngIf="locationForm.controls.country.errors.maxlength"
              class="form-text text-danger"
            >
              Country is too long, it must be shorter then 129
              characters!
            </small>
          </div>
        </div>




        <div class="form-actions">
          <button type="submit" class="btn btn-primary">Add Location</button>
        </div>
      </form>
    </div>
  </div>
</div>
