import { Component, OnInit, TemplateRef } from '@angular/core';
import { MessageService} from "../../../services/message.service";
import { Message} from "../../../dtos/message";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService} from "../../../services/auth.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ErrorFormatterService} from "../../../services/error-formatter.service";
import { HttpStatusCode } from "@angular/common/http";

@Component({
  selector: 'app-create-news',
  templateUrl: './create-news.component.html',
  styleUrls: ['./create-news.component.scss']
})
export class CreateNewsComponent {
  submitted = false;
  currentMessage: Message = new Message();  // Initialize currentMessage here
  totalItems = 0;
  currentPage = 1;
  itemsPerPage = 8;
  invalidFileType = false;
  private message: Message[];
  image: File | null = null;

  constructor(private messageService: MessageService,
              private authService: AuthService,
              private modalService: NgbModal,
              private router: Router,
              private notification: ToastrService,
              private errorFormatter: ErrorFormatterService) { }

  isAdmin(): boolean {
    return this.authService.getUserRole() === 'ADMIN';
  }

  openAddModal(messageAddModal: TemplateRef<any>) {
    this.currentMessage = new Message();  // Reinitialize currentMessage when opening the modal
    this.modalService.open(messageAddModal, {ariaLabelledBy: 'modal-basic-title'});
  }

  addMessage(form) {
    this.submitted = true;

    if (form.valid && !this.invalidFileType) {
      this.currentMessage.publishedAt = new Date().toISOString();
      this.createMessage(this.currentMessage);
      this.modalService.dismissAll()
    }
  }

  getMessage(): Message[] {
    return this.message;
  }

  onFileSelected(event: any, currentMessage: Message) {
    const file: File = event.target.files[0];
    if (file && file.type !== 'image/png') {
      this.invalidFileType = true;
      return;
    } else {
      this.invalidFileType = false;
    }
    this.image = file || null;
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      currentMessage.image = event.target.result.split(',')[1];
    });
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  private createMessage(message: Message) {
    this.messageService.createMessage(message).subscribe({
        next: () => {
          this.notification.success(
           ' Successfully created news: ' + message.title);
          this.router.navigate(['/message']);
        },
        error: error => {
          if (error.status === HttpStatusCode.UnprocessableEntity) {
            this.notification.error(this.errorFormatter.format(error), "Could not create message.")
          } else {
            this.notification.error("Please try again.", "Could not create message.");
          }
        }
      }
    );
  }
}
