<div [routerLink]="['/top-ten', topTen.id]" class="top-ten-item"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    [ngClass]="{'hovered-top-ten-item':isHovered}">
    <div class="fill-container" [style.width.px]="ticketPercentage()*500"
    [ngClass]="{'hovered-fill-container':isHovered}"></div>
    <div class="text-container">
        <span class="numberCircle"></span>
      <span class="number">{{rank}}</span>
    </div>
  <span class="name">{{topTen.name}}</span>
</div>
