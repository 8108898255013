

<app-back-arrow-button></app-back-arrow-button>
<section id="products" class="product-section">
    <div class="container">
        <h1 class="nameDetailProduct">{{ product.product_Name }}</h1>
        <div class="detailProduct">
            <div class="imageProduct">
                <div [ngClass]="{'out-of-stock': product.available_Quantity < 1}">
                    <img *ngIf="product.image_Url" [src]="product.image_Url" alt="Product Image" loading="lazy">
                </div>
            </div>
            <div class="product-item">
                <h3>{{ product.product_Description }}</h3>
                <h5>{{ product.product_Detail_Description }}</h5>
                <p class="price">{{ product.cost | currency }}</p>
                <div class="quantity-selector">
                    <button class="quantity-btn" (click)="decreaseQuantity()">-</button>
                    <input type="number" [(ngModel)]="quantity" min="1">
                    <button class="quantity-btn" (click)="increaseQuantity()">+</button>
                </div>
                <button class="button-style" (click)="addProductToCart(product, quantity)">Add to Cart</button>
            </div>
        </div>
    </div>
</section>
