import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {debounceTime, Subject, switchMap} from "rxjs";
import {PerformerGroupListDto} from "../../../../dtos/performerGroup";
import {PerformerGroupService} from "../../../../services/performer-group.service";

@Component({
  selector: 'app-performance-group-search',
  standalone: true,
  imports: [
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './performance-group-search.component.html',
  styleUrl: './performance-group-search.component.scss'
})
export class PerformanceGroupSearchComponent implements OnInit{

  @Input() disable = false;
  @Output() selectedPerformanceGroup = new EventEmitter<PerformerGroupListDto>();
  _searchParam  = '';
  performerGroups: PerformerGroupListDto[] = [];
  search$ = new Subject<void>();

  constructor(private performerGroupService: PerformerGroupService) {
  }

  ngOnInit(): void {
    this.search$.pipe(
      debounceTime(200),
      switchMap(() => this.performerGroupService.search(this._searchParam))
    ).subscribe((performerGroups) => {
      this.performerGroups = performerGroups;
    });
    this.search$.next();
  }

  get searchParam() {
    return this._searchParam;
  }

  set searchParam(searchParam) {
    this._searchParam = searchParam
    this.search$.next();
  }

  setPerformerGroupSelection(performerGroup: PerformerGroupListDto) {
    this.selectedPerformanceGroup.emit(performerGroup);
  }

  isMaxLengthExceeded(input: string, maxLength: number): boolean {
    return input ? input.length >= maxLength : false;
  }

}
