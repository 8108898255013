import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input, OnInit,
  Output,
} from "@angular/core";
import {
  loadStripe, PaymentIntent,
  Stripe,
  StripeCardElement,
  StripeElements,
} from "@stripe/stripe-js";
import {PaymentIntentRequestDto} from "../../../dtos/payment";
import {catchError, lastValueFrom} from "rxjs";
import {PaymentService} from "../../../services/payment.service";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-payment-modal",
  templateUrl: "./payment-modal.component.html",
  styleUrl: "./payment-modal.component.scss",
})
export class PaymentModalComponent implements OnInit {
  @Input() amount: number;
  @Output() paymentStatus = new EventEmitter<any>();
  stripePromise: Promise<Stripe | null>;
  stripe: Stripe;
  elements: StripeElements;
  card: StripeCardElement;
  cancelButtonDisabled: boolean = false;
  isCardValid: boolean = false;
  private testApiKey =
    "pk_test_51PIBzfP4ts0USGe2rTPcP7dXIgDQ51HGShQGuZ82Q4LxCC4wl211ly8DsSBfy0pvHFCLkgVrIzg5Z7lOxIyHg4gV00vSb42EUR";

  constructor(
    private paymentService: PaymentService,
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
    this.stripePromise = loadStripe(this.testApiKey);
    this.initializeStripe();
  }

  async confirmPayment() {
    try {
      const clientSecret = await this.createPaymentIntent(this.amount);
      const { paymentIntent, error } = await this.stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: this.card,
        },
      });

      if (error) {
        this.paymentStatus.emit({ type: 'error', data: error });
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        this.paymentStatus.emit({type: 'success', data: paymentIntent});
        this.activeModal.close();
      } else {
        this.paymentStatus.emit({ type: 'unknownError', data: 'unknown Error' });
      }
    } catch (error) {
      this.paymentStatus.emit({ type: 'unknownError', data: 'unknown Error' });
    }
    this.cancelButtonDisabled = false;
  }

  dismissModal() {
    this.paymentStatus.emit({ type: 'cancelled', data: 'payment cancelled' });
    this.activeModal.close();
  }

  private async initializeStripe(): Promise<void> {
    this.stripe = await this.stripePromise;
    this.elements = this.stripe.elements({locale: "en"}); // Set locale here

    const cardOptions = {
      hidePostalCode: true,
    };

    this.card = this.elements.create("card", cardOptions);
    this.card.mount("#card-element");

    this.card.on("change", (event) => {
      this.isCardValid = event.complete && event.error == null;
    });
  }

  private async createPaymentIntent(amount: number): Promise<string> {
    const paymentIntentRequest: PaymentIntentRequestDto = {
      amount: amount,
    };
    try {
      const response = await lastValueFrom(
        this.paymentService.createPaymentIntent(paymentIntentRequest).pipe(
          catchError((error) => {
            throw error;
          }),
        ),
      );
      return response.clientSecret;
    } catch (error) {
      throw error;
    }
  }

  cartTotal(): number {
    return this.amount / 100;
  }
}
