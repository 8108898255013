import { Component } from "@angular/core";
import { ToastrService } from "ngx-toastr";

import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { ErrorFormatterService } from "../../services/error-formatter.service";
import { HttpStatusCode } from "@angular/common/http";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent {
  registerFormUser: UntypedFormGroup;
  showNewPassword: boolean = false;
  showConfirmedPassword: boolean = false;
  submitted = false;
  specialCharacters = "#?!@$%^&*-";

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private router: Router,
    private errorFormatter: ErrorFormatterService,
    private notification: ToastrService,
  ) {
    const passwordPattern = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{4,}$";
    this.registerFormUser = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.minLength(1),
          Validators.maxLength(256),
        ],
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(passwordPattern),
          Validators.minLength(8),
          Validators.maxLength(128),
        ],
      ],
      passwordRepeat: [
        "",
        [Validators.required, this.matchControl("password")],
      ],
    });
    this.registerFormUser.controls.password.valueChanges.subscribe(() => {
      this.registerFormUser.controls.passwordRepeat.updateValueAndValidity();
    });
  }
  matchControl(matchingControlName: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if (!this.registerFormUser || !this.registerFormUser.controls || !control || !this.registerFormUser.controls[matchingControlName]) {
        return null;
      }

      if (control.value === this.registerFormUser.controls[matchingControlName].value) {
        return null;
      }

      // Validation failed, return an error object
      return {match: true};
    };
  }


  /**
   * Form validation will start after the method is called   */
  registerUser() {
    this.submitted = true;
    if (this.registerFormUser.valid) {
      this.userService.register(this.registerFormUser.value).subscribe({
        next: () => {
          this.notification.success(
            `User ${this.registerFormUser.controls.email.value} successfully registered.`,
            "Registration successful!",
          );
          this.router.navigate(["/login"]);
        },
        error: (error) => {
          this.resetRegisterForm();
          if (error.status === HttpStatusCode.Conflict) {
            this.notification.error(
              this.errorFormatter.format(error),
              `Could not register the user ${this.registerFormUser.controls.email.value}.`,
            );
          } else {
            this.notification.error(
              "Please try again.",
              `Could not register the user ${this.registerFormUser.controls.email.value}.`,
            );
          }
        },
      });
    }
  }

  togglePasswordVisibility(field: string): void {
    if (field === 'newPassword') {
      this.showNewPassword = !this.showNewPassword;
    } else if (field === 'confirmedPassword') {
      this.showConfirmedPassword = !this.showConfirmedPassword;
    }
  }

  resetRegisterForm() {
    this.submitted = false;
    this.registerFormUser.reset();
  }
}
