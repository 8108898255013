import { Component } from '@angular/core';
import {FormBuilder, FormControlOptions, FormGroup, Validators} from '@angular/forms';
import { PerformanceCreateDto } from '../../../dtos/performance';
import { LocationListDto } from '../../../dtos/location';
import { EventListDto} from '../../../dtos/event';
import { ArtistListDto } from '../../../dtos/artist';
import { PerformerGroupListDto } from '../../../dtos/performerGroup';
import { PerformanceService } from '../../../services/performance.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {ItemDto} from "../../../dtos/items";
import { ErrorFormatterService } from 'src/app/services/error-formatter.service';

@Component({
  selector: 'app-create-performance',
  templateUrl: './create-performance.component.html',
  styleUrls: ['./create-performance.component.scss']
})
export class CreatePerformanceComponent  {
  createPerformanceForm: FormGroup;

  selectedArtists = new Map<number, ItemDto<ArtistListDto>>();
  selectedEvents= new Map<number, ItemDto<EventListDto>>();
  selectedLocation = new Map<number, ItemDto<LocationListDto>>();
  selectedPerformerGroups = new Map<number, ItemDto<PerformerGroupListDto>>();
  submitted = false;

  disableEvents = false;
  disableLocation = false;
  disablePerformer = false;

  constructor(
    private fb: FormBuilder,
    private performanceService: PerformanceService,
    private errorFormatter: ErrorFormatterService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.createPerformanceForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(255)]],
      dateTime: ['', [Validators.required, this.dateNotInPastValidator()]],
      durationInSeconds: ['', [Validators.required, Validators.min(0)]],
      locationId: ['', Validators.required],
      eventId: ['', Validators.required],
      performerId: ['', Validators.required],
      } as FormControlOptions);
  }


  setArtistSelection(artist: ArtistListDto) {
    let artistItem: ItemDto<ArtistListDto> = {
      item: artist,
      toString: `${artist.artistName} (${artist.firstName} ${artist.lastName}`
    }
    this.selectedArtists.set(artist.id, artistItem);
    this.disablePerformer = true;
    this.createPerformanceForm.patchValue({ performerId: artist.id});
  }

  setPerformerGroupSelection(performerGroup: PerformerGroupListDto) {
    let performerGroupItem: ItemDto<PerformerGroupListDto> = {
      item: performerGroup,
      toString: `${performerGroup.name} ${performerGroup.performerType}`
    }
    this.selectedPerformerGroups.set(performerGroup.id, performerGroupItem);
    this.disablePerformer = true;
    this.createPerformanceForm.patchValue({ performerId: performerGroup.id});
  }

  setEventSelection(event: EventListDto): void {
    let eventItem: ItemDto<EventListDto> = {
      item: event,
      toString: `${event.name} ${event.type}`
    }
    this.selectedEvents.set(event.id, eventItem);
    this.disableEvents = true;
    this.createPerformanceForm.patchValue({eventId: event.id})
  }

  eventRemoved() {
    this.disableEvents = false;
    this.createPerformanceForm.patchValue({eventId: ''})
  }

  artistRemoved() {
    const ids = Array.from(this.selectedArtists.keys())
    this.createPerformanceForm.patchValue({performerId: ids})
    this.disablePerformer = false;
  }

  performerGroupsRemoved(){
    const ids = Array.from(this.selectedPerformerGroups.keys())
    this.createPerformanceForm.patchValue({performerId: ids})
    this.disablePerformer = false;
  }

  setLocationSelection(location: LocationListDto): void {
    let locationItem: ItemDto<LocationListDto> = {
      item: location,
      toString: `${location.description} ${location.address.street} ${location.address.city} ${location.address.country}`
    }
    this.selectedLocation.set(location.id, locationItem);
    this.disableLocation = true;
    this.createPerformanceForm.patchValue({locationId: location.id})
  }

  locationRemoved(){
    this.disableLocation = false;
    this.createPerformanceForm.patchValue({locationId: ''})
  }


  dateNotInPastValidator() {
    return (control) => {
      const inputDate = new Date(control.value);
      const now = new Date();
      return inputDate < now ? { dateNotInPast: true } : null;
    };
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.createPerformanceForm.valid) {
      const performanceData: PerformanceCreateDto = this.createPerformanceForm.value;
      this.performanceService.createPerformance(performanceData).subscribe(
        () => {
          this.toastr.success('Performance created successfully');
          this.router.navigate(['/performances']);
        },
        (error) => {
          this.toastr.error(this.errorFormatter.format(error),
          `Failed to create performance.`,
        );
        }
      );
    }
  }

  showContent: string | null = "event";
  selectedButton: string | null = "event";


  changeContent(current: string): void {
    this.showContent = current;
    this.selectedButton = current;
  }

}
