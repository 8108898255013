<div class="p-2">
  <div class="d-flex flex-row justify-content-end">
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="d-flex flex-row modal-header justify-content-center p-2">
    <h5 class="modal-title" id="confirm-reset-dialog-title">Reset User's Password</h5>
  </div>
  <div class="modal-body">
    <p class="text-center">
      Do you really want to reset the password to the account with email <strong>{{ accountEmail }}</strong>?
    </p>
    <p class="text-center">
      The user will be delivered a password via email.
    </p>
  </div>
  <div class="modal-footer d-flex flex-column align-items-center p-2">
    <button type="submit" class="col btn btn-secondary w-75 my-3" (click)="confirmed.emit()">
      <i class="bi bi-key"></i> Reset Password
    </button>
  </div>
</div>

