export class Message {
  id: number;
  title: string;
  summary: string;
  text: string;
  publishedAt: string;
  image: string;
}

export interface MessageListDto {
  id: number;
  title: string;
  summary: string;
  publishedAt: string;
  image: string;
}
