<div class="container">
  <div class="row">
    <div class="col-md-4 mx-auto">
      <span class="anchor" id="formRegister"></span>

      <div class="card rounded-0 change-background-to-pink border-0">
        <div class="card-header align-content-center change-background-to-pink">
          <i class="bi bi-person" style="font-size: 6rem; line-height: 5rem">
          </i>
          <h3 class="mb-0">Create your account!</h3>
        </div>

        <div class="card-body" style="border: none; margin-top: 0px">
          <form
            (ngSubmit)="registerUser()"
            [formGroup]="registerFormUser"
            class="form"
          >
            <div class="form-performerGroup input-form">
              <label for="inputUsername">Email</label>
              <input
                aria-describedby="usernameHelp"
                class="form-control"
                formControlName="email"
                id="inputUsername"
                name="email"
                placeholder="Type your Email"
                type="text"
                maxlength="140"
                [ngClass]="{
                           'is-invalid': submitted && registerFormUser.controls.email.errors,
                            'is-valid': submitted && !registerFormUser.controls.email.errors
                            }"
              />
              <div *ngIf="submitted && registerFormUser.controls.email.errors" class="invalid-feedback d-block">
                <small *ngIf="registerFormUser.controls.email.errors?.required">Your email is required!</small>
                <small *ngIf="registerFormUser.controls.email.errors?.email">Your email has the wrong format!</small>
                <small *ngIf="registerFormUser.controls.email.errors?.maxlength">Your email is too long! </small>
              </div>
            </div>
            <hr class="divider" style="margin-bottom: 15px"/>
            <div class="form-performerGroup input-form" style="margin-top: 0">
              <label for="password">Password</label>
              <div class="input-group">
                <input
                  aria-describedby="passwordHelp"
                  class="form-control"
                  formControlName="password"
                  id="password"
                  name="password"
                  placeholder="Type your password"
                  type="password"
                  maxlength="140"
                  [ngClass]="{
                           'is-invalid': submitted && registerFormUser.controls.password.errors,
                            'is-valid': submitted && !registerFormUser.controls.password.errors
                            }"
                  [type]="showNewPassword ? 'text' : 'password'"
                />
                <button class="btn btn-secondary" type="button" id="button-see-new-password"
                        (click)="togglePasswordVisibility('newPassword')">
                  <i class="bi" [ngClass]="showNewPassword ? 'bi-eye-slash' : 'bi-eye'"></i>
                </button>
              </div>
              <div *ngIf="submitted && registerFormUser.controls.password.errors" class="invalid-feedback d-block">
                <small *ngIf="registerFormUser.controls.password.errors.required">New password is required!</small>
                <div *ngIf="registerFormUser.controls.password.errors.pattern">
                  <small> Use at least one
                    uppercase letter, one lowercase letter, one digit, and one of the
                    following special characters: {{ specialCharacters }}
                  </small>
                </div>
                <small
                  *ngIf="registerFormUser.controls.password.errors.minlength && !registerFormUser.controls.password.errors?.pattern">Your
                  password must be at least 8
                  characters long!</small>
                <small
                  *ngIf="registerFormUser.controls.password.errors.maxlength && !registerFormUser.controls.password.errors?.pattern">Your
                  password is too long! </small>
              </div>
            </div>
            <div class="form-performerGroup input-form">
              <label for="passwordRepeat">Confirm Password</label>
              <div class="input-group">
                <input
                  aria-describedby="passwordRepeatHelp"
                  class="form-control"
                  formControlName="passwordRepeat"
                  id="passwordRepeat"
                  name="passwordRepeat"
                  placeholder="Confirm your Password"
                  maxlength="140"
                  [ngClass]="{
                           'is-invalid': submitted && registerFormUser.controls.passwordRepeat.errors,
                            'is-valid': submitted && !registerFormUser.controls.passwordRepeat.errors
                            }"
                  [type]="showConfirmedPassword ? 'text' : 'password'"
                />
                <button class="btn btn-secondary" type="button" id="button-see-confirmed-password"
                        (click)="togglePasswordVisibility('confirmedPassword')">
                  <i class="bi" [ngClass]="showConfirmedPassword ? 'bi-eye-slash' : 'bi-eye'"></i>
                </button>
              </div>
              <div *ngIf="submitted && registerFormUser.controls.passwordRepeat.errors" class="invalid-feedback d-block">
                <small *ngIf="registerFormUser.controls.passwordRepeat.errors.required">Repeat your new password!</small>
                <small *ngIf="registerFormUser.controls.passwordRepeat.errors.match">Passwords do not match!</small>
              </div>
            </div>
            <div class="center-button">
              <button
                (click)="registerUser()"
                class="btn btn-primary float-right lilac-button"
                type="button"
              >
                Create Account
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
