import { Injectable } from '@angular/core';
import {InvoiceDetail, InvoiceType} from "../dtos/invoice";
import jsPDFInvoiceTemplate, {OutputType} from "jspdf-invoice-template";

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  createInvoice(invoice: InvoiceDetail) {

    const formatDeliveryDate = (date: Date) => {
      const result = new Date(date);
      result.setDate(result.getDate() + 5);
      return result;
    };
    const formatCurrency = (amount: number, negative: boolean) => {

      return negative ? (-amount / 100).toFixed(2) + ' €' : (amount / 100).toFixed(2) + ' €';
    };
    const formatInvoiceType = (refund: boolean) => {
      return refund ? 'Refund invoice ': 'Invoice '
    };

    const props: Parameters<typeof jsPDFInvoiceTemplate>[0] = {
      outputType: OutputType.Save, // autosaves the invoice
      returnJsPDFDocObject: true,
      fileName: formatInvoiceType(invoice.invoiceType == InvoiceType.CANCEL) + invoice.id,
      orientationLandscape: false,
      compress: true,
      business: {
        name: 'Ticket Line 4.0',
        address: 'Austria, Fernbach 4490, Waldstrasse 6',
        phone: '(+43) 3360 131 54 33',
        email: 'ticketline@example.com',
        email_1: 'UID: ATU01053041',
        website: 'www.ticketline.al',
      },
      contact: {
        label: formatInvoiceType(invoice.invoiceType == InvoiceType.CANCEL) + 'issued for:',
        name: invoice.customer.name + ' ' + invoice.customer.lastname,
        address: invoice.customer.address,
        email: invoice.customer.email,
        phone: invoice.customer.phoneNumber
      },
      invoice: {
        label: formatInvoiceType(invoice.invoiceType == InvoiceType.CANCEL) + '#: ',
        num: invoice.id,
        invGenDate: formatInvoiceType(invoice.invoiceType == InvoiceType.CANCEL) + 'date: ' + new Date(invoice.invoiceDate).toLocaleDateString(),
        invDate: 'Delivery date: ' + formatDeliveryDate(invoice.invoiceDate).toLocaleDateString(),
        headerBorder: false,
        tableBodyBorder: false,
        header: [
          {
            title: '#',
            style: {
              width: 10,
            },
          },
          {
            title: 'Title',
            style: {
              width: 19,
            },
          },
          {
            title: 'Description',
            style: {
              width: 80,
            },
          },
          {
            title: 'Price*',
            style: {
              width: 35,
            },
          },
          {
            title: 'Qty',
            style: {
              width: 20,
            },
          },
          {
            title: 'Sum',
            style: {
              width: 50,
            },
          },
        ],
        table: invoice.items.map((item, index) => [
          index + 1,
          item.title,
          item.description,
          formatCurrency(item.price, false),
          item.quantity,
          formatCurrency(item.price * item.quantity, invoice.invoiceType === InvoiceType.CANCEL)
        ]),
        additionalRows: [
          {
            col1: 'Price Total (ex. VAT):',
            col2: formatCurrency(this.getNettoTotal(invoice), invoice.invoiceType === InvoiceType.CANCEL),
          },
          {
            col1: 'VAT ('+invoice.items[0].vat+' %):',
            col2: formatCurrency(this.getVATval(invoice), invoice.invoiceType === InvoiceType.CANCEL),
            style: {
              fontSize: 10,
            },
          },
          {
            col1: invoice.invoiceType == 'NORMAL' ? 'Price Total:' : 'Refund Total:',
            col2: formatCurrency(this.getInvoiceTotal(invoice), invoice.invoiceType === InvoiceType.CANCEL),
            style: {
              fontSize: 14,
            },
          },
        ] as any,
        invDesc:
          invoice.invoiceType == 'NORMAL'
            ? 'Payment method: Credit card'
            : '',
      },
      footer: {
        text: '*Prices are displayed with VAT inclusive. \n The invoice is created on a computer and is valid without a signature and stamp.',
      },
      pageEnable: true,
      pageLabel: 'Page ',
    };
    jsPDFInvoiceTemplate(props); //returns number of pages created
  }

  getNettoTotal(invoice: InvoiceDetail) {
    return invoice.items.reduce((prev, curr) => prev + (curr.price / (1 + curr.vat / 100)) * curr.quantity, 0);
  }

  getVATval(invoice: InvoiceDetail) {
    return this.getInvoiceTotal(invoice) - this.getNettoTotal(invoice);
  }

  getInvoiceTotal(invoice: InvoiceDetail) {
    return invoice.items.reduce((prev, curr) => prev + curr.price * curr.quantity, 0);
  }
}
