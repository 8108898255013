<div class="container">
  <div class="col-12 col-md-8 col-lg-6">
    <div class="form-wrapper shadow">
      <div class="d-flex justify-content-between">
        <h1 class="title">Create News</h1>
        <div class="d-flex align-items-center mb-3">
          <i class="bi bi-arrow-left"></i>
          <a [routerLink]="['/manage']" class="ms-2 link-unstyled"><strong>Back</strong></a>
        </div>
      </div>
      <form class="form" #messageForm="ngForm" (ngSubmit)="addMessage(messageForm)">
        <div class="form-group">
          <label for="title" class="form-label">Title</label>
          <input class="form-control" 
          id="title" 
          name="title" 
          [(ngModel)]="currentMessage.title" 
          [ngClass]="{
            'is-invalid': submitted && messageForm.controls.title.errors,
             'is-valid': submitted && !messageForm.controls.title.errors
             }"
          required>
          <div *ngIf="submitted && messageForm.controls.title.errors" class="invalid-feedback d-block">
            <small *ngIf="messageForm.controls.title.errors.required">Please enter News Title</small>
          </div>
        </div>

        <div class="form-group">
          <label for="summary" class="form-label">Summary</label>
          <textarea class="form-control" 
          id="summary" 
          name="summary" 
          [(ngModel)]="currentMessage.summary" 
          [ngClass]="{
            'is-invalid': submitted && messageForm.controls.summary.errors,
             'is-valid': submitted && !messageForm.controls.summary.errors
             }"
          required></textarea>
          <div *ngIf="submitted && messageForm.controls.summary.errors" class="invalid-feedback d-block">
            <small *ngIf="messageForm.controls.summary.errors.required">Please enter News Summary</small>
          </div>
        </div>

        <div class="form-group">
          <label for="text" class="form-label">Text</label>
          <textarea class="form-control" 
          id="text" 
          name="text" 
          [(ngModel)]="currentMessage.text" 
          [ngClass]="{
            'is-invalid': submitted && messageForm.controls.text.errors,
             'is-valid': submitted && !messageForm.controls.text.errors
             }"
          required></textarea>
          <div *ngIf="submitted && messageForm.controls.text.errors" class="invalid-feedback d-block">
            <small *ngIf="messageForm.controls.text.errors.required">Please enter News Text</small>
          </div>
        </div>

        <div class="form-group">
          <label for="image" class="form-label">Upload Image (optional)</label>
          <input class="form-control" 
          id="image" 
          name="image" 
          type="file" 
          (change)="onFileSelected($event, currentMessage)" accept="image/png">
          <div *ngIf="invalidFileType" class="error">
            <small>Only PNG images are allowed!</small>
          </div>
        </div>

        <div class="form-actions">
          <button type="submit" class="btn btn-primary">Add News</button>
        </div>
      </form>
    </div>
  </div>
</div>
