import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {debounceTime, Subject} from "rxjs";
import {ErrorFormatterService} from "../../../services/error-formatter.service";
import {ToastrService} from "ngx-toastr";
import {UserService} from "../../../services/user.service";
import {UserListDto, UserSearchDto} from "../../../dtos/user";
import {HttpStatusCode} from "@angular/common/http";
import {AuthService} from "../../../services/auth.service";
import {
  UserConfirmDeleteModalComponent
} from "../../profile/edit-profile/user-confirm-delete-modal/user-confirm-delete-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  ConfirmUserPasswordResetModalComponent
} from "./confirm-user-passsword-reset-modal/confirm-user-password-reset-modal.component";

@Component({
  selector: "app-manage",
  templateUrl: "./manage-user.component.html",
  styleUrls: ["./manage-user.component.scss"],
})
export class ManageUserComponent implements OnInit {
  users: UserListDto[] = [];
  totalItems = 0;
  currentPage = 1;
  itemsPerPage = 16;
  searchParam: UserSearchDto = {
    pageNumber: this.currentPage,
    pageSize: this.itemsPerPage,
  };
  animate = false;
  searchChangedObservable = new Subject<void>();
  @ViewChild("container") container: ElementRef;

  constructor(
    private userService: UserService,
    private errorFormatter: ErrorFormatterService,
    private notification: ToastrService,
    private authService: AuthService,
    private modalService: NgbModal
    ) {
  }

  ngOnInit(): void {
    this.getTotalItems();
    this.reloadUsers();
    this.animate = false;
    this.searchChangedObservable.pipe(debounceTime(250)).subscribe({
      next: () => {
        this.searchParam.pageNumber = 1;
        this.getTotalItems();
        this.reloadUsers();
      },
    });
  }

  searchChanged(): void {
    this.animate = true;
    this.searchChangedObservable.next();
    this.currentPage = 1;
  }

  getTotalItems() {
    this.userService.getSearchAmount(this.searchParam).subscribe({
      next: (data) => {
        this.totalItems = data;
      },
      error: () => {
        this.notification.error(
          "Please try again.",
          "Could not fetch user data.",
        );
      },
    });
  }

  reloadUsers() {
    if (
      this.searchParam.searchTerm == null ||
      this.searchParam.searchTerm === ""
    ) {
      delete this.searchParam.searchTerm;
    }
    if (this.searchParam.status == null || this.searchParam.status === "all") {
      delete this.searchParam.status;
      this.fetchUser();
      this.searchParam.status = "all";
      return;
    }
    this.fetchUser();
  }

  fetchUser() {
    this.userService.search(this.searchParam).subscribe({
      next: (data) => {
        this.users = data;
      },
      error: () => {
        this.notification.error(
          "Please try again.",
          "Could not fetch user data.",
        );
      },
    });
  }

  clearSearch() {
    delete this.searchParam.searchTerm;
    this.getTotalItems();
    this.reloadUsers();
  }

  unlockUser(id: number) {
    let locked = false;
    this.userService.lockUser(id, locked).subscribe({
      next: () => {
        this.reloadUsers();
      },
      error: (error) => {
        if (error.status === HttpStatusCode.NotFound) {
          this.notification.error(
            this.errorFormatter.format(error, "User was not found."),
            "Could not unlock user.",
          );
        } else {
          this.notification.error(
            "Please try again.",
            "Could not unlock user.",
          );
        }
      },
    })
  }

  lockUser(id: number) {
    let locked = true;
    this.userService.lockUser(id, locked).subscribe({
      next: () => {
        this.reloadUsers();
      },
      error: (error) => {
        if (error.status === HttpStatusCode.NotFound) {
          this.notification.error(
            this.errorFormatter.format(error, "User was not found."),
            "Could not lock user.",
          );
        } else {
          this.notification.error(
            "Please try again.",
            "Could not lock user.",
          );
        }
      },
    })
  }

  onPageChange(pageNumber: number): void {
    this.searchParam.pageNumber = pageNumber;
    this.currentPage = pageNumber;
    this.fetchUser();
    this.container.nativeElement.scrollTo({top: 0, behavior: "smooth"});
  }

  onDeleteUser(user: UserListDto) {
    if (this.authService.getUserId() === user.id) {
      this.notification.error("Admin cannot delete the own account.");
    } else {
      const modalRef = this.modalService.open(UserConfirmDeleteModalComponent, {
        centered: true,
      });
      modalRef.componentInstance.accountEmail = user.email;
      modalRef.componentInstance.confirmed.subscribe({
        next: () => {
          this.userService.deleteUserById(user.id).subscribe({
            next: () => {
              this.notification.success("Account deleted successfully.");
              this.reloadUsers();
            },
            error: () => {
              this.notification.error("Could not delete account.");
            }
          });
          modalRef.dismiss();
        },
        error: () => {
          this.notification.error("Could not delete account.");
          modalRef.dismiss();
        }
      });
    }
  }

  onResetPassword(user: UserListDto) {
    if (this.authService.getUserId() === user.id) {
      this.notification.error("As an admin, change your password in the account settings.");
    } else {
      const modalRef = this.modalService.open(ConfirmUserPasswordResetModalComponent, {
        centered: true,
      });
      modalRef.componentInstance.accountEmail = user.email;
      modalRef.componentInstance.confirmed.subscribe({
        next: () => {
          this.userService.resetUserPassword(user.id).subscribe({
            next: () => {
              this.notification.success("Password retested successfully.");
            },
            error: () => {
              this.notification.error("Could not reset password.");
            }
          });
          modalRef.dismiss();
        },
        error: () => {
          this.notification.error("Could not reset password.");
          modalRef.dismiss();
        }
      });
    }
  }
}
