import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BeeServiceService } from 'src/app/services/bee-service.service';

@Component({
  selector: 'app-order-detail-view',
  templateUrl: './order-detailview.component.html',

  styleUrls: ['./order-detailview.component.scss']
})
export class OrderDetailViewComponent implements OnInit {
  orderId: number;
  orderDetails: any; // Use appropriate type
  groupedBeeProducts: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private beeService: BeeServiceService
  ) { }

  ngOnInit() {
    console.log('OrderDetailViewComponent initialized');
    this.orderId = +this.route.snapshot.paramMap.get('id');
    this.loadOrderDetails(this.orderId);
  }

  loadOrderDetails(orderId: number) {
    console.log('Loading order details for order ID', orderId);
    // Use your service to load order details by ID
    this.beeService.getOrderById(orderId).subscribe({
      next: (details) => {
        this.orderDetails = details;
        this.groupBeeProducts();
      },
      error: (err) => console.error('Failed to load order details', err)
    });
  }


  groupBeeProducts() {
    const productMap = new Map();
    this.orderDetails.beeProducts.forEach(product => {
      const key = product.product_Name + product.product_Description;
      if (productMap.has(key)) {
        productMap.get(key).count++;
        productMap.get(key).totalCost += product.cost;
      } else {
        productMap.set(key, { ...product, count: 1, totalCost: product.cost });
      }
    });
    this.groupedBeeProducts = Array.from(productMap.values());
  }




  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${hours}:${minutes}   ${day}.${month}.${year}`;
  }
}















/*
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { NgClass, NgForOf, NgIf } from "@angular/common";
import { OrderService } from "../../../../services/order.service";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import { animate, style, transition, trigger } from "@angular/animations";
import { TicketListDto } from "../../../../dtos/ticket";
import { OrderType } from "../../../../dtos/orderType";
import { OrderDetailDto } from "../../../../dtos/order";
import { PurchaseRequestDataDto } from "../../../../dtos/payment";
import { TicketItemComponent } from "../ticket-item/ticket-item.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ErrorFormatterService } from "../../../../services/error-formatter.service";
import { ToastrService } from "ngx-toastr";

export enum Status {
  IMMUTABLE_VIEW = "View",
  PURCHASE_VIEW = "Buy",
  REFUNDABLE_VIEW = "Refund",
  CANCELLATION_VIEW = "Cancel",
}

export const ReverseStatus: Record<string, Status> = {
  view: Status.IMMUTABLE_VIEW,
  buy: Status.PURCHASE_VIEW,
  refund: Status.REFUNDABLE_VIEW,
  cancel: Status.CANCELLATION_VIEW,
};

@Component({
  selector: "app-order-detailview",
  standalone: true,
  imports: [NgForOf, NgClass, NgIf, TicketItemComponent, RouterLink],
  templateUrl: "./order-detailview.component.html",
  styleUrl: "./order-detailview.component.scss",
  animations: [
    trigger("fadeIn", [
      transition(":enter", [
        style({ transform: "scale(0)" }),
        animate("600ms {{delay}}ms ease-out", style({ transform: "scale(1)" })),
      ]),
    ]),
  ],
})
export class OrderDetailviewComponent implements OnInit {
  status: Status = Status.IMMUTABLE_VIEW;
  orderID: number = -1;
  tickets: TicketListDto[] = [];
  selected: Set<number> = new Set<number>();
  orderDetails: OrderDetailDto;
  @ViewChild("modal") modal: TemplateRef<any>;

  protected readonly Status = Status;

  constructor(
    private service: OrderService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private errorFormatter: ErrorFormatterService,
    private notification: ToastrService,
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.orderID = params["id"];
      this.status = ReverseStatus[params["status"]];
    });

    this.service.getOrderDetailById(this.orderID).subscribe((order) => {
      this.tickets = order.tickets;
      this.orderDetails = order;

      if (this.status === Status.IMMUTABLE_VIEW) {
        this.selectAll();
      }
    });
  }

  getTicketIds(): number[] {
    return this.tickets
      .filter((ticket) => this.selected[ticket.id])
      .map((ticket) => ticket.id);
  }

  getAmountOfValidTickets(): number {
    return this.tickets.filter((ticket) => this.ticketValid(ticket)).length;
  }

  cancel() {
    this.service.cancelReservation(this.orderID, this.getTicketIds()).subscribe({
        next: () => {
          this.router.navigate(["/profile/order/overview"]).then(() => {});
        },
        error: (error) =>
          this.notification.error(
            this.errorFormatter.format(error),
            "Could not cancel reservation(s).",
          ),
      }
    );
  }

  buy() {
    const purchaseData: PurchaseRequestDataDto = {
      orderId: this.orderID,
      ticketIds: this.getTicketIds(),
    };
    this.router.navigate([
      "/cart",
      {purchaseData: JSON.stringify(purchaseData)},
    ]).then(() => {
    });
  }

  refund() {
    this.service.refundTickets(this.orderID, this.getTicketIds()).subscribe(
      {
        next: () => {
          this.router.navigate(["/profile/order/overview"]).then(() => {
          });
        },
        error: (error) => {
          this.notification.error(this.errorFormatter.format(error), "Could not refund ticket(s).");
        }
      });
  }

  submit() {
    this.modalService.open(this.modal, {centered: true});
  }

  toggleTicketSelection(tickedId: number) {
    if (Status.IMMUTABLE_VIEW === this.status) {
      return;
    }
    this.selected[tickedId] = !this.selected[tickedId];
  }

  selectAll() {
    this.tickets.forEach((ticket: any) => {
      if (this.ticketValid(ticket)) {
        this.selected[ticket.id] = true;
      }
    });
  }

  selectNone() {
    this.tickets.forEach((ticket: any) => {
      if (this.ticketValid(ticket)) {
        this.selected[ticket.id] = false;
      }
    });
  }

  ticketValid(ticket: TicketListDto) {
    return (
      ticket.orderType === OrderType.PURCHASED ||
      ticket.orderType === OrderType.RESERVATION
    );
  }

  getTitle() {
    switch (this.status) {
      case Status.PURCHASE_VIEW:
        return "Reservations";
      case Status.REFUNDABLE_VIEW:
        return "Tickets";
      case Status.CANCELLATION_VIEW:
        return "Reservations";
      case Status.IMMUTABLE_VIEW:
        return this.tickets[0]?.orderType === OrderType.PURCHASED
          ? "Tickets"
          : "Reservations";
      default:
        console.error("Unknown status");
        return "ERROR: UNKNOWN STATUS";
    }
  }

  onModalSubmit() {
    switch (this.status) {
      case Status.PURCHASE_VIEW:
        this.buy();
        break;
      case Status.REFUNDABLE_VIEW:
        this.refund();
        break;
      case Status.CANCELLATION_VIEW:
        this.cancel();
        break;
      default:
        console.error("Unknown status")
        break;
    }
  }
}
*/
