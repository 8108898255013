
<div class="container">
  <h1 class="title ms-lg-3">Manage Ticketline</h1>
  <div class="title-container">
    <div class="manage-header">
      <div class="manage-buttons-container">
        <button
          class="btn hoverable hoverable-top manage-button"
          routerLink="/manage/performance"
        >
          <i class="bi bi-music-note-beamed icon"></i><br/>Create Show
        </button>
        <button class="btn hoverable hoverable-top manage-button" routerLink="/manage/event">
          <i class="bi bi-calendar-event icon"></i><br/>Create Event
        </button>
        <button class="btn hoverable hoverable-top manage-button" routerLink="/manage/news">
          <i class="bi bi-card-text icon"></i><br/>Create News
        </button>
        <button class="btn hoverable hoverable-top manage-button" routerLink="/manage/user">
          <i class="bi bi-person-plus icon"></i><br/>Create User
        </button>
        <button class="btn hoverable hoverable-top manage-button" routerLink="/manage/location">
          <i class="bi bi-geo-alt-fill icon"></i><br/>Create Location
        </button>
        <button class="btn hoverable hoverable-top manage-button" routerLink="/manage/eventhall">
          <i class="bi bi-house-gear icon"></i><br/>Create Event Hall
        </button>
      </div>
    </div>
  </div>

  <div class="search-container">
    <div class="input-group">
      <input
        (ngModelChange)="searchChanged()"
        [(ngModel)]="searchParam.searchTerm"
        class="form-control mt-0"
        placeholder="Search user"
        type="text"
      />
    </div>

    <select
      (ngModelChange)="searchChanged()"
      [(ngModel)]="searchParam.status"
      class="form-select form-select mt-0"
    >
      <option selected value="all">All</option>
      <option value="active">Active</option>
      <option value="locked">Locked</option>
    </select>
  </div>

  <div #container
       [ngClass]="{'no-scroll': totalItems < 8 && animate}" class="flex-container"
  >
    <div *ngIf="totalItems === 0" class="not-found hoverable">
      <h4 class="text-center">No Users Found</h4>
    </div>
    <div *ngFor="let item of users" class="item-box mx-auto hoverable">
      <div class="left-section">
        <!-- Icon -->
        <i
          *ngIf="!item.admin"
          class="bi bi-person h1"
          style="margin-left: 5px"
        ></i>
        <i
          *ngIf="item.admin"
          class="bi bi-person-fill-gear h1"
          style="margin-left: 5px"
        ></i>
      </div>
      <div class="mid-section">
        <div class="name ">
          <p class="mid">{{ item.firstname }} {{ item.lastname }}</p>
        </div>
        <!-- Email -->
        <div class="email">
          <p class="mid">{{ item.email }}</p>
        </div>
        <!-- Status -->
        <div class="status">
          <p class="mid">Status: {{ item.locked === true ? "Locked" : "Active" }}</p>
        </div>
      </div>
      <div class="right-section">
        <!-- Buttons -->
        <button
          (click)="onResetPassword(item)"
          class="btn btn-secondary">
          <i class="bi bi-key"></i> Reset Password
        </button>
        <button
          (click)="unlockUser(item.id)"
          *ngIf="item.locked === true"
          class="btn btn-success">
          <i class="bi bi-person-check"></i> Unlock
        </button>
        <button
        (click)="lockUser(item.id)"
        *ngIf="item.locked === false"
        class="btn lock btn-yellow">
          <i class="bi bi-person-lock"></i> Lock
        </button>
        <button class="btn delete btn-danger" (click)="onDeleteUser(item)">
          <i class="bi bi-trash"></i> Delete
        </button>
      </div>
    </div>
  </div>

  <ngb-pagination
    (pageChange)="onPageChange($event)"
    *ngIf="totalItems > itemsPerPage"
    [(page)]="currentPage"
    [collectionSize]="totalItems"
    [maxSize]="3"
    [pageSize]="itemsPerPage"
    [rotate]="true"
    class="pager"
  ></ngb-pagination>
</div>
