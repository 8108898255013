
<div class="main-container ">
  <div class="form-wrapper shadow">
    <div class="d-flex justify-content-between">
      <h1 class="title">Create Event</h1>
      <div class="d-flex align-items-center mb-3">
        <i class="bi bi-arrow-left"></i>
        <a [routerLink]="['/manage']" class="ms-2 link-unstyled"><strong>Back</strong></a>
      </div>
    </div>
    <form class="form" [formGroup]="createEventForm" (ngSubmit)="createEvent()">
      <div class="form-group">
        <label for="name" class="form-label">Title</label>
        <input class="form-control"
        id="name"
        type="text"
        formControlName="name"
        [ngClass]="{
          'is-invalid': submitted && createEventForm.controls.name.errors,
           'is-valid': submitted && !createEventForm.controls.name.errors
           }">
        <div *ngIf="submitted && createEventForm.controls.name.errors" class="invalid-feedback d-block">
          <small *ngIf="createEventForm.controls.name.errors.required">Please enter Event Name</small>
        </div>
      </div>

      <div class="form-group">
        <label for="start-date-time" class="form-label">Start Date</label>
        <input class="form-control"
        id="start-date-time"
        type="datetime-local"
        formControlName="startDateTime"
        [ngClass]="{
          'is-invalid': submitted && createEventForm.controls.startDateTime.errors,
           'is-valid': submitted && !createEventForm.controls.startDateTime.errors
           }">
        <div *ngIf="submitted && createEventForm.controls.startDateTime.errors" class="invalid-feedback d-block">
          <small *ngIf="createEventForm.controls.startDateTime.errors.required">Please enter Event start date</small>
        </div>
      </div>

      <div class="form-group">
        <label for="end-date-time" class="form-label">End Date</label>
        <input class="form-control"
        id="end-date-time"
        type="datetime-local"
        formControlName="endDateTime"
        [ngClass]="{
          'is-invalid': submitted && createEventForm.controls.endDateTime.errors,
           'is-valid': submitted && !createEventForm.controls.endDateTime.errors
           }">
        <div *ngIf="submitted && createEventForm.controls.endDateTime.errors" class="invalid-feedback d-block">
          <small *ngIf="createEventForm.controls.endDateTime.errors.required">Please enter Event end date</small>
        </div>
      </div>

      <div class="form-group">
        <label for="description" class="form-label">Description</label>
        <textarea class="form-control"
        id="description"
        formControlName="description"
        [ngClass]="{
          'is-invalid': submitted && createEventForm.controls.description.errors,
           'is-valid': submitted && !createEventForm.controls.description.errors
           }"></textarea>
        <div *ngIf="submitted && createEventForm.controls.description.errors" class="invalid-feedback d-block">
          <small *ngIf="createEventForm.controls.description.errors.required">Please enter Event description</small>
        </div>
      </div>

      <div class="form-group">
        <label for="content" class="form-label">Content</label>
        <textarea class="form-control"
        id="content"
        formControlName="content"
        [ngClass]="{
          'is-invalid': submitted && createEventForm.controls.content.errors,
           'is-valid': submitted && !createEventForm.controls.content.errors
           }"></textarea>
        <div *ngIf="submitted && createEventForm.controls.content.errors" class="invalid-feedback d-block">
          <small *ngIf="createEventForm.controls.content.errors.required">Please enter Event content</small>
        </div>
      </div>

      <div class="form-group">
        <label for="image" class="form-label">Upload Image (optional)</label>
        <input class="form-control"
        id="image"
        name="image"
        type="file"
        (change)="onFileSelected($event,createEventDto)"
        accept="image/png">
        <div *ngIf="invalidFileType" class="error">
          <small class="text-danger">Only PNG images are allowed!</small>
        </div>
      </div>

      <div class="form-group">
        <label for="category" class="form-label">Category</label>
        <select class="form-control"
        id="category"
        formControlName="category"
        [ngClass]="{
          'is-invalid': submitted && createEventForm.controls.category.errors,
           'is-valid': submitted && !createEventForm.controls.category.errors
           }">
          <option value="ARTS">Arts</option>
          <option value="ENTERTAINMENT">Entertainment</option>
          <option value="FASHION">Fashion</option>
          <option value="HEALTH">Health</option>
          <option value="BUSINESS">Business</option>
          <option value="SCIENCE">Science</option>
          <option value="TRAVEL">Travel</option>
          <option value="ENVIRONMENT">Environment</option>
          <option value="COMMUNITY">Community</option>
          <option value="TECHNOLOGY">Technology</option>
          <option value="CHARITY">Charity</option>
        </select>
        <div *ngIf="submitted && createEventForm.controls.category.errors" class="invalid-feedback d-block">
          <small *ngIf="createEventForm.controls.category.errors.required">Please enter Event category</small>
        </div>
      </div>

      <div class="form-actions">
        <button type="submit" class="btn btn-primary">Add Event</button>
      </div>
    </form>
  </div>
</div>

