import { Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class ErrorFormatterService {
  constructor(private domSanitizer: DomSanitizer) {}

  format(error: any, customMessage: string = undefined): string {
    let receivedError;
    try {
      receivedError = JSON.parse(error.error);
    } catch (e) {
      receivedError = error.error;
    }

    let message =
      customMessage ||
      (this.domSanitizer.sanitize(
        SecurityContext.HTML,
        receivedError.message,
      ) ??
        "");

    if (receivedError.errors) {
      message += "<ul>";
      for (const e of receivedError.errors) {
        const sanE = this.domSanitizer.sanitize(SecurityContext.HTML, e);
        message += `<li>${sanE}</li>`;
      }
      message += "</ul>";
    }

    return message;
  }
}
